import * as Sentry from '@sentry/react-native'
import dayjs from 'dayjs'
import 'dayjs/locale/cs'
import i18n from 'i18next'
import { FC } from 'react'
import Config from 'react-native-config'
import { PaperProvider } from 'react-native-paper'
import { Provider } from 'react-redux'

import './setup/dayjsSetup'
import './src/app-component-files/firebase'
import './src/app-component-files/logs'
import './src/app-component-files/messaging'
import AppContainer from './src/components/AppContainer'
import store from './src/redux/store'
import { theme } from './src/styles/theme'
import './src/translations/index'

if (!__DEV__) {
    Sentry.init({
        dsn: Config.SENTRY_DSN,
        maxValueLength: Number.MAX_SAFE_INTEGER,
    })
}

dayjs.locale(i18n.language)

const App: FC = () => {
    return (
        <Provider store={store}>
            <PaperProvider theme={theme}>
                <AppContainer />
            </PaperProvider>
        </Provider>
    )
}

export default !__DEV__ ? Sentry.wrap(App) : App
