import { FC, useState } from 'react'

import TerminalAPI from '../../../../../api/terminal/terminal'
import { useAppSelector } from '../../../../../hooks/redux'
import { ordersSelectors } from '../../../../../redux/reducers/orders/selectors'
import { IDeliveryTime } from '../../../../../types/order'
import { RequestedDeliveryTimeShiftValues } from '../../../../../types/order-flow-action-time-values'
import { shiftDeliveryTime } from '../../../../../utils/customDeliveryTime'
import CustomAcceptedDeliveryTimeAdjuster from './CustomAcceptedDeliveryTimeAdjuster'
import DefaultAcceptedDeliveryTimeSelector from './DefaultAcceptedDeliveryTimeSelector'

interface AcceptedDeliveryTimeSelectorProps {
    orderUuid: string
    onOrderCancelButton: () => void
}

const AcceptedDeliveryTimeSelector: FC<AcceptedDeliveryTimeSelectorProps> = ({
    orderUuid,
    onOrderCancelButton,
}) => {
    const order = useAppSelector(ordersSelectors.orderByUuid(orderUuid))

    const [shiftValue, setShiftValue] = useState<RequestedDeliveryTimeShiftValues>(0)
    const [isCustomValueMode, setIsCustomValueMode] = useState(false)
    const [customDeliveryTime, setCustomDeliveryTime] = useState<IDeliveryTime>()

    if (!(order && order.requestedDeliveryTime)) {
        return null
    }

    const handleDeliveryTimeShiftPress = (value: RequestedDeliveryTimeShiftValues) => {
        if (value === 'custom') {
            setIsCustomValueMode(true)
            return
        }
        setShiftValue(value)
    }

    const handleCustomTimeShiftPress = (deliveryTime: IDeliveryTime) => {
        setCustomDeliveryTime(deliveryTime)
    }

    const handleCancelCustomDeliveryTime = () => {
        setCustomDeliveryTime(undefined)
        setIsCustomValueMode(false)

        if (shiftValue !== 'custom') return

        setShiftValue(0)
    }

    const handleConfirmCustomDeliveryTime = () => {
        setShiftValue('custom')
        setIsCustomValueMode(false)
    }

    const handleOrderAcceptButton = async () => {
        if (customDeliveryTime && shiftValue === 'custom') {
            await TerminalAPI.acceptOrder(order.uuid, customDeliveryTime)
            return
        }

        if (!order.requestedDeliveryTime || typeof shiftValue !== 'number') {
            throw new Error(
                'Invalid input: requestedDeliveryTime is missing or shiftValue is not a number'
            )
        }

        const deliveryTime = shiftDeliveryTime(order.requestedDeliveryTime, shiftValue, 'minutes')
        await TerminalAPI.acceptOrder(order.uuid, deliveryTime)
    }

    return (
        <>
            {!isCustomValueMode ? (
                <DefaultAcceptedDeliveryTimeSelector
                    requestedDeliveryTime={order.requestedDeliveryTime}
                    customDeliveryTime={customDeliveryTime}
                    shiftValue={shiftValue}
                    onDeliveryTimeShiftPress={handleDeliveryTimeShiftPress}
                    onOrderCancelButton={onOrderCancelButton}
                    onOrderAcceptButton={handleOrderAcceptButton}
                />
            ) : (
                <CustomAcceptedDeliveryTimeAdjuster
                    requestedDeliveryTime={order.requestedDeliveryTime}
                    customDeliveryTime={customDeliveryTime}
                    onTimeShiftPress={handleCustomTimeShiftPress}
                    onCancelButton={handleCancelCustomDeliveryTime}
                    onConfirmButton={handleConfirmCustomDeliveryTime}
                />
            )}
        </>
    )
}

export default AcceptedDeliveryTimeSelector
