import { MD3LightTheme as DefaultTheme, configureFonts } from 'react-native-paper'

const baseFont = {
    fontFamily: 'Satoshi-Medium',
}

const baseVariants = configureFonts({ config: baseFont })

const customVariants = {
    bold: {
        ...baseVariants.bodyMedium,
        fontFamily: 'Satoshi-Bold',
    },
    regular: {
        ...baseVariants.bodyMedium,
        fontFamily: 'Satoshi-Regular',
    },
    light: {
        fontFamily: 'Satoshi-Light',
    },
    italic: {
        ...baseVariants.bodyMedium,
        fontFamily: 'Satoshi-Italic',
    },
    boldItalic: {
        ...baseVariants.bodyMedium,
        fontFamily: 'Satoshi-BoldItalic',
    },
} as const

const fonts = configureFonts({
    config: {
        ...baseVariants,
        ...customVariants,
    },
})

export const theme = {
    ...DefaultTheme,
    fonts,
}
