import React, { FC } from 'react'
import { Dimensions, StyleSheet } from 'react-native'
import { Text } from 'react-native-paper'
import { useContextSelector } from 'use-context-selector'

import { ColorEnum } from '../../../../constants/color'
import { truncateText } from '../../../../utils/truncateText'
import OrderListItemContext from './OrderListItemContext'

const CustomerName: FC = () => {
    const name = useContextSelector(OrderListItemContext, ({ order }) => order.customerName)

    if (!name) {
        return null
    }

    const maxLength = Dimensions.get('window').width > 430 ? 30 : 20

    return <Text style={styles.name}>{truncateText(name, maxLength)}</Text>
}

const styles = StyleSheet.create({
    name: {
        color: ColorEnum.GRAY_600,
        fontSize: 12,
    },
})

export default CustomerName
