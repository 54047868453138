import { FC } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { ColorEnum } from '../../constants/color'
import { useAppSelector } from '../../hooks/redux'
import { selectScreenInfo } from '../../redux/reducers/screenInfo'
import TopBarContent from './TopBarContent'
import TopBarContentMobile from './TopBarContentMobile'

const TopBar: FC = () => {
    const screenInfo = useAppSelector(selectScreenInfo)

    const { top } = useSafeAreaInsets()

    return (
        <View style={[styles.outerContainer, { paddingTop: top }]}>
            <View style={styles.innerContainer}>
                {screenInfo.isLargeScreen ? <TopBarContent /> : <TopBarContentMobile />}
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    outerContainer: {
        backgroundColor: ColorEnum.BRICK_700,
    },
    innerContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 64,
    },
    icon: {
        margin: 0,
    },
})

export default TopBar
