import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native'
import { Button } from 'react-native-paper'

import { ColorEnum } from '../../../constants/color'

interface CancelWithConfirmButtonsProps {
    applyButtonDisabled?: boolean
    onCancel: () => void
    onConfirm: () => void
    style?: StyleProp<ViewStyle>
}

const CancelWithConfirmButtons: FC<CancelWithConfirmButtonsProps> = ({
    applyButtonDisabled,
    onCancel,
    onConfirm,
    style,
}) => {
    const { t } = useTranslation()

    return (
        <View style={[styles.container, style]}>
            <Button
                mode="contained"
                buttonColor={ColorEnum.BRICK_500}
                maxFontSizeMultiplier={1}
                onPress={onCancel}
                style={styles.button}
            >
                <Text style={styles.buttonText}>{t('cancel')}</Text>
            </Button>
            <Button
                mode="contained"
                disabled={applyButtonDisabled}
                buttonColor={ColorEnum.EMERALD_500}
                maxFontSizeMultiplier={1}
                onPress={onConfirm}
                style={styles.button}
            >
                <Text style={styles.buttonText}>{t('apply')}</Text>
            </Button>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'center',
        gap: 10,
    },
    button: {
        flex: 1,
        borderRadius: 8,
    },
    buttonText: {
        fontSize: 16,
        textTransform: 'uppercase',
    },
})

export default CancelWithConfirmButtons
