import { FC } from 'react'
import { StyleSheet } from 'react-native'

import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { addAuthLicenceIdNum, removeAuthLicenceIdNum } from '../../../redux/reducers/auth'
import { lookupAuthLicence } from '../../../redux/reducers/auth/action-creators'
import { authSelectors } from '../../../redux/reducers/auth/selectors'
import { verticalScale } from '../../../utils/metrics'
import NumPad from '../../UI/NumPad/NumPad'

const AuthLicenceVerificationNumPad: FC = () => {
    const isLicenceIdEmpty = useAppSelector(authSelectors.isLicenceIdEmpty)

    const dispatch = useAppDispatch()

    const handleNumPress = (num: string) => {
        dispatch(addAuthLicenceIdNum(num))
    }

    const handleDelete = () => {
        dispatch(removeAuthLicenceIdNum())
    }

    const handleSubmit = () => {
        dispatch(lookupAuthLicence())
    }

    return (
        <NumPad
            deleteDisabled={isLicenceIdEmpty}
            submitDisabled={isLicenceIdEmpty}
            onNumPress={handleNumPress}
            onDelete={handleDelete}
            onSubmit={handleSubmit}
            style={[styles.numPad, { marginBottom: verticalScale(5) }]}
            numPadID="licenceVerificationScreen"
        />
    )
}

const styles = StyleSheet.create({
    numPad: {
        marginTop: 'auto',
    },
})

export default AuthLicenceVerificationNumPad
