import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs'
import { FC } from 'react'
import { StyleSheet, View } from 'react-native'

import { ColorEnum } from '../../../constants/color'
import TabBarItem from './TabBarItem'

const TabBar: FC<MaterialTopTabBarProps> = ({ state, descriptors, navigation, position }) => {
    return (
        <View style={styles.container}>
            {state.routes.map((route, index) => {
                const { options } = descriptors[route.key]

                const label =
                    options.tabBarLabel !== undefined
                        ? (options.tabBarLabel as string)
                        : options.title !== undefined
                          ? options.title
                          : route.name

                const isFocused = state.index === index

                const handlePress = () => {
                    const event = navigation.emit({
                        type: 'tabPress',
                        target: route.key,
                        canPreventDefault: true,
                    })

                    if (!isFocused && !event.defaultPrevented) {
                        navigation.navigate(route.name, route.params)
                    }
                }

                return (
                    <TabBarItem key={index} focused={isFocused} onPress={handlePress}>
                        {label}
                    </TabBarItem>
                )
            })}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: ColorEnum.WHITE,
        paddingTop: 16,
        paddingBottom: 8,
        gap: 4,
    },
})

export default TabBar
