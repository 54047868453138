import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from '../../../hooks/redux'
import { authSelectors } from '../../../redux/reducers/auth/selectors'
import CustomTextField from '../../UI/CustomTextField/CustomTextField'

const AuthLicenceIdField: FC = () => {
    const licenceId = useAppSelector(authSelectors.licenceId)

    const { t } = useTranslation()

    return (
        <CustomTextField
            placeholder={t('licenceVerificationScreenLicenceId')}
            customTextFieldID="licenceVerificationScreen-licenceId"
        >
            {licenceId}
        </CustomTextField>
    )
}

export default AuthLicenceIdField
