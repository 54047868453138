import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import TerminalAPI from '../../../../api/terminal/terminal'
import { ColorEnum } from '../../../../constants/color'
import { OrderPhaseEnum, OrderStateEnum } from '../../../../constants/order'
import { useAppSelector } from '../../../../hooks/redux'
import { ordersSelectors } from '../../../../redux/reducers/orders/selectors'
import { isDeliveryOrder } from '../../../../utils/isDeliveryOrder'
import AcceptedDeliveryTimeSelector from './AcceptedDeliveryTimeSelector/AcceptedDeliveryTimeSelector'
import AcceptedOrderDetailFooter from './AcceptedOrderDetailFooter/AcceptedOrderDetailFooter'
import OrderDetailFooterActionButton from './OrderDetailFooterActionButton'

interface OrderDetailFooterProps {
    orderUuid: string
    onOrderCancelButton: () => void
}

const OrderDetailFooter: FC<OrderDetailFooterProps> = ({ orderUuid, onOrderCancelButton }) => {
    const order = useAppSelector(ordersSelectors.orderByUuid(orderUuid))

    const { bottom } = useSafeAreaInsets()

    const { t } = useTranslation()

    if (!order) {
        return null
    }

    const renderContent = () => {
        if (order.state === OrderStateEnum.AwaitingAcceptance) {
            return (
                <AcceptedDeliveryTimeSelector
                    orderUuid={orderUuid}
                    onOrderCancelButton={onOrderCancelButton}
                />
            )
        }

        if (order.state === OrderStateEnum.Accepted && order.phase === OrderPhaseEnum.New) {
            return <AcceptedOrderDetailFooter orderUuid={orderUuid} />
        }

        if (order.state === OrderStateEnum.Production) {
            const handlePress = async () => {
                await TerminalAPI.finishOrderProduction(orderUuid)
            }

            return (
                <OrderDetailFooterActionButton onPress={handlePress}>
                    {t('order.detail.toReady')}
                </OrderDetailFooterActionButton>
            )
        }

        if (order.state === OrderStateEnum.Ready && isDeliveryOrder(order)) {
            const handlePress = async () => {
                await TerminalAPI.startOrderDelivery(orderUuid)
            }

            return (
                <OrderDetailFooterActionButton onPress={handlePress}>
                    {t('order.detail.toDelivering')}
                </OrderDetailFooterActionButton>
            )
        }

        if (
            order.state === OrderStateEnum.Delivering ||
            (order.state === OrderStateEnum.Ready && !isDeliveryOrder(order))
        ) {
            const handlePress = async () => {
                await TerminalAPI.finishOrderDelivery(orderUuid)
            }

            return (
                <OrderDetailFooterActionButton onPress={handlePress}>
                    {t('order.detail.toDelivered')}
                </OrderDetailFooterActionButton>
            )
        }
    }

    return (
        <View style={[styles.container, !!bottom && { paddingBottom: bottom }]}>
            {renderContent()}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        backgroundColor: ColorEnum.GRAY_100,
        borderTopStartRadius: 16,
        borderTopEndRadius: 16,
        paddingVertical: 16,
    },
})

export default OrderDetailFooter
