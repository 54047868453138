import { useNavigation } from '@react-navigation/native'
import React, { FC } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { IconButton } from 'react-native-paper'

import { ColorEnum } from '../../../../constants/color'
import { OrderPhaseEnum } from '../../../../constants/order'
import { mobileOrdersModuleStackId } from '../../../../constants/screen'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { clearSelectedOrderUuid } from '../../../../redux/reducers/orders'
import { ordersSelectors } from '../../../../redux/reducers/orders/selectors'
import { selectScreenInfo } from '../../../../redux/reducers/screenInfo'
import CountdownWithDeliveryTypeCategory from '../../../UI/Order/CountdownWithDeliveryTypeCategory'
import DeliveryTypeCategoryAndChannel from '../../../UI/Order/DeliveryTypeCategoryAndChannel'
import OrderCompletionIndicator from '../../../UI/Order/OrderCompletionIndicator'
import ShortCode from '../../../UI/Order/ShortCode'

interface ShortCodeAndDeliveryTypeCategoryAndChannelWithCountdownOrCompletionIndicatorProps {
    uuid: string
    style?: StyleProp<ViewStyle>
}

const ShortCodeAndDeliveryTypeCategoryAndChannelWithCountdownOrCompletionIndicator: FC<
    ShortCodeAndDeliveryTypeCategoryAndChannelWithCountdownOrCompletionIndicatorProps
> = ({ uuid, style }) => {
    const order = useAppSelector(ordersSelectors.orderByUuid(uuid))
    const screenInfo = useAppSelector(selectScreenInfo)

    const dispatch = useAppDispatch()

    const navigation = useNavigation()

    if (!order) {
        throw new Error('Order must be set')
    }

    const renderCountdownOrCompletionIndicator = () => {
        if (order.phase !== OrderPhaseEnum.Archived) {
            const deliveryTime = order.acceptedDeliveryTime ?? order.requestedDeliveryTime

            if (!deliveryTime) {
                throw new Error('Order delivery time must be provided')
            }

            return (
                <CountdownWithDeliveryTypeCategory
                    deliveryTime={deliveryTime}
                    deliveryTypeCategory={order.deliveryType.typeCategory}
                />
            )
        }

        return (
            <OrderCompletionIndicator
                state={order.state}
                acceptedDeliveryTime={order.acceptedDeliveryTime}
                deliveredAt={order.deliveredAt}
            />
        )
    }

    const handleCloseButtonPress = () => {
        if (navigation.getId() === mobileOrdersModuleStackId) {
            navigation.goBack()

            return
        }

        dispatch(clearSelectedOrderUuid())
    }

    return (
        <View style={[styles.container, style]}>
            {order.shortCode && (
                <ShortCode textStyle={{ fontSize: 10 }} style={styles.shortCode}>
                    {order.shortCode}
                </ShortCode>
            )}
            <DeliveryTypeCategoryAndChannel
                deliveryTypeCategory={order.deliveryType.typeCategory}
                channel={order.channel}
            />
            {renderCountdownOrCompletionIndicator()}
            <IconButton
                icon="close"
                iconColor={ColorEnum.BRICK_600}
                onPress={handleCloseButtonPress}
                style={styles.closeIcon}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    shortCode: {
        height: 28,
        width: 28,
        marginRight: 6,
    },
    countdown: {
        fontSize: 16,
        marginEnd: 40,
        color: ColorEnum.EMERALD_700,
    },
    closeIcon: {
        margin: 0,
        marginLeft: 6,
    },
})

export default ShortCodeAndDeliveryTypeCategoryAndChannelWithCountdownOrCompletionIndicator
