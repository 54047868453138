import { InternalAxiosRequestConfig } from 'axios'
import { URL as NativeURL } from 'react-native-url-polyfill'

import { isWeb } from '../constants/environment'

const parseUrl = (url: string) => (isWeb ? new URL(url) : new NativeURL(url))

export const applyUrlHostRewrite = async (
    config: InternalAxiosRequestConfig,
    targetServerURL: string,
    originalHostHeaderName: string
) => {
    if (config.url === undefined) {
        throw new Error('URL is undefined')
    }

    const targetServerUrl = parseUrl(targetServerURL)

    const url = parseUrl((config.baseURL + config.url).replace(/\/+/g, '/'))
    const originalHost = url.host

    url.host = targetServerUrl.host
    url.protocol = targetServerUrl.protocol

    config.url = url.toString()
    config.headers[originalHostHeaderName] = originalHost

    return config
}
