import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { ILicenceLookupData } from '../../../api/master/types'
import { OAuthErrorTypeEnum } from '../../../api/oauth/constants'
import { IDeviceAuth } from '../../../services/authStorage/types'
import { IProfile } from '../../../types/profile'
import {
    IRetainedKeys,
    clearAuth,
    fetchAuthDeviceId,
    fetchAuthDeviceToken,
    fetchAuthProfiles,
    fetchAuthTokens,
    logoutAuthUser,
    lookupAuthLicence,
    setAuthProfile,
} from './action-creators'

export interface AuthState {
    licenceId: string
    hostUrl: string | null
    licenceName: string | null
    deviceId: string | null
    deviceCode: string
    deviceToken: string | null
    profiles: IProfile[] | null
    profile: IProfile | null
    pin: string
    prevSubmittedPin: string | null
    hasTokens: boolean
}

const initialState: AuthState = {
    licenceId: '',
    hostUrl: null,
    licenceName: null,
    deviceId: null,
    deviceCode: '',
    deviceToken: null,
    profiles: null,
    profile: null,
    pin: '',
    prevSubmittedPin: null,
    hasTokens: false,
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        addAuthLicenceIdNum: (state, { payload: num }: PayloadAction<string>) => {
            state.licenceId += num
        },
        removeAuthLicenceIdNum: (state) => {
            state.licenceId = state.licenceId.substring(0, state.licenceId.length - 1)
        },
        addAuthDeviceCodeNum: (state, { payload: num }: PayloadAction<string>) => {
            state.deviceCode += num
        },
        removeAuthDeviceCodeNum: (state) => {
            state.deviceCode = state.deviceCode.substring(0, state.deviceCode.length - 1)
        },
        setAuthDeviceAuth: (state, { payload: deviceAuth }: PayloadAction<IDeviceAuth>) => {
            state.hostUrl = deviceAuth.hostUrl
            state.deviceId = deviceAuth.deviceId
            state.deviceToken = deviceAuth.deviceToken
        },
        setAuthProfiles: (state, { payload: profiles }: PayloadAction<IProfile[]>) => {
            state.profiles = profiles
        },
        clearAuthProfile: (state) => {
            state.profile = initialState.profile
            state.pin = initialState.pin
        },
        addAuthPinNum: (state, { payload: num }: PayloadAction<string>) => {
            state.pin += num
        },
        removeAuthPinNum: (state) => {
            state.pin = state.pin.substring(0, state.pin.length - 1)
        },
        setAuthHasTokens: (state, { payload: hasTokens }: PayloadAction<boolean>) => {
            state.hasTokens = hasTokens
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            lookupAuthLicence.fulfilled,
            (state, { payload: info }: PayloadAction<ILicenceLookupData>) => {
                state.hostUrl = info.url
                state.licenceName = info.name
            }
        )
        builder.addCase(
            fetchAuthDeviceId.fulfilled,
            (state, { payload: id }: PayloadAction<string>) => void (state.deviceId = id)
        )
        builder.addCase(fetchAuthDeviceId.rejected, (state) => {
            state.hostUrl = initialState.hostUrl
            state.licenceName = initialState.licenceName
        })
        builder.addCase(
            fetchAuthDeviceToken.fulfilled,
            (state, { payload: token }: PayloadAction<string>) => void (state.deviceToken = token)
        )
        builder.addCase(fetchAuthDeviceToken.rejected, (state) => {
            state.hostUrl = initialState.hostUrl
            state.licenceName = initialState.licenceName
            state.deviceId = initialState.deviceId
            state.deviceCode = initialState.deviceCode
        })
        builder.addCase(
            clearAuth.fulfilled,
            (state, { payload: retainedKeys }: PayloadAction<IRetainedKeys>) => {
                if (retainedKeys) {
                    const retainedData: { [key in keyof AuthState]?: any } = {}

                    retainedKeys.forEach((key) => {
                        retainedData[key] = state[key]
                    })

                    return { ...initialState, ...retainedData }
                }

                return initialState
            }
        )
        builder.addCase(
            fetchAuthProfiles.fulfilled,
            (state, { payload: profiles }) => void (state.profiles = profiles)
        )
        builder.addCase(
            setAuthProfile.fulfilled,
            (state, { payload: profile }) => void (state.profile = profile)
        )
        builder.addCase(fetchAuthTokens.fulfilled, (state) => {
            state.hasTokens = true
            state.prevSubmittedPin = initialState.prevSubmittedPin
        })
        builder.addCase(fetchAuthTokens.rejected, (state, { payload: errorType }) => {
            if (errorType === OAuthErrorTypeEnum.InvalidClient) {
                return initialState
            }

            if (errorType === OAuthErrorTypeEnum.InvalidGrant) {
                // return user to profile selection screen
                if (state.prevSubmittedPin === state.pin) {
                    state.profiles = initialState.profiles
                    state.profile = initialState.profile
                    state.prevSubmittedPin = initialState.prevSubmittedPin
                } else {
                    state.prevSubmittedPin = state.pin
                }

                state.pin = initialState.pin
            }
        })
        builder.addCase(logoutAuthUser.fulfilled, (state) => {
            state.hasTokens = initialState.hasTokens
            state.pin = initialState.pin
            state.profile = initialState.profile
        })
    },
})

export const {
    addAuthLicenceIdNum,
    removeAuthLicenceIdNum,
    addAuthDeviceCodeNum,
    removeAuthDeviceCodeNum,
    setAuthDeviceAuth,
    setAuthProfiles,
    clearAuthProfile,
    addAuthPinNum,
    removeAuthPinNum,
    setAuthHasTokens,
} = authSlice.actions

export default authSlice.reducer
