import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'

import { requestedDeliveryTimeShiftValues } from '../../../../../constants/order-flow-action-time-values'
import { IDeliveryTime } from '../../../../../types/order'
import { RequestedDeliveryTimeShiftValues } from '../../../../../types/order-flow-action-time-values'
import { formatDeliveryTime } from '../../../../../utils/formatDeliveryTime'
import Chip from '../../../../UI/Chip'
import AcceptedDeliveryTimeButtons from './AcceptedDeliveryTimeButtons'

interface DefaultAcceptedDeliveryTimeProps {
    requestedDeliveryTime: IDeliveryTime
    customDeliveryTime: IDeliveryTime | undefined
    shiftValue: RequestedDeliveryTimeShiftValues
    onDeliveryTimeShiftPress: (value: RequestedDeliveryTimeShiftValues) => void
    onOrderCancelButton: () => void
    onOrderAcceptButton: () => Promise<void>
}

const DefaultAcceptedDeliveryTimeSelector: FC<DefaultAcceptedDeliveryTimeProps> = ({
    requestedDeliveryTime,
    customDeliveryTime,
    shiftValue,
    onDeliveryTimeShiftPress,
    onOrderCancelButton,
    onOrderAcceptButton,
}) => {
    const { t } = useTranslation()

    return (
        <>
            <Text style={styles.title}>{t('order.detail.acceptationTitle')}</Text>
            <View style={styles.container}>
                {requestedDeliveryTimeShiftValues.map((value, index) => {
                    if (value === 0) {
                        return (
                            <Chip
                                key={index}
                                text={t('order.detail.requiredTime')}
                                subText={formatDeliveryTime(requestedDeliveryTime)}
                                chosen={shiftValue === 0}
                                onPress={() => onDeliveryTimeShiftPress(value)}
                                style={styles.wideChip}
                            />
                        )
                    }

                    if (value === 'custom') {
                        return (
                            <Chip
                                key={index}
                                text={t('order.detail.customTime')}
                                subText={
                                    customDeliveryTime && formatDeliveryTime(customDeliveryTime)
                                }
                                chosen={shiftValue === 'custom'}
                                onPress={() => onDeliveryTimeShiftPress('custom')}
                                style={styles.wideChip}
                            />
                        )
                    }

                    return (
                        <Chip
                            key={index}
                            text={`${value > 0 ? '+' : ''}${value} ${t('timeUnit.min')}`}
                            chosen={shiftValue === value}
                            onPress={() => onDeliveryTimeShiftPress(value)}
                        />
                    )
                })}
            </View>
            <AcceptedDeliveryTimeButtons
                onOrderCancel={onOrderCancelButton}
                onOrderAccept={onOrderAcceptButton}
            />
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flexWrap: 'wrap',
        width: 400,
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: 12,
    },
    title: {
        fontSize: 16,
        marginBottom: 12,
    },
    wideChip: {
        width: 168,
    },
})

export default DefaultAcceptedDeliveryTimeSelector
