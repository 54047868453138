import Config from 'react-native-config'

import AxiosHttpFactory from '../axiosHttpFactory'
import { IIgnoreErrorResponseLogAxiosRequestConfig } from '../types'
import { MasterAPIErrorCodeEnum } from './constants'
import { ILicenceLookupData, IMasterAPI } from './types'

class MasterAPI implements IMasterAPI {
    private $masterAPI = AxiosHttpFactory.createInstance({ baseURL: Config.MASTER_API_URL })

    async licenceLookup(licenceId: string) {
        const { data } = await this.$masterAPI.get<{ data: ILicenceLookupData }>(
            '/licence-lookup',
            {
                params: { apiKey: Config.MASTER_API_KEY, id: licenceId },
                ignoreErrorLogging: (response) =>
                    response.data?.data?.error.code === MasterAPIErrorCodeEnum.EntityNotFound,
            } as IIgnoreErrorResponseLogAxiosRequestConfig
        )

        return data.data
    }
}

export default new MasterAPI()
