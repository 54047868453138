import dayjs from 'dayjs'

import { OrderPhaseEnum } from '../../../constants/order'
import { IOrder } from '../../../types/order'
import { UuidOrderMap } from './types'

type GetOrderSortPropertyCallback = (order: IOrder) => string | undefined
type SortOrder = 'desc' | 'asc'

const sortOrderUuidsByDateTimeProperty = (
    uuidOrderMap: UuidOrderMap,
    uuids: string[],
    getOrderSortPropertyCallback: GetOrderSortPropertyCallback,
    sortOrder: SortOrder = 'asc'
) =>
    uuids.sort((a, b) => {
        const orderA = uuidOrderMap[a]
        const orderB = uuidOrderMap[b]

        if (!orderA || !orderB) {
            return 0
        }

        const propertyA = getOrderSortPropertyCallback(orderA)
        const propertyB = getOrderSortPropertyCallback(orderB)

        if (!propertyA || !propertyB) {
            return 0
        }

        if (propertyA === propertyB) {
            return dayjs(orderA.createdAt).isAfter(dayjs(orderB.createdAt)) ? -1 : 1
        }

        const propertyADayjs = dayjs(propertyA)
        const propertyBDayjs = dayjs(propertyB)

        if (sortOrder === 'asc') {
            return propertyADayjs.isAfter(propertyBDayjs) ? 1 : -1
        }

        return propertyADayjs.isAfter(propertyBDayjs) ? -1 : 1
    })

export const sortOrderUuids = (
    uuids: string[],
    uuidOrderMap: UuidOrderMap,
    phase: OrderPhaseEnum
) => {
    return sortOrderUuidsByDateTimeProperty(
        uuidOrderMap,
        uuids,
        (order) => getOrderSortProperty(order),
        phase === OrderPhaseEnum.New || phase === OrderPhaseEnum.Archived ? 'desc' : 'asc'
    )
}

export const getOrderSortProperty = (order: IOrder) => {
    switch (order.phase) {
        case OrderPhaseEnum.New:
            return order.createdAt
        case OrderPhaseEnum.InProgress:
            return order.acceptedDeliveryTime?.end
        case OrderPhaseEnum.Future:
            return order.remindAt
        case OrderPhaseEnum.Archived:
            return order.deliveredAt ?? order.createdAt
    }
}
