import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import SideBarMenuItem from './SideBarMenuItem'

const SideBarMenu: FC = () => {
    const { t } = useTranslation()

    return (
        <View style={styles.container}>
            <SideBarMenuItem icon="food-takeout-box" name={t('menuOrders')} />
            <SideBarMenuItem icon="cash-register" name={t('menuCashDesk')} />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
})

export default SideBarMenu
