import { FC } from 'react'
import { StyleSheet } from 'react-native'

import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { addAuthDeviceCodeNum, removeAuthDeviceCodeNum } from '../../../redux/reducers/auth'
import { fetchAuthDeviceToken } from '../../../redux/reducers/auth/action-creators'
import { authSelectors } from '../../../redux/reducers/auth/selectors'
import { verticalScale } from '../../../utils/metrics'
import NumPad from '../../UI/NumPad/NumPad'

const AuthDeviceAuthorizationNumPad: FC = () => {
    const { empty, fulfilled } = useAppSelector(authSelectors.deviceCodeState)

    const dispatch = useAppDispatch()

    const handleNumPress = (num: string) => {
        if (fulfilled) return

        dispatch(addAuthDeviceCodeNum(num))
    }

    const handleDelete = () => {
        dispatch(removeAuthDeviceCodeNum())
    }

    const handleSubmit = () => {
        dispatch(fetchAuthDeviceToken())
    }

    return (
        <NumPad
            deleteDisabled={empty}
            submitDisabled={!fulfilled}
            onNumPress={handleNumPress}
            onDelete={handleDelete}
            onSubmit={handleSubmit}
            style={[styles.numPad, { marginBottom: verticalScale(5) }]}
            numPadID="deviceAuthorizationScreen"
        />
    )
}

const styles = StyleSheet.create({
    numPad: {
        marginTop: 'auto',
    },
})

export default AuthDeviceAuthorizationNumPad
