import { isDetox } from '../../constants/environment'

let AuthStorageService

if (isDetox) {
    AuthStorageService = require('./inMemoryAuthDataStorage')
} else {
    AuthStorageService = require('./asyncAuthDataStorage')
}

export default AuthStorageService.default
