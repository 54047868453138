import React, { FC } from 'react'
import { StyleSheet } from 'react-native'
import { Text } from 'react-native-paper'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'

import { ColorEnum } from '../../../../constants/color'

interface CustomerEvaluationProps {
    icon: string
    children: number
}

const CustomerEvaluation: FC<CustomerEvaluationProps> = ({ icon, children }) => {
    return (
        <>
            <Icon name={icon} size={16} color={ColorEnum.JET_600} />
            <Text
                style={[
                    styles.evaluation,
                    children >= 3 ? styles.evaluationPositive : styles.evaluationNegative,
                ]}
            >
                {children}/5
            </Text>
        </>
    )
}

const styles = StyleSheet.create({
    evaluation: {
        fontFamily: 'Satoshi-Bold',
        marginStart: 4,
        marginEnd: 10,
    },
    evaluationPositive: {
        color: ColorEnum.EMERALD_700,
    },
    evaluationNegative: {
        color: ColorEnum.BRICK_500,
    },
})

export default CustomerEvaluation
