import AuthAPI from './auth/auth'
import LicenceAPI from './licence/licence'
import OAuthAPI from './oauth/oauth'
import TerminalAPI from './terminal/terminal'

export const setAllApisHostUrl = (url: string) => {
    AuthAPI.setHostUrl(url)
    LicenceAPI.setHostUrl(url)
    OAuthAPI.setHostUrl(url)
    TerminalAPI.setHostUrl(url)
}
