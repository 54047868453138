import oauth from 'axios-oauth-client'

import AxiosHttpFactory from '../axiosHttpFactory'
import { OAuthErrorTypeEnum } from './constants'
import { IApiTokenResponse, IOAuthAPI, ITokenResponse } from './types'

class OAuthAPI implements IOAuthAPI {
    private $oauth = AxiosHttpFactory.createMultiClient({
        ignoreErrorLogging: (response) =>
            response.data?.error === OAuthErrorTypeEnum.InvalidClient ||
            response.data?.error === OAuthErrorTypeEnum.InvalidGrant,
    })

    setHostUrl(url: string) {
        this.$oauth.defaults.baseURL = url
    }

    private transformTokens(tokens: IApiTokenResponse): ITokenResponse {
        return {
            accessToken: tokens.access_token,
            refreshToken: tokens.refresh_token,
            expiresIn: tokens.expires_in,
        }
    }

    async fetchTokens(clientId: string, clientSecret: string, username: string, password: string) {
        if (!this.$oauth.defaults.baseURL) {
            throw new Error('Host url must be set')
        }

        const request = oauth.ownerCredentials(this.$oauth, '/oauth2/token', clientId, clientSecret)

        const tokens = await request(username, password, 'terminal')

        return this.transformTokens(tokens)
    }
}

export default new OAuthAPI()
