import { FC } from 'react'
import { StyleProp, StyleSheet, TextStyle, ViewStyle, useWindowDimensions } from 'react-native'
import { Text, TouchableRipple } from 'react-native-paper'

import { ColorEnum } from '../../constants/color'

interface ChipProps {
    text: string
    subText?: string
    chosen?: boolean
    disabled?: boolean
    onPress: () => void
    style?: StyleProp<ViewStyle>
    textStyle?: StyleProp<TextStyle>
}

const Chip: FC<ChipProps> = ({ text, subText, chosen, disabled, onPress, style, textStyle }) => {
    const { width: windowWidth } = useWindowDimensions()

    return (
        <TouchableRipple
            borderless
            disabled={disabled}
            onPress={onPress}
            style={[styles.container, chosen && styles.containerChosen, style]}
        >
            <>
                <Text style={[styles.text, textStyle, chosen && styles.textChosen]}>{text}</Text>
                {subText && (
                    <Text
                        style={[
                            styles.subText,
                            windowWidth < 400 ? { fontSize: 10 } : {},
                            chosen && styles.textChosen,
                        ]}
                    >
                        {subText}
                    </Text>
                )}
            </>
        </TouchableRipple>
    )
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: ColorEnum.VIOLET_200,
        borderRadius: 8,
        width: 80,
        height: 40,
        paddingVertical: 8,
        margin: 4,
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerChosen: {
        backgroundColor: ColorEnum.YELLOW_600,
    },
    text: {
        fontFamily: 'Satoshi-Regular',
        fontSize: 14,
        textTransform: 'uppercase',
        textAlign: 'center',
    },
    subText: {
        fontFamily: 'Satoshi-Regular',
        fontSize: 12,
        textTransform: 'uppercase',
        textAlign: 'center',
        opacity: 0.8,
    },
    textChosen: {
        color: ColorEnum.WHITE,
    },
})

export default Chip
