import { FC, ReactNode } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { Text } from 'react-native-paper'

import { ColorEnum } from '../../../constants/color'
import { INotification } from '../../../types/notification'
import CloseButton from './CloseButton'
import Date from './Date'
import NotificationContext from './NotificationContext'
import NotificationIcon from './NotificationIcon'
import ProgressBar from './ProgressBar'

interface NotificationProps {
    notification: INotification
    mode?: 'default' | 'rounded'
    Date?: ReactNode
    ProgressBar?: ReactNode
    CloseButton?: ReactNode
    style?: StyleProp<ViewStyle>
}

interface NotificationComponent extends FC<NotificationProps> {
    Date: typeof Date
    ProgressBar: typeof ProgressBar
    CloseButton: typeof CloseButton
}

const Notification: NotificationComponent = ({
    notification,
    mode = 'default',
    Date,
    ProgressBar,
    CloseButton,
    style,
}) => {
    return (
        <NotificationContext.Provider value={{ notification }}>
            <View
                style={[
                    styles.outerContainer,
                    mode === 'rounded' && styles.roundedOuterContainer,
                    style,
                ]}
            >
                <View style={styles.innerContainer}>
                    <NotificationIcon />
                    <View style={styles.contentContainer}>
                        <View style={styles.header}>
                            <Text style={styles.type}>{notification.name}</Text>
                            {Date}
                        </View>
                        <Text>{notification.text}</Text>
                    </View>
                    {CloseButton}
                </View>
                {ProgressBar}
            </View>
        </NotificationContext.Provider>
    )
}

const styles = StyleSheet.create({
    outerContainer: {
        flex: 1,
        backgroundColor: ColorEnum.WHITE,
    },
    roundedOuterContainer: {
        borderRadius: 30,
        paddingVertical: 6,
        paddingHorizontal: 8,
    },
    innerContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    contentContainer: {
        flex: 1,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 4,
    },
    type: {
        textTransform: 'uppercase',
        color: ColorEnum.JET_500,
    },
})

Notification.Date = Date
Notification.ProgressBar = ProgressBar
Notification.CloseButton = CloseButton

export default Notification
