import { FC } from 'react'
import { StyleSheet } from 'react-native'
import { Button, Text } from 'react-native-paper'

import { ColorEnum } from '../../../../constants/color'

interface CancelOrderDialogEditNoteButtonProps {
    note: string
    onPress: () => void
}

const CancelOrderDialogEditNoteButton: FC<CancelOrderDialogEditNoteButtonProps> = ({
    note,
    onPress,
}) => {
    return (
        <Button
            icon="pencil-circle-outline"
            textColor={ColorEnum.BRICK_600}
            onPress={onPress}
            labelStyle={styles.label}
            contentStyle={styles.content}
            style={styles.button}
        >
            <Text style={styles.text}>{note}</Text>
        </Button>
    )
}

const styles = StyleSheet.create({
    label: {
        fontSize: 24,
    },
    content: {
        flexDirection: 'row-reverse',
    },
    text: {
        color: ColorEnum.JET_600,
        fontSize: 14,
    },
    button: {
        alignItems: 'flex-start',
    },
})

export default CancelOrderDialogEditNoteButton
