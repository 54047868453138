import { StackActions, useFocusEffect, useNavigation } from '@react-navigation/native'
import { FC, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Dimensions, StyleSheet, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { authorizationRequestExpirationTime } from '../../../constants/auth'
import { ColorEnum } from '../../../constants/color'
import { deviceAuthorizationScreenShowLogoMinWidth } from '../../../constants/layout'
import { AuthScreenEnum } from '../../../constants/screen'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { clearAuth } from '../../../redux/reducers/auth/action-creators'
import { authSelectors } from '../../../redux/reducers/auth/selectors'
import { selectScreenInfo } from '../../../redux/reducers/screenInfo'
import { verticalScale } from '../../../utils/metrics'
import AuthHeader from '../../Auth/AuthHeader'
import AuthBranchCard from '../../Auth/DeviceAuthorization/AuthBranchCard'
import AuthDeviceAuthorizationCodeField from '../../Auth/DeviceAuthorization/AuthDeviceAuthorizationCodeField'
import AuthDeviceAuthorizationNumPad from '../../Auth/DeviceAuthorization/AuthDeviceAuthorizationNumPad'
import TextLink from '../../UI/TextLink'

const DeviceAuthorizationScreen: FC = () => {
    const hostUrl = useAppSelector(authSelectors.hostUrl)
    const deviceId = useAppSelector(authSelectors.deviceId)
    const deviceToken = useAppSelector(authSelectors.deviceToken)
    useAppSelector(selectScreenInfo)

    const dispatch = useAppDispatch()

    const timerRef = useRef<NodeJS.Timeout | null>(null)

    const { top } = useSafeAreaInsets()

    const { t } = useTranslation()

    const navigation = useNavigation()

    useFocusEffect(() => {
        timerRef.current = setTimeout(() => {
            dispatch(clearAuth(['licenceId']))
        }, authorizationRequestExpirationTime)

        return () => {
            if (!timerRef.current) return

            clearTimeout(timerRef.current)

            timerRef.current = null
        }
    })

    useFocusEffect(
        useCallback(() => {
            if (hostUrl || deviceId) return

            navigation.dispatch(StackActions.replace(AuthScreenEnum.Licence))
        }, [hostUrl, deviceId])
    )

    useFocusEffect(
        useCallback(() => {
            if (!deviceToken) return

            navigation.navigate(AuthScreenEnum.ProfileSelection)
        }, [deviceToken])
    )

    const handleChangeLicenceButtonPress = () => {
        dispatch(clearAuth())
    }

    return (
        <View style={[styles.container, { paddingTop: top }]} testID="deviceAuthorizationScreen">
            <AuthHeader
                title={t('authScreensDeviceAuthorization')}
                hint={t('deviceAuthorizationScreenEnterCode')}
                showLogo={
                    Dimensions.get('screen').height > deviceAuthorizationScreenShowLogoMinWidth
                }
            />
            <AuthBranchCard />
            <TextLink
                onPress={handleChangeLicenceButtonPress}
                style={{ marginTop: verticalScale(8) }}
            >
                {t('deviceAuthorizationScreenChangeLicenceId')}
            </TextLink>
            <AuthDeviceAuthorizationCodeField />
            <AuthDeviceAuthorizationNumPad />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        backgroundColor: ColorEnum.JET_800,
    },
})

export default DeviceAuthorizationScreen
