import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { ILicence } from '../../../types/licence'
import { RootState } from '../../store'
import { fetchLicence } from './action-creators'

interface LicenceState {
    licence: ILicence | null
}

const initialState: LicenceState = {
    licence: null,
}

const licenceSlice = createSlice({
    name: 'licence',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(
            fetchLicence.fulfilled,
            (state, { payload: licence }: PayloadAction<ILicence>) => void (state.licence = licence)
        )
    },
})

export const licenceSelectors = {
    licence: ({ licence }: RootState) => licence.licence,
}

export default licenceSlice.reducer
