import { createAsyncThunk } from '@reduxjs/toolkit'
import { HttpStatusCode } from 'axios'

import ApiErrorTranslatorService from '../../../api/apiErrorTranslator'
import LicenceAPI from '../../../api/licence/licence'
import { ILicence } from '../../../types/licence'
import { setAppOverlay } from '../app'
import { setErrorDialog } from '../dialog'

export const fetchLicence = createAsyncThunk<ILicence, void, { rejectValue: void }>(
    'licence/fetchLicence',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setAppOverlay(true))

            return await LicenceAPI.fetchLicenceData()
        } catch (e) {
            const message = ApiErrorTranslatorService.translateTerminalAPIError(e, {
                [HttpStatusCode.InternalServerError]: 'serverError',
            })

            dispatch(setErrorDialog(message))

            return rejectWithValue()
        } finally {
            dispatch(setAppOverlay(false))
        }
    }
)
