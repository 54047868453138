export const hexToRgba = (hex: string, intensity = 0.5) => {
    if (hex.charAt(0) === '#') {
        hex = hex.substring(1)
    }

    const r = parseInt(hex.slice(0, 2), 16)
    const g = parseInt(hex.slice(2, 4), 16)
    const b = parseInt(hex.slice(4, 6), 16)

    return `rgba(${r}, ${g}, ${b}, ${intensity})`
}
