import { FC } from 'react'

import TopBarClock from './TopBarClock'
import TopBarLogoOrNotification from './TopBarLogoOrNotification'
import TopBarToolbar from './TopBarToolbar'

const TopBarContent: FC = () => {
    return (
        <>
            <TopBarClock />
            <TopBarLogoOrNotification />
            <TopBarToolbar />
        </>
    )
}

export default TopBarContent
