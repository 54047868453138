import Config from 'react-native-config'

import { isAndroid } from './environment'

export const isFirebaseEmulators = Config.ENV === 'mock' || Config.ENV === 'detox'

const FIREBASE_EMULATORS_ANDROID = isFirebaseEmulators && isAndroid ? '_ANDROID' : ''

export const firebaseConfig = {
    apiKey: Config.FIREBASE_API_KEY!,
    authDomain: Config.FIREBASE_AUTH_DOMAIN!,
    databaseURL: Config[`FIREBASE_DATABASE_URL${FIREBASE_EMULATORS_ANDROID}`]!,
    projectId: Config.FIREBASE_PROJECT_ID!,
    storageBucket: Config.FIREBASE_STORAGE_BUCKET!,
    messagingSenderId: Config.FIREBASE_MESSAGING_SENDER_ID!,
    appId: Config.FIREBASE_APP_ID!,
}
