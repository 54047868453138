import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { Text } from 'react-native-paper'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'

import { ColorEnum } from '../../constants/color'
import { horizontalScale, moderateScale } from '../../utils/metrics'
import SideBarMenu from './SideBarMenu'
import SideBarMenuItem from './SideBarMenuItem'

const SideBar: FC = () => {
    const { t } = useTranslation()

    return (
        <View style={[styles.container, { width: horizontalScale(32, 106) }]}>
            <SideBarMenu />

            <View style={styles.userWrapper}>
                <Icon
                    name="account-circle"
                    color={ColorEnum.JET_500}
                    size={moderateScale(27, 40, 0.15)}
                />
                <Text style={[styles.userInitials, { fontSize: moderateScale(10, 16, 0.2) }]}>
                    M.V.
                </Text>
            </View>

            <SideBarMenuItem icon="cog" name={t('menuSettings')} />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: ColorEnum.JET_800,
    },
    userWrapper: {
        marginVertical: 10,
        alignItems: 'center',
    },
    userInitials: {
        color: ColorEnum.WHITE,
        marginTop: 8,
    },
})

export default SideBar
