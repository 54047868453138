import { OrderPhaseEnum } from '../../../constants/order'
import { RootState } from '../../store'

export const ordersSelectors = {
    newOrderUuids: ({ orders }: RootState) => orders.uuidsByPhase[OrderPhaseEnum.New],
    inProgressOrderUuids: ({ orders }: RootState) => orders.uuidsByPhase[OrderPhaseEnum.InProgress],
    futureOrderUuids: ({ orders }: RootState) => orders.uuidsByPhase[OrderPhaseEnum.Future],
    archivedOrderUuids: ({ orders }: RootState) => orders.uuidsByPhase[OrderPhaseEnum.Archived],
    orderByUuid:
        (uuid: string) =>
        ({ orders }: RootState) =>
            orders.uuidOrderMap[uuid],
    isOrdersLoaded: ({ orders }: RootState) => orders.isOrdersLoaded,
    selectedOrderUuid: ({ orders }: RootState) => orders.selectedOrderUuid,
}
