import dayjs, { Dayjs, ManipulateType } from 'dayjs'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, useWindowDimensions } from 'react-native'

import { useAppSelector } from '../../../../../../hooks/redux'
import { ordersSelectors } from '../../../../../../redux/reducers/orders/selectors'
import { ICustomPostponeOrderTime } from '../../../../../../types/customPostponeOrderTime'
import { TimeAdjusterShiftValue } from '../../../../../../types/timeAdjuster'
import {
    calculateCustomPostponeOrderTime,
    calculatePostponedUntil,
} from '../../../../../../utils/postponeOrderTime'
import TimeAdjuster from '../../../../../UI/TimeAdjuster'

interface CustomPostponeOrderTimeAdjusterProps {
    orderUuid: string
    customTime: ICustomPostponeOrderTime
    currentTime: Dayjs
    onTimeShiftPress: (customTime: ICustomPostponeOrderTime) => void
    onCancelButton: () => void
    onConfirmButton: () => void
}

const CustomPostponeOrderTimeAdjuster: FC<CustomPostponeOrderTimeAdjusterProps> = ({
    orderUuid,
    customTime,
    currentTime,
    onTimeShiftPress,
    onCancelButton,
    onConfirmButton,
}) => {
    const order = useAppSelector(ordersSelectors.orderByUuid(orderUuid))

    const { width: windowWidth } = useWindowDimensions()
    const { t } = useTranslation()

    if (!order) {
        return null
    }

    const handleTimeShiftPress = (value: number, timeUnit: ManipulateType) => {
        const newTime = calculateCustomPostponeOrderTime(customTime, value, timeUnit)

        onTimeShiftPress(newTime)
    }

    const checkIsApplyButtonDisabled = () => {
        if (customTime.hours === 0 && customTime.minutes === 0) {
            return true
        }

        return calculatePostponedUntil(order, dayjs.duration(customTime)).isBefore(currentTime)
    }

    const checkChipDisabled = (option: TimeAdjusterShiftValue) => {
        const newTime = calculateCustomPostponeOrderTime(customTime, option.value, option.timeUnit)
        const postponeTime = dayjs.duration(newTime)

        if (option.value < 0) {
            return postponeTime.asHours() < 0
        }

        return calculatePostponedUntil(order, postponeTime).isBefore(currentTime)
    }

    const applyButtonDisabled = checkIsApplyButtonDisabled()

    return (
        <TimeAdjuster
            title={t('order.detail.customPostponeTitle')}
            time={`${customTime.hours} h ${customTime.minutes} m`}
            applyButtonDisabled={applyButtonDisabled}
            onTimeShiftPress={handleTimeShiftPress}
            onCancelButton={onCancelButton}
            onConfirmButton={onConfirmButton}
            checkDisabled={checkChipDisabled}
            titleStyle={windowWidth < 400 && styles.title}
        />
    )
}

const styles = StyleSheet.create({
    title: {
        fontSize: 14,
    },
})

export default CustomPostponeOrderTimeAdjuster
