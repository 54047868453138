import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

import { CancelOrderReason } from '../../../../types/cancelOrder'
import CircleRadioButton from '../../../UI/buttons/CircleRadioButton'

interface CancelOrderDialogReasonRadioButtonsProps {
    reason: CancelOrderReason
    onReasonChange: (reason: CancelOrderReason) => void
}

const CancelOrderDialogReasonRadioButtons: FC<CancelOrderDialogReasonRadioButtonsProps> = ({
    reason,
    onReasonChange,
}) => {
    const { t } = useTranslation()

    return (
        <View style={{ flexDirection: 'row' }}>
            <CircleRadioButton
                checked={reason === 'customer'}
                value="customer"
                onPress={onReasonChange}
                style={{ marginRight: 8 }}
            >
                {t('order.detail.cancelDialog.customerReason')}
            </CircleRadioButton>
            <CircleRadioButton
                checked={reason === 'restaurant'}
                value="restaurant"
                onPress={onReasonChange}
            >
                {t('order.detail.cancelDialog.restaurantReason')}
            </CircleRadioButton>
        </View>
    )
}

export default CancelOrderDialogReasonRadioButtons
