import React, { FC } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { Text } from 'react-native-paper'

import { ColorEnum } from '../../constants/color'

interface TagProps {
    text: string
    isPositive: boolean
    style?: StyleProp<ViewStyle>
}

const Tag: FC<TagProps> = ({ text, isPositive, style }) => {
    return (
        <View style={[styles.tag, isPositive ? styles.positive : styles.negative, style]}>
            <Text style={[isPositive ? styles.positiveText : styles.negativeText]}>{text}</Text>
        </View>
    )
}

const styles = StyleSheet.create({
    tag: {
        borderRadius: 4,
        paddingHorizontal: 8,
        paddingVertical: 4,
    },
    positive: {
        backgroundColor: ColorEnum.EMERALD_200,
    },
    positiveText: {
        fontFamily: 'Satoshi-Bold',
        color: ColorEnum.EMERALD_700,
        fontSize: 12,
    },
    negative: {
        backgroundColor: ColorEnum.BRICK_200,
    },
    negativeText: {
        fontFamily: 'Satoshi-Bold',
        color: ColorEnum.BRICK_700,
        fontSize: 12,
    },
})

export default Tag
