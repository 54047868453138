import { FC } from 'react'
import { StyleSheet } from 'react-native'
import { IconButton } from 'react-native-paper'

import { ColorEnum } from '../../constants/color'
import { topBarMarginHorizontalSide } from '../../constants/layout'
import { useAppDispatch } from '../../hooks/redux'
import { openGridMenu } from '../../redux/reducers/app'
import { hexToRgba } from '../../utils/hexToRgba'
import { horizontalScale, moderateScale } from '../../utils/metrics'

const TopBarMenuIcon: FC = () => {
    const dispatch = useAppDispatch()

    const handlePress = () => {
        dispatch(openGridMenu())
    }

    const marginHorizontalSide = horizontalScale(topBarMarginHorizontalSide)
    const iconSize = moderateScale(21, 26, 0.2)

    return (
        <IconButton
            icon="view-grid-outline"
            iconColor={ColorEnum.WHITE}
            size={iconSize}
            style={[styles.icon, { marginLeft: marginHorizontalSide }]}
            rippleColor={hexToRgba(ColorEnum.JET_800)}
            onPress={handlePress}
        />
    )
}

const styles = StyleSheet.create({
    icon: {
        margin: 0,
    },
})

export default TopBarMenuIcon
