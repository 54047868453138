import dayjs from 'dayjs'
import { FC, useCallback, useEffect, useState } from 'react'

import TerminalAPI from '../../../../../../api/terminal/terminal'
import { useAppSelector } from '../../../../../../hooks/redux'
import { useIntervaledState } from '../../../../../../hooks/useIntervalState'
import { ordersSelectors } from '../../../../../../redux/reducers/orders/selectors'
import { ICustomPostponeOrderTime } from '../../../../../../types/customPostponeOrderTime'
import { PostponeOrderTimeValues } from '../../../../../../types/order-flow-action-time-values'
import {
    calculatePostponedUntil,
    getPostponementTimeAsDuration,
} from '../../../../../../utils/postponeOrderTime'
import CustomPostponeOrderTimeAdjuster from './CustomPostponeOrderTimeAdjuster'
import DefaultPostponeOrderTimeSelector from './DefaultPostponeOrderTimeSelector'

interface PostponeOrderTimeSelectorProps {
    orderUuid: string
    onBackButton: () => void
}

const defaultCustomPostponementTime: ICustomPostponeOrderTime = {
    hours: 0,
    minutes: 0,
}

const PostponeOrderTimeSelector: FC<PostponeOrderTimeSelectorProps> = ({
    orderUuid,
    onBackButton,
}) => {
    const order = useAppSelector(ordersSelectors.orderByUuid(orderUuid))

    const [postponementTime, setPostponementTime] = useState<PostponeOrderTimeValues>()
    const [customPostponementTime, setCustomPostponementTime] = useState<ICustomPostponeOrderTime>(
        defaultCustomPostponementTime
    )
    const [isCustomValueMode, setIsCustomValueMode] = useState(false)

    const currentTime = useIntervaledState(
        useCallback(() => {
            return dayjs()
        }, []),
        60000
    )

    useEffect(() => {
        if (!order || !postponementTime) return

        const durationTime = getPostponementTimeAsDuration(postponementTime, customPostponementTime)

        if (calculatePostponedUntil(order, durationTime).isAfter(currentTime)) return

        setPostponementTime(undefined)
    }, [postponementTime, currentTime, order])

    if (!order) {
        return null
    }

    const handleDefaultTimePress = (value: PostponeOrderTimeValues) => {
        if (value === 'custom') {
            setIsCustomValueMode(true)
            return
        }
        setPostponementTime(value)
    }

    const handlePostponeOrderTime = async () => {
        if (!postponementTime) return

        const durationTime = getPostponementTimeAsDuration(postponementTime, customPostponementTime)

        const postponeUntil = calculatePostponedUntil(order, durationTime).format()

        await TerminalAPI.postponeOrder(orderUuid, postponeUntil)
    }

    const handleCustomTimeShiftPress = (newCustomPostponeOrderTime: ICustomPostponeOrderTime) => {
        setCustomPostponementTime(newCustomPostponeOrderTime)
    }

    const handleCancelCustomButton = () => {
        setCustomPostponementTime(defaultCustomPostponementTime)
        setIsCustomValueMode(false)

        if (postponementTime !== 'custom') return

        setPostponementTime(undefined)
    }

    const handleConfirmCustomButton = () => {
        const durationTime = dayjs.duration(customPostponementTime)

        setPostponementTime(
            calculatePostponedUntil(order, durationTime).isBefore(currentTime)
                ? undefined
                : 'custom'
        )
        setIsCustomValueMode(false)
    }

    return (
        <>
            {!isCustomValueMode ? (
                <DefaultPostponeOrderTimeSelector
                    orderUuid={orderUuid}
                    postponementTime={postponementTime}
                    customTime={customPostponementTime}
                    currentTime={currentTime}
                    onTimePress={handleDefaultTimePress}
                    onBackButton={onBackButton}
                    onPostponeButton={handlePostponeOrderTime}
                />
            ) : (
                <CustomPostponeOrderTimeAdjuster
                    orderUuid={orderUuid}
                    customTime={customPostponementTime}
                    currentTime={currentTime}
                    onTimeShiftPress={handleCustomTimeShiftPress}
                    onCancelButton={handleCancelCustomButton}
                    onConfirmButton={handleConfirmCustomButton}
                />
            )}
        </>
    )
}

export default PostponeOrderTimeSelector
