import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import { Button } from 'react-native-paper'

import { ColorEnum } from '../../../../../../constants/color'
import { useAppSelector } from '../../../../../../hooks/redux'
import { selectScreenInfo } from '../../../../../../redux/reducers/screenInfo'

interface DefaultPostponeOrderButtonsProps {
    onBack: () => void
    postponeButtonDisabled: boolean
    onPostpone: () => Promise<void>
}

const DefaultPostponeOrderButtons: FC<DefaultPostponeOrderButtonsProps> = ({
    onBack,
    postponeButtonDisabled,
    onPostpone,
}) => {
    const screenInfo = useAppSelector(selectScreenInfo)

    const [postponeLoading, setPostponeLoading] = useState(false)

    const { t } = useTranslation()

    const handleOrderAccept = async () => {
        try {
            setPostponeLoading(true)

            await onPostpone()
        } finally {
            setPostponeLoading(false)
        }
    }

    return (
        <View style={styles.container}>
            <Button
                icon="close"
                mode="contained"
                buttonColor={ColorEnum.BRICK_500}
                disabled={postponeLoading}
                onPress={onBack}
                maxFontSizeMultiplier={1}
                labelStyle={[styles.buttonIcon, !screenInfo.isLargeScreen && { fontSize: 20 }]}
                style={styles.button}
            >
                <Text style={[styles.buttonText, !screenInfo.isLargeScreen && { fontSize: 15 }]}>
                    {t('back')}
                </Text>
            </Button>
            <Button
                icon="check"
                mode="contained"
                buttonColor={ColorEnum.EMERALD_500}
                disabled={postponeButtonDisabled || postponeLoading}
                loading={postponeLoading}
                onPress={handleOrderAccept}
                maxFontSizeMultiplier={1}
                labelStyle={[styles.buttonIcon, !screenInfo.isLargeScreen && { fontSize: 20 }]}
                style={styles.button}
            >
                <Text style={[styles.buttonText, !screenInfo.isLargeScreen && { fontSize: 15 }]}>
                    {!screenInfo.isLargeScreen ? t('apply') : t('order.detail.acceptPostpone')}
                </Text>
            </Button>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        gap: 10,
    },
    button: {
        width: '40%',
        borderRadius: 8,
    },
    buttonIcon: {
        fontSize: 20,
        alignItems: 'center',
    },
    buttonText: {
        fontSize: 11,
        textTransform: 'uppercase',
    },
})

export default DefaultPostponeOrderButtons
