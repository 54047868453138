import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { FC } from 'react'

import { OrdersModuleScreenEnum, mobileOrdersModuleStackId } from '../../../constants/screen'
import { OrdersModuleStackParamList } from '../../../types/navigation'
import MapScreen from '../../screens/orders-module/MapScreen'
import MobileOrderDetailScreen from '../../screens/orders-module/MobileOrderDetailScreen'
import OrdersTabs from './OrdersTabs'

const Stack = createNativeStackNavigator<OrdersModuleStackParamList>()

const MobileOrdersModuleStack: FC = () => {
    const getInitialRouteName = () => {
        return OrdersModuleScreenEnum.Tabs
    }

    return (
        <Stack.Navigator
            id={mobileOrdersModuleStackId}
            initialRouteName={getInitialRouteName()}
            screenOptions={{ animation: 'none', headerShown: false }}
        >
            <Stack.Screen name={OrdersModuleScreenEnum.Tabs} component={OrdersTabs} />
            <Stack.Screen
                name={OrdersModuleScreenEnum.Detail}
                component={MobileOrderDetailScreen}
            />
            <Stack.Screen name={OrdersModuleScreenEnum.Map} component={MapScreen} />
        </Stack.Navigator>
    )
}

export default MobileOrdersModuleStack
