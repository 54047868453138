import i18n from 'i18next'
import intervalPlural from 'i18next-intervalplural-postprocessor'
import { initReactI18next } from 'react-i18next'

import { resources } from './resources'

i18n.use(initReactI18next).use(intervalPlural).init({
    compatibilityJSON: 'v3',
    resources,
    lng: 'cs',
})

export default i18n
