import { CancelOrderReason } from '../../types/cancelOrder'
import { IDeliveryTime } from '../../types/order'
import AxiosHttpFactory from '../axiosHttpFactory'
import { ITerminalAPI } from './types'

class TerminalAPI implements ITerminalAPI {
    private $terminalAPI = AxiosHttpFactory.createMultiClient()

    setHostUrl(url: string) {
        this.$terminalAPI.defaults.baseURL = `${url}/api/terminal`
    }

    setAuthorizationToken(token: string) {
        this.$terminalAPI.defaults.headers.common['Authorization'] = `Bearer ${token}`
    }

    async acceptOrder(uuid: string, acceptedDeliveryTime: IDeliveryTime) {
        await this.$terminalAPI.post('/orders/actions/accept', {
            uuid,
            acceptedDeliveryTime,
        })
    }

    async cancelOrder(uuid: string, reason: CancelOrderReason, note?: string) {
        await this.$terminalAPI.post('/orders/actions/cancel', {
            uuid,
            reason,
            ...(note ? { note } : {}),
        })
    }

    async fetchFirebaseCustomToken(): Promise<string> {
        const { data } = await this.$terminalAPI.post<{ customToken: string }>(
            '/firebase-custom-tokens'
        )

        return data.customToken
    }

    async postponeOrder(uuid: string, postponeUntil: string) {
        await this.$terminalAPI.post('/orders/actions/postpone', {
            uuid,
            postponeUntil,
        })
    }

    async startOrderProduction(uuid: string) {
        await this.$terminalAPI.post('/orders/actions/start-production', {
            uuid,
        })
    }

    async finishOrderDelivery(uuid: string) {
        await this.$terminalAPI.post('/orders/actions/finish-delivery', { uuid })
    }

    async finishOrderProduction(uuid: string) {
        await this.$terminalAPI.post('/orders/actions/finish-production', { uuid })
    }

    async startOrderDelivery(uuid: string) {
        await this.$terminalAPI.post('/orders/actions/start-delivery', { uuid })
    }
}

export default new TerminalAPI()
