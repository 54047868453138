import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootState } from '../../store'

interface IError {
    visible: boolean
    message: string | null
}

interface DialogState {
    error: IError
}

const initialState: DialogState = {
    error: {
        visible: false,
        message: null,
    },
}

const dialogSlice = createSlice({
    name: 'dialog',
    initialState,
    reducers: {
        setErrorDialog: (state, { payload: message }: PayloadAction<string>) => {
            state.error = { visible: true, message }
        },
        closeErrorDialog: (state) => {
            state.error = initialState.error
        },
    },
})

export const { setErrorDialog, closeErrorDialog } = dialogSlice.actions

export const dialogSelectors = {
    error: ({ dialog }: RootState) => dialog.error,
}

export default dialogSlice.reducer
