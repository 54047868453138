import { StackActions, useFocusEffect, useNavigation } from '@react-navigation/native'
import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { IconButton } from 'react-native-paper'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { ColorEnum } from '../../../constants/color'
import { AuthScreenEnum } from '../../../constants/screen'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { clearAuth, fetchAuthProfiles } from '../../../redux/reducers/auth/action-creators'
import { authSelectors } from '../../../redux/reducers/auth/selectors'
import { selectScreenInfo } from '../../../redux/reducers/screenInfo'
import { moderateScale, verticalScale } from '../../../utils/metrics'
import AuthHeader from '../../Auth/AuthHeader'
import AuthProfilesList from '../../Auth/ProfileSelection/AuthProfilesList'
import TextLink from '../../UI/TextLink'

const ProfileSelectionScreen: FC = () => {
    const profile = useAppSelector(authSelectors.profile)
    const deviceToken = useAppSelector(authSelectors.deviceToken)
    useAppSelector(selectScreenInfo)

    const dispatch = useAppDispatch()

    const { top } = useSafeAreaInsets()

    const { t } = useTranslation()

    const navigation = useNavigation()

    useFocusEffect(
        useCallback(() => {
            if (!profile) return

            navigation.navigate(AuthScreenEnum.ProfilePin)
        }, [profile])
    )

    useFocusEffect(
        useCallback(() => {
            if (deviceToken) return

            navigation.dispatch(StackActions.replace(AuthScreenEnum.Licence))
        }, [deviceToken])
    )

    const handleChangeLicenceButtonPress = () => {
        dispatch(clearAuth())
    }

    const handleReloadButtonPress = () => {
        dispatch(fetchAuthProfiles())
    }

    return (
        <View style={[styles.container, { paddingTop: top }]} testID="profileSelectionScreen">
            <AuthHeader
                title={t('profileScreensLogin')}
                hint={t('profileSelectionScreenSelectProfile')}
            />
            <TextLink
                onPress={handleChangeLicenceButtonPress}
                style={{ marginTop: verticalScale(14) }}
            >
                {t('profileSelectionScreenChangeLicence')}
            </TextLink>
            <IconButton
                icon="reload"
                iconColor={ColorEnum.WHITE}
                size={moderateScale(24, 34, 0.2)}
                onPress={handleReloadButtonPress}
                style={styles.reloadButton}
                accessibilityLabel={t('accessibilityLabels.buttons.reload')}
            />
            {deviceToken && <AuthProfilesList />}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        backgroundColor: ColorEnum.JET_800,
    },
    reloadButton: {
        margin: 0,
    },
})

export default ProfileSelectionScreen
