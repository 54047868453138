import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'
import { Dialog, Text } from 'react-native-paper'

import TerminalAPI from '../../../../api/terminal/terminal'
import { CancelOrderReason } from '../../../../types/cancelOrder'
import CancelOrderDialogActionButtons from './CancelOrderDialogActionButtons'
import CancelOrderDialogEditNoteButton from './CancelOrderDialogEditNoteButton'
import CancelOrderDialogInsertNoteButton from './CancelOrderDialogInsertNoteButton'
import CancelOrderDialogReasonRadioButtons from './CancelOrderDialogReasonRadioButtons'

interface CancelOrderDialogOptionsFormProps {
    uuid: string
    reason: CancelOrderReason
    note: string
    onReasonChange: (reason: CancelOrderReason) => void
    onDismiss: () => void
    onNoteButtonPress: () => void
}

const CancelOrderDialogOptionsForm: FC<CancelOrderDialogOptionsFormProps> = ({
    uuid,
    reason,
    note,
    onReasonChange,
    onDismiss,
    onNoteButtonPress,
}) => {
    const { t } = useTranslation()

    const handleConfirm = async () => {
        await TerminalAPI.cancelOrder(uuid, reason, note)

        onDismiss()
    }

    return (
        <>
            <Dialog.Title style={styles.title}>
                {t('order.detail.cancelDialog.optionsFormTitle')}
            </Dialog.Title>
            <Dialog.Content>
                <Text style={styles.label}>{t('order.detail.cancelDialog.reasonLabel')}</Text>
                <CancelOrderDialogReasonRadioButtons
                    reason={reason}
                    onReasonChange={onReasonChange}
                />
                {note ? (
                    <>
                        <Text style={[styles.label, { marginTop: 24 }]}>
                            {t('order.detail.cancelDialog.noteLabel')}
                        </Text>
                        <CancelOrderDialogEditNoteButton note={note} onPress={onNoteButtonPress} />
                    </>
                ) : (
                    <CancelOrderDialogInsertNoteButton onPress={onNoteButtonPress} />
                )}
            </Dialog.Content>
            <CancelOrderDialogActionButtons onConfirm={handleConfirm} onCancel={onDismiss} />
        </>
    )
}

const styles = StyleSheet.create({
    title: {
        fontSize: 18,
        textAlign: 'center',
    },
    label: {
        fontSize: 16,
        marginBottom: 10,
    },
})

export default CancelOrderDialogOptionsForm
