import { ListRenderItem } from '@shopify/flash-list'
import { FC, useEffect, useRef } from 'react'
import { Dimensions, StyleProp, View, ViewStyle } from 'react-native'

import { topBarMarginHorizontalSide } from '../../../constants/layout'
import { INotification } from '../../../types/notification'
import { horizontalScale } from '../../../utils/metrics'
import NotificationButton from './NotificationButton'
import NotificationCenterPaper, {
    paperMaxWidth,
    paperTriangleSize,
} from './NotificationCenterPaper'

interface NotificationCenterProps {
    visible: boolean
    notifications: INotification[] | null
    renderNotification: ListRenderItem<INotification>
    onPress: () => void
}

const NotificationCenter: FC<NotificationCenterProps> = ({
    visible,
    notifications,
    renderNotification,
    onPress,
}) => {
    const buttonRef = useRef<View>(null)

    const paperTriangleStyleRef = useRef<StyleProp<ViewStyle>>()
    const paperStyleRef = useRef<StyleProp<ViewStyle>>()

    const setPaperStyleRef = () => {
        if (!buttonRef.current) return

        buttonRef.current.measure((_, _1, buttonWidth, buttonHeight, _3, buttonPageY) => {
            const windowHeight = Dimensions.get('window').height
            const windowWidth = Dimensions.get('window').width
            const marginHorizontalSide = horizontalScale(topBarMarginHorizontalSide)
            const isOffset = windowWidth > paperMaxWidth + marginHorizontalSide
            const paperTriangleInButtonMiddle = buttonWidth / 2 - paperTriangleSize
            const paperTopOffset = buttonPageY + buttonHeight
            const paperHeight = windowHeight - paperTopOffset

            paperTriangleStyleRef.current = {
                right: isOffset
                    ? paperTriangleInButtonMiddle
                    : paperTriangleInButtonMiddle + marginHorizontalSide,
            }

            paperStyleRef.current = {
                height: isOffset ? paperHeight - marginHorizontalSide : paperHeight,
                top: paperTopOffset,
                right: isOffset ? marginHorizontalSide : 0,
            }
        })
    }

    useEffect(() => {
        setPaperStyleRef()
    })

    return (
        <>
            <NotificationButton
                visible={visible}
                count={notifications?.length ?? 0}
                onPress={onPress}
                onLayout={setPaperStyleRef}
                ref={buttonRef}
            />
            {visible && (
                <NotificationCenterPaper
                    notifications={notifications}
                    renderNotification={renderNotification}
                    triangleStyle={paperTriangleStyleRef.current}
                    style={paperStyleRef.current}
                />
            )}
        </>
    )
}

export default NotificationCenter
