import { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet } from 'react-native'
import { MD3Elevation, Surface, Text } from 'react-native-paper'

import { ColorEnum } from '../../../constants/color'
import { OrderPhaseEnum, OrderStateEnum } from '../../../constants/order'
import { useAppSelector } from '../../../hooks/redux'
import { ordersSelectors } from '../../../redux/reducers/orders/selectors'
import CancelOrderDialog from './CancelOrderDialog/CancelOrderDialog'
import OrderDetailFooter from './Footer/OrderDetailFooter'
import OrderDetailHeader from './Header/OrderDetailHeader'
import OrderDetailItemList from './Items/OrderDetailItemList'
import OrderDetailAcceptedOverlay from './OrderDetailAcceptedOverlay'

interface OrderDetailProps {
    uuid: string
    elevation?: MD3Elevation
}

const OrderDetail: FC<OrderDetailProps> = ({ uuid, elevation = 0 }) => {
    const order = useAppSelector(ordersSelectors.orderByUuid(uuid))

    const [showCancelOrderDialog, setShowCancelOrderDialog] = useState(false)
    const [isAcceptedOverlay, setIsAcceptedOverlay] = useState(false)

    const prevOrderState = useRef<OrderStateEnum>()
    const overlayTimeoutRef = useRef<NodeJS.Timeout>()

    const { t } = useTranslation()

    useEffect(() => {
        if (!order) return

        if (
            prevOrderState.current === OrderStateEnum.AwaitingAcceptance &&
            order.state === OrderStateEnum.Accepted
        ) {
            setIsAcceptedOverlay(true)

            overlayTimeoutRef.current = setTimeout(() => {
                setIsAcceptedOverlay(false)
            }, 1500)
        }

        prevOrderState.current = order.state
    }, [order?.state])

    useEffect(() => {
        return () => {
            if (!overlayTimeoutRef.current) return

            clearTimeout(overlayTimeoutRef.current)
        }
    }, [])

    if (!order) {
        return null
    }

    const handleShowHistoryPress = () => {
        return
    }

    const handleOrderCancelButton = () => {
        setShowCancelOrderDialog(true)
    }

    const handleCancelOrderDialogDismiss = () => {
        setShowCancelOrderDialog(false)
    }

    return (
        <Surface elevation={elevation} style={styles.container}>
            <ScrollView>
                <OrderDetailHeader uuid={order.uuid} style={styles.header} />
                <OrderDetailItemList
                    items={order.items}
                    deliveryPrice={order.deliveryPrice}
                    totalPrice={order.price}
                    currency={order.currency}
                />
                <Text style={styles.showHistoryButton} onPress={handleShowHistoryPress}>
                    {t('order.detail.showHistory')}
                </Text>
            </ScrollView>
            {order.phase !== OrderPhaseEnum.Archived && (
                <OrderDetailFooter
                    orderUuid={order.uuid}
                    onOrderCancelButton={handleOrderCancelButton}
                />
            )}

            {showCancelOrderDialog && (
                <CancelOrderDialog uuid={order.uuid} onDismiss={handleCancelOrderDialogDismiss} />
            )}
            {isAcceptedOverlay && <OrderDetailAcceptedOverlay />}
        </Surface>
    )
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: ColorEnum.WHITE,
        height: '100%',
    },
    header: {
        marginBottom: 16,
    },
    showHistoryButton: {
        textAlign: 'center',
        fontFamily: 'Satoshi-Bold',
        letterSpacing: 1,
        color: ColorEnum.BRICK_600,
        marginHorizontal: 'auto',
        textTransform: 'uppercase',
        padding: 8,
        marginVertical: 16,
    },
})

export default OrderDetail
