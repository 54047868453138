import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { SectionList, StyleSheet } from 'react-native'

import { ColorEnum } from '../../../constants/color'
import { OrderPhaseEnum } from '../../../constants/order'
import { useAppSelector } from '../../../hooks/redux'
import { ordersSelectors } from '../../../redux/reducers/orders/selectors'
import FutureOrderListItem from '../OrderListItem/FutureOrderListItem'
import InProgressOrderListItem from '../OrderListItem/InProgressOrderListItem'
import NewOrderListItem from '../OrderListItem/NewOrderListItem'
import OrderListSectionHeader from './OrderListSectionHeader'

const OrdersList: FC = () => {
    const newOrderUuids = useAppSelector(ordersSelectors.newOrderUuids)
    const inProgressOrderUuids = useAppSelector(ordersSelectors.inProgressOrderUuids)
    const futureOrderUuids = useAppSelector(ordersSelectors.futureOrderUuids)
    const selectedOrderUuid = useAppSelector(ordersSelectors.selectedOrderUuid)

    const { t } = useTranslation()

    const data = [
        {
            title: t('ordersListSectionNew'),
            data: newOrderUuids,
            phase: OrderPhaseEnum.New,
        },
        {
            title: t('ordersListSectionProcess'),
            data: inProgressOrderUuids,
            phase: OrderPhaseEnum.InProgress,
        },
        {
            title: t('ordersListSectionOncoming'),
            data: futureOrderUuids,
            phase: OrderPhaseEnum.Future,
        },
    ]

    return (
        <SectionList
            sections={data}
            renderItem={({ item: uuid, section }) => {
                switch (section.phase) {
                    case OrderPhaseEnum.New:
                        return (
                            <NewOrderListItem uuid={uuid} selected={uuid === selectedOrderUuid} />
                        )
                    case OrderPhaseEnum.InProgress:
                        return (
                            <InProgressOrderListItem
                                uuid={uuid}
                                selected={uuid === selectedOrderUuid}
                            />
                        )
                    case OrderPhaseEnum.Future:
                        return (
                            <FutureOrderListItem
                                uuid={uuid}
                                selected={uuid === selectedOrderUuid}
                            />
                        )
                    default:
                        return null
                }
            }}
            keyExtractor={(uuid) => uuid}
            renderSectionHeader={({ section: { title, data } }) => (
                <OrderListSectionHeader
                    title={title}
                    itemsCount={data.length}
                    style={{
                        marginTop: 24,
                        marginBottom: 10,
                    }}
                />
            )}
            style={[styles.list]}
        />
    )
}

const styles = StyleSheet.create({
    list: {
        backgroundColor: ColorEnum.WHITE,
        flex: 1,
    },
})

export default OrdersList
