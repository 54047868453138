import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { FC } from 'react'

import { AuthScreenEnum } from '../../constants/screen'
import { useAppSelector } from '../../hooks/redux'
import { authSelectors } from '../../redux/reducers/auth/selectors'
import { AuthStackParamList } from '../../types/navigation'
import DeviceAuthorizationScreen from '../screens/auth/DeviceAuthorizationScreen'
import LicenceVerificationScreen from '../screens/auth/LicenceVerificationScreen'
import ProfilePinScreen from '../screens/auth/ProfilePinScreen'
import ProfileSelectionScreen from '../screens/auth/ProfileSelectionScreen'

const Stack = createNativeStackNavigator<AuthStackParamList>()

const AuthStack: FC = () => {
    const profile = useAppSelector(authSelectors.profile)
    const deviceAuth = useAppSelector(authSelectors.deviceAuth)

    const getInitialRouteName = () => {
        if (profile) {
            return AuthScreenEnum.ProfilePin
        }

        if (deviceAuth) {
            return AuthScreenEnum.ProfileSelection
        }

        return AuthScreenEnum.Licence
    }

    return (
        <Stack.Navigator
            initialRouteName={getInitialRouteName()}
            screenOptions={{ animation: 'none', headerShown: false }}
        >
            <Stack.Screen name={AuthScreenEnum.Licence} component={LicenceVerificationScreen} />
            <Stack.Screen name={AuthScreenEnum.Device} component={DeviceAuthorizationScreen} />
            <Stack.Screen
                name={AuthScreenEnum.ProfileSelection}
                component={ProfileSelectionScreen}
            />
            <Stack.Screen name={AuthScreenEnum.ProfilePin} component={ProfilePinScreen} />
        </Stack.Navigator>
    )
}

export default AuthStack
