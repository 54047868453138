import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'
import { Text } from 'react-native-paper'

import { ColorEnum } from '../../../constants/color'
import { DeliveryTypeCategoryEnum, OrderChannelEnum } from '../../../constants/order'

interface DeliveryTypeCategoryAndChannelProps {
    deliveryTypeCategory: DeliveryTypeCategoryEnum
    channel: OrderChannelEnum
}

const DeliveryTypeCategoryAndChannel: FC<DeliveryTypeCategoryAndChannelProps> = ({
    deliveryTypeCategory,
    channel,
}) => {
    const { t } = useTranslation()

    return (
        <Text numberOfLines={1} style={styles.deliveryTypeCategoryAndChannel}>
            {t(`order.deliveryTypeCategory.${deliveryTypeCategory}`)}
            {' | '}
            {t(`order.channel.${channel}`)}
        </Text>
    )
}

const styles = StyleSheet.create({
    deliveryTypeCategoryAndChannel: {
        flex: 1,
        fontSize: 16,
        color: ColorEnum.GRAY_900,
    },
    code: {
        backgroundColor: ColorEnum.YELLOW_400,
        borderRadius: 4,
        height: 16,
        minWidth: 16,
        textAlign: 'center',
        marginStart: 8,
        fontSize: 12,
    },
})

export default DeliveryTypeCategoryAndChannel
