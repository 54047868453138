import currencyJs from 'currency.js'

export const formatPrice = (amount: string, currency: string) => {
    let symbol

    switch (currency) {
        case 'CZK':
            symbol = 'Kč'
            break
        case 'EUR':
            symbol = '€'
            break
        default:
            symbol = currency
    }

    return currencyJs(amount).format({
        separator: ' ',
        decimal: ',',
        pattern: '# !',
        symbol,
        negativePattern: '-# !',
    })
}
