import { FC } from 'react'
import { StyleSheet, View } from 'react-native'
import { Text } from 'react-native-paper'

import { ColorEnum } from '../../../constants/color'
import { horizontalScale, moderateScale, verticalScale } from '../../../utils/metrics'
import { getNumCharsToShow } from './getNumCharsToShow'

interface CustomTextFieldProps {
    children: string
    placeholder?: string
    customTextFieldID?: string
}

const CustomTextField: FC<CustomTextFieldProps> = ({
    children,
    placeholder,
    customTextFieldID,
}) => {
    const height = verticalScale(80, 64)
    const width = horizontalScale(290, 310)

    const licenceIdFieldStyle = {
        borderRadius: height / 2.4,
        height,
        width,
        marginTop: verticalScale(16),
    }

    const numCharsToShow = getNumCharsToShow(width)

    return (
        <View
            style={[styles.container, licenceIdFieldStyle]}
            testID={
                customTextFieldID
                    ? `${customTextFieldID ? `${customTextFieldID}-` : ''}customTextField`
                    : undefined
            }
        >
            {children ? (
                <Text
                    style={[styles.text, { fontSize: moderateScale(32, 32) }]}
                    testID={
                        customTextFieldID
                            ? `${
                                  customTextFieldID ? `${customTextFieldID}-` : ''
                              }customTextField-text`
                            : undefined
                    }
                    accessibilityLabel={placeholder}
                >
                    {children.slice(-numCharsToShow)}
                </Text>
            ) : (
                <Text style={[styles.placeholder, { fontSize: moderateScale(11, 14, 0.2) }]}>
                    {placeholder}
                </Text>
            )}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: ColorEnum.GRAY_100,
    },
    placeholder: {
        color: ColorEnum.GRAY_600,
    },
    text: {
        fontWeight: 'bold',
        letterSpacing: 4,
    },
})

export default CustomTextField
