import { FC, useState } from 'react'
import { StyleSheet } from 'react-native'
import { Dialog } from 'react-native-paper'

import { ColorEnum } from '../../../../constants/color'
import { useAppSelector } from '../../../../hooks/redux'
import { selectScreenInfo } from '../../../../redux/reducers/screenInfo'
import { CancelOrderReason } from '../../../../types/cancelOrder'
import CancelOrderDialogNoteForm from './CancelOrderDialogNoteForm'
import CancelOrderDialogOptionsForm from './CancelOrderDialogOptionsForm'

interface CancelOrderDialogProps {
    uuid: string
    onDismiss: () => void
}

const CancelOrderDialog: FC<CancelOrderDialogProps> = ({ uuid, onDismiss }) => {
    const screenInfo = useAppSelector(selectScreenInfo)

    const [reason, setReason] = useState<CancelOrderReason>('customer')
    const [note, setNote] = useState('')
    const [isNoteForm, setIsNoteForm] = useState(false)

    const handleReasonChange = (reason: CancelOrderReason) => {
        setReason(reason)
    }

    const handleNoteButtonPress = () => {
        setIsNoteForm(true)
    }

    const handleNoteChange = (note: string) => {
        setNote(note)
    }

    const handleNoteFormCancelButton = () => {
        setNote('')
        setIsNoteForm(false)
    }

    const handleNoteFormApplyButton = () => {
        setIsNoteForm(false)
    }

    const width = screenInfo.isLargeScreen ? '60%' : '90%'

    return (
        <Dialog visible onDismiss={onDismiss} style={[styles.dialog, { width }]}>
            {!isNoteForm ? (
                <CancelOrderDialogOptionsForm
                    uuid={uuid}
                    reason={reason}
                    note={note}
                    onReasonChange={handleReasonChange}
                    onDismiss={onDismiss}
                    onNoteButtonPress={handleNoteButtonPress}
                />
            ) : (
                <CancelOrderDialogNoteForm
                    note={note}
                    onNoteChange={handleNoteChange}
                    onCancelButton={handleNoteFormCancelButton}
                    onApplyButton={handleNoteFormApplyButton}
                />
            )}
        </Dialog>
    )
}

const styles = StyleSheet.create({
    dialog: {
        alignSelf: 'center',
        backgroundColor: ColorEnum.WHITE,
    },
})

export default CancelOrderDialog
