import {
    PostponeOrderTimeValues,
    RequestedDeliveryTimeShiftValues,
} from '../types/order-flow-action-time-values'

export const requestedDeliveryTimeShiftValues: RequestedDeliveryTimeShiftValues[] = [
    0,
    15,
    30,
    45,
    60,
    'custom',
]

export const postponeOrderAvailableShiftValues: PostponeOrderTimeValues[] = [1, 2, 3, 4, 'custom']

export const minimalPostponeOrderTimeInMinutes = 5
