import { FC } from 'react'

import { useAppSelector } from '../../../hooks/redux'
import { authSelectors } from '../../../redux/reducers/auth/selectors'
import { verticalScale } from '../../../utils/metrics'
import IconCard from '../../UI/IconCard/IconCard'

const AuthSelectedProfileCard: FC = () => {
    const profile = useAppSelector(authSelectors.profile)

    return (
        <IconCard
            icon="account"
            title={profile?.name ?? ''}
            subtitle={profile?.description ?? ''}
            style={{ marginTop: verticalScale(15) }}
            iconCardID="profilePinScreen-profile"
        />
    )
}

export default AuthSelectedProfileCard
