import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { Button } from 'react-native-paper'

import { ColorEnum } from '../../../../../constants/color'

interface AcceptedDeliveryTimeButtonsProps {
    onOrderCancel: () => void
    onOrderAccept: () => Promise<void>
}

const AcceptedDeliveryTimeButtons: FC<AcceptedDeliveryTimeButtonsProps> = ({
    onOrderCancel,
    onOrderAccept,
}) => {
    const [acceptOrderLoading, setAcceptOrderLoading] = useState(false)

    const { t } = useTranslation()

    const handleOrderAccept = async () => {
        try {
            setAcceptOrderLoading(true)

            await onOrderAccept()
        } finally {
            setAcceptOrderLoading(false)
        }
    }

    return (
        <View style={styles.container}>
            <Button
                icon="close"
                mode="contained"
                buttonColor={ColorEnum.BRICK_500}
                disabled={acceptOrderLoading}
                onPress={onOrderCancel}
                style={styles.button}
            >
                {t('order.detail.cancel')}
            </Button>
            <Button
                icon="check"
                mode="contained"
                buttonColor={ColorEnum.EMERALD_500}
                disabled={acceptOrderLoading}
                loading={acceptOrderLoading}
                onPress={handleOrderAccept}
                style={styles.button}
            >
                {t('order.detail.accept')}
            </Button>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'center',
        width: 400,
        gap: 10,
    },
    button: {
        width: 168,
        borderRadius: 8,
    },
})

export default AcceptedDeliveryTimeButtons
