import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'
import { Button, Dialog } from 'react-native-paper'

import { ColorEnum } from '../../../../constants/color'

interface CancelOrderDialogActionButtonsProps {
    onConfirm: () => Promise<void>
    onCancel: () => void
}

const CancelOrderDialogActionButtons: FC<CancelOrderDialogActionButtonsProps> = ({
    onConfirm,
    onCancel,
}) => {
    const [confirmLoading, setConfirmLoading] = useState(false)

    const { t } = useTranslation()

    const handleConfirm = async () => {
        try {
            setConfirmLoading(true)

            await onConfirm()
        } finally {
            setConfirmLoading(false)
        }
    }

    return (
        <Dialog.Actions style={styles.actionButtonsContainer}>
            <Button
                disabled={confirmLoading}
                loading={confirmLoading}
                mode="contained"
                buttonColor={ColorEnum.BLUE_500}
                onPress={handleConfirm}
                labelStyle={styles.actionButtonLabelStyle}
                style={styles.actionButton}
            >
                {t('order.detail.cancelDialog.confirmAction')}
            </Button>
            <Button
                disabled={confirmLoading}
                mode="outlined"
                textColor={ColorEnum.BLUE_500}
                onPress={onCancel}
                labelStyle={styles.actionButtonLabelStyle}
                style={styles.actionButton}
            >
                {t('order.detail.cancelDialog.cancelAction')}
            </Button>
        </Dialog.Actions>
    )
}

const styles = StyleSheet.create({
    actionButtonsContainer: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 16,
    },
    actionButtonLabelStyle: {
        textTransform: 'uppercase',
    },
    actionButton: {
        borderColor: ColorEnum.BLUE_500,
        borderRadius: 4,
        borderWidth: 2,
        width: '100%',
    },
})

export default CancelOrderDialogActionButtons
