import { createContext } from 'use-context-selector'

import { IOrder } from '../../../../types/order'

interface IOrderListItemContext {
    order: IOrder
}

const OrderListItemContext = createContext<IOrderListItemContext>({
    order: {} as IOrder,
})

export default OrderListItemContext
