import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { Button } from 'react-native-paper'

import TerminalAPI from '../../../../../api/terminal/terminal'
import { ColorEnum } from '../../../../../constants/color'

interface AcceptedOrderDetailFooterActionButtonsProps {
    orderUuid: string
    postponeDisabled: boolean
    onPostponeButton: () => void
}

const AcceptedOrderDetailFooterActionButtons: FC<AcceptedOrderDetailFooterActionButtonsProps> = ({
    orderUuid,
    postponeDisabled,
    onPostponeButton,
}) => {
    const [startOrderProductionLoading, setStartOrderProduction] = useState(false)

    const { t } = useTranslation()

    const handleStartProductionButton = async () => {
        try {
            setStartOrderProduction(true)

            await TerminalAPI.startOrderProduction(orderUuid)
        } finally {
            setStartOrderProduction(false)
        }
    }

    return (
        <View style={styles.container}>
            <Button
                uppercase
                mode="outlined"
                textColor={ColorEnum.BLUE_500}
                maxFontSizeMultiplier={1}
                disabled={postponeDisabled || startOrderProductionLoading}
                onPress={onPostponeButton}
                style={[
                    styles.button,
                    styles.postponeButton,
                    postponeDisabled && styles.disabledPostponeButton,
                ]}
            >
                {t('order.detail.postpone')}
            </Button>
            <Button
                disabled={startOrderProductionLoading}
                loading={startOrderProductionLoading}
                uppercase
                mode="contained"
                buttonColor={ColorEnum.EMERALD_500}
                maxFontSizeMultiplier={1}
                onPress={handleStartProductionButton}
                style={styles.button}
            >
                {t('order.detail.toProduction')}
            </Button>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'center',
        width: 400,
        gap: 10,
    },
    button: {
        width: 168,
        borderRadius: 8,
        justifyContent: 'center',
    },
    postponeButton: {
        borderColor: ColorEnum.BLUE_500,
    },
    disabledPostponeButton: {
        borderColor: ColorEnum.GRAY_300,
    },
})

export default AcceptedOrderDetailFooterActionButtons
