import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { FC, useEffect } from 'react'

import { MainScreenEnum } from '../../constants/screen'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { licenceSelectors } from '../../redux/reducers/licence'
import { fetchLicence } from '../../redux/reducers/licence/action-creators'
import { MainStackParamList } from '../../types/navigation'
import OrdersModule from '../OrdersModule/OrdersModule'

const Stack = createNativeStackNavigator<MainStackParamList>()

const MainStack: FC = () => {
    const licence = useAppSelector(licenceSelectors.licence)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (licence) return

        dispatch(fetchLicence())
    }, [licence])

    const getInitialRouteName = () => {
        return MainScreenEnum.OrdersModule
    }

    return (
        <Stack.Navigator
            initialRouteName={getInitialRouteName()}
            screenOptions={{ animation: 'none', headerShown: false }}
        >
            <Stack.Screen name={MainScreenEnum.OrdersModule} component={OrdersModule} />
        </Stack.Navigator>
    )
}

export default MainStack
