import { TimeAdjusterShiftValue } from '../types/timeAdjuster'

export const timeAdjusterShiftValues: TimeAdjusterShiftValue[] = [
    { value: 5, timeUnit: 'minutes' },
    { value: 10, timeUnit: 'minutes' },
    { value: 1, timeUnit: 'hours' },
    { value: -5, timeUnit: 'minutes' },
    { value: -10, timeUnit: 'minutes' },
    { value: -1, timeUnit: 'hours' },
] as const
