import dayjs from 'dayjs'
import { FC } from 'react'
import { StyleSheet } from 'react-native'
import { Text } from 'react-native-paper'
import { useContextSelector } from 'use-context-selector'

import { ColorEnum } from '../../../constants/color'
import { formatDate } from '../../../utils/formatDate'
import NotificationContext from './NotificationContext'

const Date: FC = () => {
    const created = useContextSelector(
        NotificationContext,
        ({ notification }) => notification.created
    )

    const createdNum = +created

    return (
        <Text style={styles.container}>
            {formatDate(
                createdNum,
                dayjs.unix(createdNum).isSame(dayjs(), 'day') ? 'hh:mm' : 'D MMM hh:mm'
            )}
        </Text>
    )
}

const styles = StyleSheet.create({
    container: {
        textTransform: 'uppercase',
        color: ColorEnum.JET_600,
    },
})

export default Date
