import dayjs, { ManipulateType } from 'dayjs'
import { Duration } from 'dayjs/plugin/duration'

import { ICustomPostponeOrderTime } from '../types/customPostponeOrderTime'
import { IOrder } from '../types/order'
import { PostponeOrderTimeValues } from '../types/order-flow-action-time-values'

export const calculateCustomPostponeOrderTime = (
    time: ICustomPostponeOrderTime,
    value: number,
    timeUnit: ManipulateType
): ICustomPostponeOrderTime => {
    const calculatedTime = dayjs.duration(time).add(value, timeUnit)

    return {
        hours: Math.floor(calculatedTime.asHours()),
        minutes: calculatedTime.minutes(),
    }
}

export const calculatePostponedUntil = (order: IOrder, postponementTime: Duration) => {
    if (!order.acceptedDeliveryTime) {
        throw new Error('order`s accepted delivery time is undefined')
    }

    return dayjs(order.acceptedDeliveryTime.end).subtract(postponementTime)
}

export const getPostponementTimeAsDuration = (
    postponementTime: PostponeOrderTimeValues,
    customPostponementTime: ICustomPostponeOrderTime
) => {
    return dayjs.duration({
        hours: postponementTime === 'custom' ? customPostponementTime.hours : postponementTime,
        minutes: postponementTime === 'custom' ? customPostponementTime.minutes : 0,
    })
}
