import dayjs from 'dayjs'
import { FC, useCallback, useEffect, useState } from 'react'

import { minimalPostponeOrderTimeInMinutes } from '../../../../../constants/order-flow-action-time-values'
import { useAppSelector } from '../../../../../hooks/redux'
import { useIntervaledState } from '../../../../../hooks/useIntervalState'
import { ordersSelectors } from '../../../../../redux/reducers/orders/selectors'
import { calculatePostponedUntil } from '../../../../../utils/postponeOrderTime'
import AcceptedOrderDetailFooterActionButtons from './AcceptedOrderDetailFooterActionButtons'
import PostponeOrderTimeSelector from './PostponeOrderTimeSelector/PostponeOrderTimeSelector'

interface AcceptedOrderDetailFooterProps {
    orderUuid: string
}

const AcceptedOrderDetailFooter: FC<AcceptedOrderDetailFooterProps> = ({ orderUuid }) => {
    const order = useAppSelector(ordersSelectors.orderByUuid(orderUuid))

    const [isPostponeOrderMode, setIsPostponeOrderMode] = useState(false)

    const postponeDisabled = useIntervaledState(
        useCallback(() => {
            if (!order) {
                return true
            }

            const postponementTime = dayjs.duration({
                minutes: minimalPostponeOrderTimeInMinutes,
            })

            return calculatePostponedUntil(order, postponementTime).isBefore(dayjs())
        }, [order]),
        60000
    )

    useEffect(() => {
        if (!postponeDisabled) return

        turnOffPostponeOrderMode()
    }, [postponeDisabled])

    if (!order) {
        return null
    }

    const turnOffPostponeOrderMode = () => {
        setIsPostponeOrderMode(false)
    }

    const turnOnPostponeOrderMode = () => {
        setIsPostponeOrderMode(true)
    }

    return (
        <>
            {!isPostponeOrderMode ? (
                <AcceptedOrderDetailFooterActionButtons
                    orderUuid={orderUuid}
                    postponeDisabled={postponeDisabled}
                    onPostponeButton={turnOnPostponeOrderMode}
                />
            ) : (
                <PostponeOrderTimeSelector
                    orderUuid={orderUuid}
                    onBackButton={turnOffPostponeOrderMode}
                />
            )}
        </>
    )
}

export default AcceptedOrderDetailFooter
