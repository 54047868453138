import React, { FC } from 'react'
import { StyleSheet, View } from 'react-native'
import { Text } from 'react-native-paper'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'

import { ColorEnum } from '../../../../constants/color'

interface OrderDetailInfoRowProps {
    icon: string
    children: string
}

const OrderDetailInfoRow: FC<OrderDetailInfoRowProps> = ({ children, icon }) => {
    return (
        <View style={styles.container}>
            <Icon name={icon} style={styles.icon} size={16} color={ColorEnum.EMERALD_500} />
            <Text style={styles.text}>{children}</Text>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 4,
    },
    icon: {
        marginEnd: 10,
    },
    text: {
        fontFamily: 'Satoshi-Regular',
        fontSize: 14,
    },
})

export default OrderDetailInfoRow
