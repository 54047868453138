import { FC } from 'react'
import { StyleSheet, View } from 'react-native'
import { ActivityIndicator } from 'react-native-paper'

import { ColorEnum } from '../../constants/color'
import { isDetox } from '../../constants/environment'
import { hexToRgba } from '../../utils/hexToRgba'

const Overlay: FC = () => {
    return (
        <View style={styles.container} testID="overlay">
            <ActivityIndicator animating={!isDetox} size="large" color={ColorEnum.JET_500} />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        justifyContent: 'center',
        backgroundColor: hexToRgba(ColorEnum.JET_800, 0.88),
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
    },
})

export default Overlay
