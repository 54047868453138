import React, { FC } from 'react'
import { StyleSheet, View } from 'react-native'
import { Text } from 'react-native-paper'

import { ColorEnum } from '../../../../constants/color'
import { ItemsPriceCalculator } from '../../../../services/calculators/itemsPriceCalculator'
import { IPrice } from '../../../../types/order'
import { formatPrice } from '../../../../utils/priceHelper'

interface ItemNameQuantityPriceProps {
    name: string
    quantity: number
    price: IPrice
}

const ItemNameQuantityPrice: FC<ItemNameQuantityPriceProps> = ({ name, quantity, price }) => {
    const totalPrice = ItemsPriceCalculator.calculateItemPriceWithoutExtras(price, quantity)

    return (
        <View style={styles.container}>
            <Text style={[styles.name]}>{name}</Text>
            <Text style={styles.quantity}>{quantity}x</Text>
            <Text style={[styles.price]}>
                {formatPrice(totalPrice.amount, totalPrice.currency)}
            </Text>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
    },
    name: {
        flex: 1,
        fontFamily: 'Satoshi-Regular',
    },
    quantity: {
        fontFamily: 'Satoshi-Regular',
        fontSize: 14,
        color: ColorEnum.GRAY_900,
        width: '10%',
        textAlign: 'center',
    },
    price: {
        fontFamily: 'Satoshi-Regular',
        fontSize: 14,
        width: '20%',
        textAlign: 'right',
    },
})

export default ItemNameQuantityPrice
