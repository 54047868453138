import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { Text } from 'react-native-paper'

import { ColorEnum } from '../../../../constants/color'
import { ItemsPriceCalculator } from '../../../../services/calculators/itemsPriceCalculator'
import { IOrderItem, IPrice } from '../../../../types/order'
import DeliveryPrice from './DeliveryPrice'
import ItemsHeader from './ItemsHeader'
import ItemsPrice from './ItemsPrice'
import ItemsSeparator from './ItemsSeparator'
import OrderDetailItem from './OrderDetailItem'
import TotalPrice from './TotalPrice'

interface OrderDetailItemListProps {
    items: IOrderItem[]
    deliveryPrice?: IPrice
    totalPrice: IPrice
    currency: string
}

const OrderDetailItemList: FC<OrderDetailItemListProps> = ({
    items,
    deliveryPrice,
    totalPrice,
    currency,
}) => {
    const { t } = useTranslation()

    return (
        <View style={styles.container}>
            <Text style={styles.itemsSectionTitle}>{t('order.detail.items')}</Text>

            <ItemsHeader />

            {items.map((item, index) => (
                <OrderDetailItem
                    key={item.uuid}
                    name={item.name}
                    quantity={item.quantity}
                    price={item.unitPrice}
                    toppings={item.toppings}
                    removedIngredients={item.removedIngredients}
                    note={item.note}
                    style={{
                        borderTopWidth: index % 2 != 0 ? 1 : 0,
                        borderTopColor: index % 2 != 0 ? ColorEnum.GRAY_200 : 'transparent',
                        borderBottomWidth: index % 2 != 0 ? 1 : 0,
                        borderBottomColor: index % 2 != 0 ? ColorEnum.GRAY_200 : 'transparent',
                        backgroundColor: index % 2 != 0 ? ColorEnum.GRAY_100 : 'transparent',
                    }}
                />
            ))}

            <ItemsSeparator style={styles.itemsSeparator} />

            <ItemsPrice price={ItemsPriceCalculator.calculateTotalPrice(items, currency)} />
            {deliveryPrice && <DeliveryPrice price={deliveryPrice} />}
            <TotalPrice price={totalPrice} />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        padding: 16,
    },
    itemsSectionTitle: {
        fontFamily: 'Satoshi-Regular',
        fontSize: 16,
        marginBottom: 10,
    },
    itemsSeparator: {
        marginBottom: 8,
    },
})

export default OrderDetailItemList
