import React, { FC } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { Text } from 'react-native-paper'

import { ColorEnum } from '../../../constants/color'
import { useAppSelector } from '../../../hooks/redux'
import { ordersSelectors } from '../../../redux/reducers/orders/selectors'

interface OrderListSectionHeaderProps {
    title: string
    itemsCount: number
    style?: StyleProp<ViewStyle>
}

const OrderListSectionHeader: FC<OrderListSectionHeaderProps> = ({ title, itemsCount, style }) => {
    const isOrdersLoaded = useAppSelector(ordersSelectors.isOrdersLoaded)

    return (
        <View style={[styles.container, style]}>
            <Text style={styles.title}>{title}</Text>
            {isOrdersLoaded && <Text style={styles.count}>{itemsCount}</Text>}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        paddingHorizontal: 16,
    },
    title: {
        textTransform: 'uppercase',
        color: ColorEnum.GRAY_600,
        fontSize: 14,
        fontWeight: '700',
    },
    count: {
        color: ColorEnum.EMERALD_700,
        fontSize: 14,
        fontWeight: '700',
        marginStart: 10,
    },
})

export default OrderListSectionHeader
