import React, { FC } from 'react'
import { StyleSheet } from 'react-native'

import { ColorEnum } from '../../constants/color'
import { INotification } from '../../types/notification'
import Notification from '../UI/Notification/Notification'

interface ListNotificationProps {
    notification: INotification
}

const NotificationCenterItem: FC<ListNotificationProps> = ({ notification }) => {
    return (
        <Notification
            key={notification.id}
            notification={notification}
            Date={<Notification.Date />}
            style={styles.notification}
        />
    )
}

const styles = StyleSheet.create({
    notification: {
        borderBottomColor: ColorEnum.GRAY_200,
        borderBottomWidth: 1,
        paddingBottom: 10,
        marginBottom: 10,
    },
})

export default NotificationCenterItem
