import AxiosHttpFactory from '../axiosHttpFactory'
import { IIgnoreErrorResponseLogAxiosRequestConfig } from '../types'
import { AuthAPIErrorCodeEnum } from './constants'
import { IAuthAPI } from './types'

class AuthAPI implements IAuthAPI {
    private $authAPI = AxiosHttpFactory.createMultiClient()

    setHostUrl(url: string) {
        this.$authAPI.defaults.baseURL = `${url}/api/terminal-auth`
    }

    async requestDeviceAuthorization() {
        const { data } = await this.$authAPI.post<{ deviceId: string }>('/request-authorization')

        return data.deviceId
    }

    async authorizeDevice(deviceId: string, authorizationCode: string) {
        const formData = new FormData()
        formData.append('deviceId', deviceId)
        formData.append('authorizationCode', authorizationCode)

        const { data } = await this.$authAPI.post<{ accessToken: string }>('/authorize', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            ignoreErrorLogging: (response) =>
                response.data?.error.code === AuthAPIErrorCodeEnum.NotGranted,
        } as IIgnoreErrorResponseLogAxiosRequestConfig)

        return data.accessToken
    }
}

export default new AuthAPI()
