import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet } from 'react-native'

import { ColorEnum } from '../../../constants/color'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { fetchAuthProfiles, setAuthProfile } from '../../../redux/reducers/auth/action-creators'
import { authSelectors } from '../../../redux/reducers/auth/selectors'
import { IProfile } from '../../../types/profile'
import { horizontalScale, moderateScale, verticalScale } from '../../../utils/metrics'
import IconCard from '../../UI/IconCard/IconCard'
import InfoMessage from '../../UI/InfoMessage'

const AuthProfilesList: FC = () => {
    const profiles = useAppSelector(authSelectors.profiles)

    const dispatch = useAppDispatch()

    const { t } = useTranslation()

    useEffect(() => {
        if (profiles) return

        dispatch(fetchAuthProfiles())
    }, [profiles])

    const handleProfilePress = (profile: IProfile) => {
        dispatch(setAuthProfile(profile))
    }

    return (
        <ScrollView style={{ marginTop: verticalScale(4) }} testID="authProfilesList">
            {!!profiles &&
                (profiles.length > 0 ? (
                    profiles.map((profile, index) => (
                        <IconCard
                            key={profile.uuid}
                            icon="account"
                            title={profile.name}
                            subtitle={profile.description ?? ''}
                            onPress={() => handleProfilePress(profile)}
                            style={{ marginBottom: verticalScale(10) }}
                            iconCardID={`profileSelectionScreen-profile-${index}`}
                        />
                    ))
                ) : (
                    <InfoMessage
                        textStyle={styles.emptyListMessageText}
                        style={{ marginTop: horizontalScale(20) }}
                    >
                        {t('profileSelectionScreenEmptyListMessage')}
                    </InfoMessage>
                ))}
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    emptyListMessageText: {
        color: ColorEnum.WHITE,
        fontSize: moderateScale(12, undefined, 0.2),
    },
})

export default AuthProfilesList
