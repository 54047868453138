import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import Config from 'react-native-config'

import { applyUrlHostRewrite } from '../utils/http'
import { logError } from '../utils/logError'
import { formatAxiosErrorLog } from './formatAxiosErrorLog'
import { IIgnoreErrorResponseLogAxiosRequestConfig } from './types'

class AxiosHttpFactory {
    private addResponseInterceptor = (instance: AxiosInstance) => {
        instance.interceptors.response.use(
            (response: AxiosResponse) => response,
            (error: AxiosError) => {
                if (error.response) {
                    const config = error.config as IIgnoreErrorResponseLogAxiosRequestConfig

                    if (config.ignoreErrorLogging && config.ignoreErrorLogging(error.response)) {
                        return Promise.reject(error)
                    }
                }

                const log = formatAxiosErrorLog(error)

                logError(log)

                return Promise.reject(error)
            }
        )
    }
    createInstance = (config?: IIgnoreErrorResponseLogAxiosRequestConfig) => {
        const axiosInstance = axios.create({
            ...config,
        } as IIgnoreErrorResponseLogAxiosRequestConfig)

        this.addResponseInterceptor(axiosInstance)

        return axiosInstance
    }
    createMultiClient = (config?: IIgnoreErrorResponseLogAxiosRequestConfig) => {
        const axiosInstance = this.createInstance(config)

        if (!Config.LOCAL_SERVER_MULTI_URL) {
            return axiosInstance
        }

        const localServerURL = Config.LOCAL_SERVER_MULTI_URL
        axiosInstance.interceptors.request.use((config) =>
            applyUrlHostRewrite(config, localServerURL, 'X-Adaptee-Licence-Host')
        )

        return axiosInstance
    }
}

export default new AxiosHttpFactory()
