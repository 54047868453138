import dayjs from 'dayjs'

import i18n from '../translations'

export const formatHumanReadableDurationFromMinutes = (minutesDuration: number) => {
    const duration = dayjs.duration(minutesDuration, 'minutes')

    const days = duration.days()
    const hours = duration.hours()
    const minutes = Math.abs(duration.minutes())

    if (Math.abs(days) > 0) {
        return `${days} ${i18n.t('timeUnit.day', { postProcess: 'interval', count: Math.abs(days) })}`
    } else if (Math.abs(hours) >= 2) {
        const hoursStr = `${hours} ${minutes > 0 ? i18n.t('timeUnit.shortHour') : i18n.t('timeUnit.hour')}`
        const minutesStr = `${minutes > 0 ? ` ${minutes} ${i18n.t('timeUnit.shortMin')}` : ''}`

        return `${hoursStr}${minutesStr}`
    }

    return `${minutesDuration} ${i18n.t('timeUnit.min')}`
}

export const formatHourMinuteDurationFromMinutes = (minutesDuration: number) => {
    const duration = dayjs.duration(minutesDuration, 'minutes')

    const minutes = duration.minutes()

    return `${Math.floor(duration.asHours())}:${minutes < 10 ? '0' : ''}${minutes}`
}
