export enum DeliveryTimeTypeEnum {
    Exact = 'exact',
    Asap = 'asap',
    Window = 'window',
}

export enum DeliveryTypeEnum {
    EatIn = 'eat_in',
    FoodoraGo = 'foodora_go',
    SelfDelivery = 'self_delivery',
    Takeaway = 'takeaway',
    WoltDrive = 'wolt_drive',
}

export enum DeliveryTypeCategoryEnum {
    Delivery = 'delivery',
    Takeaway = 'takeaway',
    EatIn = 'eat_in',
}

export enum OrderChannelEnum {
    Menuboard = 'menuboard',
    Web = 'web',
    QR = 'qr',
    Terminal = 'terminal',
    Kiosk = 'kiosk',
    Foodora = 'foodora',
    Wolt = 'wolt',
}

export enum OrderPhaseEnum {
    New = 'new',
    InProgress = 'in-progress',
    Future = 'future',
    Archived = 'archived',
}

export enum OrderStateEnum {
    AwaitingPayment = 'awaiting-payment',
    AwaitingAcceptance = 'awaiting-acceptance',
    Accepted = 'accepted',
    Production = 'production',
    Ready = 'ready',
    Delivering = 'delivering',
    Delivered = 'delivered',
    Cancelled = 'cancelled',
}

export enum PaymentTypeEnum {
    CardOnsite = 'card-onsite',
    Cash = 'cash',
    Invoice = 'invoice',
    MealVoucher = 'meal-voucher',
    MealVoucherCard = 'meal-voucher-card',
    Online = 'online',
}

export enum PaymentMethodEnum {
    ApplePay = 'apple-pay',
    Card = 'card',
    GooglePay = 'google-pay',
}

export enum SegmentEnum {
    New = 'novy',
    Loyal = 'loajalni',
    Occasional = 'obcasny',
    Lost = 'ztracen',
}
