import { forwardRef } from 'react'
import { LayoutChangeEvent, StyleSheet, View } from 'react-native'
import { IconButton, Text, TouchableRipple } from 'react-native-paper'

import { ColorEnum } from '../../../constants/color'
import { moderateScale } from '../../../utils/metrics'

interface NotificationButtonProps {
    visible: boolean
    count: number
    onPress: () => void
    onLayout: (event: LayoutChangeEvent) => void
}

const NotificationButton = forwardRef<View, NotificationButtonProps>(
    ({ visible, count, onPress, onLayout }, ref) => {
        const outerContainerStyle = {
            backgroundColor: visible ? ColorEnum.YELLOW_500 : 'transparent',
        }
        const badgeTextStyle = { color: visible ? ColorEnum.WHITE : ColorEnum.BRICK_700 }

        const contentColor = visible ? ColorEnum.BRICK_700 : ColorEnum.WHITE

        return (
            <TouchableRipple onPress={onPress} onLayout={onLayout} style={styles.outerContainer}>
                <View style={[styles.innerContainer, outerContainerStyle]} ref={ref}>
                    <IconButton
                        icon="bell-outline"
                        iconColor={contentColor}
                        size={moderateScale(21, 26, 0.2)}
                        style={styles.icon}
                    />
                    <View style={[styles.badge, { backgroundColor: contentColor }]}>
                        <Text style={[styles.badgeText, badgeTextStyle]}>{count}</Text>
                    </View>
                </View>
            </TouchableRipple>
        )
    }
)

const styles = StyleSheet.create({
    outerContainer: {
        zIndex: 1,
    },
    innerContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 4,
    },
    icon: {
        margin: 0,
    },
    badge: {
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 32,
        height: 16,
        width: 16,
        marginStart: -8,
        marginEnd: 8,
    },
    badgeText: {
        fontSize: 10,
    },
})

NotificationButton.displayName = 'NotificationButton'

export default NotificationButton
