import dayjs from 'dayjs'

import { DeliveryTimeTypeEnum } from '../constants/order'
import { IDeliveryTime } from '../types/order'
import {
    formatHourMinuteDurationFromMinutes,
    formatHumanReadableDurationFromMinutes,
} from './format-duration'

export const getCountdown = (deliveryTime: IDeliveryTime) => {
    const isDelayed = (diffMinutes: number) => diffMinutes < 0

    const now = dayjs().second(0)
    const endDiff = dayjs(deliveryTime.end).second(0).diff(dayjs().second(0), 'minute')

    if (
        deliveryTime.type === DeliveryTimeTypeEnum.Asap ||
        deliveryTime.type === DeliveryTimeTypeEnum.Exact
    ) {
        return [formatHumanReadableDurationFromMinutes(endDiff), isDelayed(endDiff)]
    } else if (deliveryTime.type === DeliveryTimeTypeEnum.Window) {
        const startDiff = dayjs(deliveryTime.start).second(0).diff(now, 'minute')

        let countdown = ''

        if (startDiff >= 0 && endDiff < 1440) {
            countdown = `${formatHourMinuteDurationFromMinutes(startDiff)} - ${formatHourMinuteDurationFromMinutes(endDiff)}`
        } else if (startDiff < 0 && endDiff >= 0 && endDiff < 1440) {
            countdown = `0:00 - ${formatHourMinuteDurationFromMinutes(endDiff)}`
        } else {
            countdown = formatHumanReadableDurationFromMinutes(endDiff)
        }

        return [countdown, isDelayed(endDiff)]
    }

    throw new Error('Unsupported delivery time type')
}
