import { FC } from 'react'

import { authDeviceAuthorizationCodeFieldLength } from '../../../constants/codeEntryField'
import { useAppSelector } from '../../../hooks/redux'
import { authSelectors } from '../../../redux/reducers/auth/selectors'
import { verticalScale } from '../../../utils/metrics'
import CodeEntryField from '../../UI/CodeEntryField/CodeEntryField'

const AuthDeviceAuthorizationCodeField: FC = () => {
    const deviceCode = useAppSelector(authSelectors.deviceCode)

    return (
        <CodeEntryField
            length={authDeviceAuthorizationCodeFieldLength}
            style={{ marginTop: verticalScale(22) }}
            codeEntryFieldID="deviceAuthorizationScreen-deviceCode"
        >
            {deviceCode}
        </CodeEntryField>
    )
}

export default AuthDeviceAuthorizationCodeField
