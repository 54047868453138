import React, { FC } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { Avatar, IconButton, Text } from 'react-native-paper'

import { ColorEnum } from '../../constants/color'
import { useAppDispatch } from '../../hooks/redux'
import { closeGridMenu } from '../../redux/reducers/app'
import { logoutAuthUser } from '../../redux/reducers/auth/action-creators'
import { moderateScale } from '../../utils/metrics'

interface UserAccountCardProps {
    name: string
    role: string
    style?: StyleProp<ViewStyle>
}

const UserAccountCard: FC<UserAccountCardProps> = ({ name, role, style }) => {
    const dispatch = useAppDispatch()

    const handleUserLogout = () => {
        dispatch(logoutAuthUser())
        dispatch(closeGridMenu())
    }

    return (
        <View style={[style, styles.container]}>
            <Avatar.Icon
                icon="account"
                color={ColorEnum.WHITE}
                size={moderateScale(32, 48, 0.15)}
                style={styles.avatar}
            />
            <View>
                <Text style={[styles.title, { fontSize: moderateScale(15, 18, 0.1) }]}>{name}</Text>
                <Text style={[styles.subtitle, { fontSize: moderateScale(12, 14, 0.02) }]}>
                    {role}
                </Text>
            </View>

            <IconButton
                onPress={handleUserLogout}
                icon="power"
                iconColor={ColorEnum.BRICK_600}
                size={moderateScale(24, 36, 0.15)}
                style={styles.powerIcon}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
        maxWidth: 288,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: ColorEnum.VIOLET_100,
        borderRadius: 36,
        paddingHorizontal: 8,
    },
    avatar: {
        backgroundColor: ColorEnum.JET_500,
        marginEnd: 8,
    },
    title: {
        color: ColorEnum.GRAY_900,
        fontFamily: 'Satoshi-Regular',
    },
    subtitle: {
        color: ColorEnum.GRAY_600,
        fontFamily: 'Satoshi-Regular',
    },
    powerIcon: {
        marginStart: 'auto',
    },
})

export default UserAccountCard
