import { Platform } from 'react-native'
import Config from 'react-native-config'

export const isAndroid = Platform.OS === 'android'

export const isIOS = Platform.OS === 'ios'

export const isWeb = Platform.OS === 'web'

export const isDetox = Config.ENV === 'detox'
