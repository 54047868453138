export enum AsyncStorageKeyEnum {
    ApiHostUrl = 'apiHostUrl',
    DeviceId = 'deviceId',
    DeviceToken = 'deviceToken',
    Profiles = 'profiles',
    Profile = 'profile',
    OAuthTokens = 'oauthTokens',
}

export enum SecureStorageKeyEnum {
    DeviceToken = 'deviceToken',
    OAuthTokens = 'oauthTokens',
}
