import { StyleProp, StyleSheet, ViewStyle } from 'react-native'
import { RadioButton, Text, TouchableRipple } from 'react-native-paper'

import { ColorEnum } from '../../../constants/color'

interface CircleRadioButtonProps<T extends string> {
    children: string
    checked: boolean
    value: T
    onPress: (value: T) => void
    style?: StyleProp<ViewStyle>
}

const CircleRadioButton = <T extends string>({
    children,
    checked,
    value,
    onPress,
    style,
}: CircleRadioButtonProps<T>) => {
    return (
        <TouchableRipple onPress={() => onPress(value)} style={[styles.container, style]}>
            <>
                <RadioButton.Android
                    color={ColorEnum.BRICK_600}
                    uncheckedColor={ColorEnum.JET_500}
                    status={checked ? 'checked' : 'unchecked'}
                    value={value}
                    onPress={() => onPress(value)}
                />
                <Text style={styles.label}>{children}</Text>
            </>
        </TouchableRipple>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        borderColor: ColorEnum.GRAY_300,
        borderRadius: 4,
        borderWidth: 1,
        paddingVertical: 2,
        paddingRight: 8,
    },
    label: {
        color: ColorEnum.JET_600,
    },
})

export default CircleRadioButton
