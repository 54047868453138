import { useEffect } from 'react'
import { Dimensions, ScaledSize } from 'react-native'

import { setScreenInfo } from '../redux/reducers/screenInfo'
import { useAppDispatch } from './redux'

export const useScreenInfo = () => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        const onChange = (result: { window: ScaledSize }) => {
            dispatch(setScreenInfo(result.window))
        }

        const onChangeListener = Dimensions.addEventListener('change', onChange)

        return () => onChangeListener.remove()
    }, [])
}
