import React, { FC } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import { IDeliveryTime } from '../../../../types/order'
import { formatDeliveryTime } from '../../../../utils/formatDeliveryTime'
import OrderDetailInfoRow from './OrderDetailInfoRow'

interface OrderInfoProps {
    deliveryTime?: IDeliveryTime
    phoneNumber?: string
    address?: string
    paymentType?: string
    customerNote?: string
    style?: StyleProp<ViewStyle>
}

const OrderInfo: FC<OrderInfoProps> = ({
    deliveryTime,
    phoneNumber,
    address,
    paymentType,
    customerNote,
    style,
}) => {
    return (
        <View style={style}>
            {deliveryTime && (
                <OrderDetailInfoRow icon="clock">
                    {formatDeliveryTime(deliveryTime)}
                </OrderDetailInfoRow>
            )}
            {phoneNumber && <OrderDetailInfoRow icon="cellphone">{phoneNumber}</OrderDetailInfoRow>}
            {address && <OrderDetailInfoRow icon="map-marker">{address}</OrderDetailInfoRow>}
            {paymentType && <OrderDetailInfoRow icon="cash">{paymentType}</OrderDetailInfoRow>}

            {customerNote && (
                <OrderDetailInfoRow icon="note-text-outline">{customerNote}</OrderDetailInfoRow>
            )}
        </View>
    )
}

export default OrderInfo
