import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'

import { INotification } from '../../../types/notification'
import { RootState } from '../../store'

interface NotificationsState {
    isCenterOpen: boolean
    notifications: INotification[] | null
    recentNotificationQueue: INotification[]
}

const initialState: NotificationsState = {
    isCenterOpen: false,
    notifications: null,
    recentNotificationQueue: [],
}

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        toggleNotificationCenter: (state) => void (state.isCenterOpen = !state.isCenterOpen),
        setNotifications: (state, { payload: data }: PayloadAction<INotification[]>) =>
            void (state.notifications = data),
        addRecentNotification: (state, { payload: notification }: PayloadAction<INotification>) =>
            void state.recentNotificationQueue.push(notification),
        removeRecentNotification: (state) => void state.recentNotificationQueue.shift(),
    },
})

export const {
    setNotifications,
    toggleNotificationCenter,
    addRecentNotification,
    removeRecentNotification,
} = notificationsSlice.actions

const sliceSelectors = {
    isCenterOpen: ({ notifications }: RootState) => notifications.isCenterOpen,
    notifications: ({ notifications }: RootState) => notifications.notifications,
    recentNotification: ({ notifications }: RootState) => notifications.recentNotificationQueue[0],
}

const count = createSelector([sliceSelectors.notifications], (data) => {
    return data !== null ? data.length : 0
})

export const notificationSelectors = {
    ...sliceSelectors,
    count,
}

export default notificationsSlice.reducer
