import dayjs from 'dayjs'
import i18n from 'i18next'

export const formatDate = (timestamp: number, format: string) => {
    const dateDayjs = dayjs.unix(timestamp)

    return dateDayjs.format(format)
}

export const isoDateToAgoHumanFormat = (date: string) => {
    const dateDayjs = dayjs(date)
    const today = dayjs()

    const diff = today.diff(dateDayjs, 'day')

    if (diff < 0) {
        throw new Error('Future date is not allowed')
    }

    if (dateDayjs.isToday()) {
        return i18n.t('date.today')
    } else if (dateDayjs.isYesterday()) {
        return i18n.t('date.yesterday')
    } else if (diff < 30) {
        return i18n.t('date.daysAgo', { days: diff })
    } else if (diff < 60) {
        return i18n.t('date.monthAgo')
    } else if (diff < 365) {
        return i18n.t('date.monthsAgo', { months: Math.floor(diff / 30) })
    } else {
        return i18n.t('date.moreThanAYearAgo')
    }
}
