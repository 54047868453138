import { FC } from 'react'
import { StyleSheet } from 'react-native'
import { Text, TouchableRipple } from 'react-native-paper'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'

import { ColorEnum } from '../../constants/color'
import { hexToRgba } from '../../utils/hexToRgba'
import { moderateScale } from '../../utils/metrics'

interface MenuItemProps {
    icon: string
    name: string
    onPress?: () => void
}

const SideBarMenuItem: FC<MenuItemProps> = ({ icon, name, onPress }) => {
    return (
        <TouchableRipple
            borderless
            centered
            rippleColor={hexToRgba(ColorEnum.JET_800)}
            style={styles.container}
            onPress={onPress}
        >
            <>
                <Icon name={icon} color={ColorEnum.WHITE} size={moderateScale(23, 36, 0.15)} />
                <Text style={[styles.text, { fontSize: moderateScale(8, 13, 0.2) }]}>{name}</Text>
            </>
        </TouchableRipple>
    )
}

const styles = StyleSheet.create({
    container: {
        paddingVertical: 20,
        alignItems: 'center',
    },
    text: {
        marginTop: 8,
        textTransform: 'uppercase',
        color: ColorEnum.WHITE,
    },
})

export default SideBarMenuItem
