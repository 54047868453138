import { StackActions, useFocusEffect, useNavigation } from '@react-navigation/native'
import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Dimensions, StyleSheet, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { ColorEnum } from '../../../constants/color'
import { deviceAuthorizationScreenShowLogoMinWidth } from '../../../constants/layout'
import { AuthScreenEnum } from '../../../constants/screen'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { clearAuthProfile } from '../../../redux/reducers/auth'
import { authSelectors } from '../../../redux/reducers/auth/selectors'
import { selectScreenInfo } from '../../../redux/reducers/screenInfo'
import { verticalScale } from '../../../utils/metrics'
import AuthHeader from '../../Auth/AuthHeader'
import AuthProfilePinCodeField from '../../Auth/ProfilePin/AuthProfilePinCodeField'
import AuthProfilePinNumPad from '../../Auth/ProfilePin/AuthProfilePinNumPad'
import AuthSelectedProfileCard from '../../Auth/ProfilePin/AuthSelectedProfileCard'
import TextLink from '../../UI/TextLink'

const ProfilePinScreen: FC = () => {
    const profile = useAppSelector(authSelectors.profile)
    const deviceToken = useAppSelector(authSelectors.deviceToken)
    useAppSelector(selectScreenInfo)

    const dispatch = useAppDispatch()

    const { top } = useSafeAreaInsets()

    const { t } = useTranslation()

    const navigation = useNavigation()

    useFocusEffect(
        useCallback(() => {
            if (profile || !deviceToken) return

            navigation.dispatch(StackActions.replace(AuthScreenEnum.ProfileSelection))
        }, [profile, deviceToken])
    )

    useFocusEffect(
        useCallback(() => {
            if (deviceToken) return

            navigation.dispatch(StackActions.replace(AuthScreenEnum.Licence))
        }, [deviceToken])
    )

    const handleSelectAnotherAccountButtonPress = () => {
        dispatch(clearAuthProfile())
    }

    return (
        <View style={[styles.container, { paddingTop: top }]} testID="profilePinScreen">
            <AuthHeader
                title={t('profileScreensLogin')}
                hint={t('profilePinScreenEnterPin')}
                showLogo={
                    Dimensions.get('screen').height > deviceAuthorizationScreenShowLogoMinWidth
                }
            />
            <AuthSelectedProfileCard />
            <TextLink
                onPress={handleSelectAnotherAccountButtonPress}
                style={{ marginTop: verticalScale(8) }}
            >
                {t('profilePinScreenSelectAnotherAccount')}
            </TextLink>
            <AuthProfilePinCodeField />
            <AuthProfilePinNumPad />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        backgroundColor: ColorEnum.JET_800,
    },
})

export default ProfilePinScreen
