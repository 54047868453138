import { FC } from 'react'

import { useAppSelector } from '../../hooks/redux'
import { appSelectors } from '../../redux/reducers/app'
import Overlay from '../UI/Overlay'

const AppOverlay: FC = () => {
    const visible = useAppSelector(appSelectors.overlay)

    if (!visible) {
        return null
    }

    return <Overlay />
}

export default AppOverlay
