import { ManipulateType } from 'dayjs'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleProp, StyleSheet, Text, TextStyle, View } from 'react-native'

import { ColorEnum } from '../../constants/color'
import { timeAdjusterShiftValues } from '../../constants/timeAdjuster'
import { TimeAdjusterShiftValue } from '../../types/timeAdjuster'
import Chip from './Chip'
import CancelWithConfirmButtons from './buttons/CancelWithConfirmButtons'

interface TimeAdjusterProps {
    title: string
    time: string
    applyButtonDisabled: boolean
    onTimeShiftPress: (value: number, timeUnit: ManipulateType) => void
    onCancelButton: () => void
    onConfirmButton: () => void
    checkDisabled: (value: TimeAdjusterShiftValue) => boolean
    titleStyle?: StyleProp<TextStyle>
}

const TimeAdjuster: FC<TimeAdjusterProps> = ({
    title,
    time,
    applyButtonDisabled,
    onTimeShiftPress,
    onCancelButton,
    onConfirmButton,
    checkDisabled,
    titleStyle,
}) => {
    const { t } = useTranslation()

    const getTimeUnitTranslation = (timeUnit: string) => {
        switch (timeUnit) {
            case 'hours' || 'hour': {
                return t('timeUnit.hour')
            }
            case 'minutes' || 'minute': {
                return t('timeUnit.min')
            }
        }
    }

    return (
        <>
            <Text style={[styles.title, titleStyle]}>{title}</Text>
            <View style={styles.container}>
                <Text style={styles.timeText}>{time}</Text>
                {timeAdjusterShiftValues.map(({ value, timeUnit }, index) => {
                    const disabled = checkDisabled({ value, timeUnit })
                    return (
                        <Chip
                            key={index}
                            text={`${value > 0 ? '+' : ''}${value} ${getTimeUnitTranslation(timeUnit)}`}
                            disabled={disabled}
                            onPress={() => onTimeShiftPress(value, timeUnit)}
                            textStyle={{
                                color: disabled
                                    ? ColorEnum.GRAY_500
                                    : value < 0
                                      ? ColorEnum.RED_500
                                      : undefined,
                            }}
                            style={{ width: 110 }}
                        />
                    )
                })}
            </View>
            <CancelWithConfirmButtons
                onCancel={onCancelButton}
                onConfirm={onConfirmButton}
                applyButtonDisabled={applyButtonDisabled}
                style={{ width: 345 }}
            />
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flexWrap: 'wrap',
        width: 400,
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: 12,
    },
    title: {
        fontSize: 16,
        marginBottom: 0,
        textTransform: 'uppercase',
    },
    timeText: {
        fontSize: 22,
        marginBottom: 12,
        textAlign: 'center',
        width: '100%',
    },
})

export default TimeAdjuster
