class NumPad {
    id

    constructor(id: string) {
        this.id = id
    }

    get submitButton() {
        return element(by.id(`${this.id}-numPad-submitBtn`))
    }

    getNumberButton(number: string) {
        return element(by.id(`${this.id}-numPad-${number}Btn`))
    }

    async tapOnNumberButton(number: string) {
        return this.getNumberButton(number).tap()
    }

    async tapOnSubmitButton() {
        return this.submitButton.tap()
    }

    async typeInNumber(number: string) {
        for (let i = 0; i < number.length; i++) {
            await this.tapOnNumberButton(number[i])
        }
    }
}

export default NumPad
