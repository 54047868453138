import dayjs, { ManipulateType } from 'dayjs'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { IDeliveryTime } from '../../../../../types/order'
import { shiftDeliveryTime } from '../../../../../utils/customDeliveryTime'
import { formatDeliveryTime } from '../../../../../utils/formatDeliveryTime'
import TimeAdjuster from '../../../../UI/TimeAdjuster'

interface CustomAcceptedDeliveryTimeAdjusterProps {
    requestedDeliveryTime: IDeliveryTime
    customDeliveryTime: IDeliveryTime | undefined
    onTimeShiftPress: (deliveryTime: IDeliveryTime) => void
    onCancelButton: () => void
    onConfirmButton: () => void
}

const CustomAcceptedDeliveryTimeAdjuster: FC<CustomAcceptedDeliveryTimeAdjusterProps> = ({
    requestedDeliveryTime,
    customDeliveryTime,
    onTimeShiftPress,
    onCancelButton,
    onConfirmButton,
}) => {
    const deliveryTime = customDeliveryTime ?? requestedDeliveryTime

    const { t } = useTranslation()

    const handleCustomTimeShiftPress = (value: number, timeUnit: ManipulateType) => {
        const newCustomDeliveryTime = shiftDeliveryTime(deliveryTime, value, timeUnit)

        onTimeShiftPress(newCustomDeliveryTime)
    }

    const formattedTime = formatDeliveryTime(deliveryTime)

    const applyButtonDisabled =
        deliveryTime.start === requestedDeliveryTime.start &&
        deliveryTime.end === requestedDeliveryTime.end

    return (
        <TimeAdjuster
            title={t('order.detail.acceptationCustomTimeTitle')}
            time={formattedTime}
            applyButtonDisabled={applyButtonDisabled}
            onTimeShiftPress={handleCustomTimeShiftPress}
            onCancelButton={onCancelButton}
            onConfirmButton={onConfirmButton}
            checkDisabled={(option) => {
                if (option.value > 0) {
                    return false
                }

                const newDeliveryTime = shiftDeliveryTime(
                    deliveryTime,
                    option.value,
                    option.timeUnit
                )

                return dayjs(newDeliveryTime.start).isBefore(requestedDeliveryTime.start)
            }}
        />
    )
}

export default CustomAcceptedDeliveryTimeAdjuster
