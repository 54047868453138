import { FC } from 'react'

import { useAppSelector } from '../../hooks/redux'
import { notificationSelectors } from '../../redux/reducers/notifications'
import TopBarLogo from './TopBarLogo'
import TopBarNotification from './TopBarNotification'

const TopBarLogoOrNotification: FC = () => {
    const recentNotification = useAppSelector(notificationSelectors.recentNotification)

    if (recentNotification) {
        return <TopBarNotification key={recentNotification.id} notification={recentNotification} />
    }

    return <TopBarLogo />
}

export default TopBarLogoOrNotification
