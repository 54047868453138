import { FC } from 'react'

import { useAppSelector } from '../../../hooks/redux'
import { authSelectors } from '../../../redux/reducers/auth/selectors'
import { verticalScale } from '../../../utils/metrics'
import IconCard from '../../UI/IconCard/IconCard'

const AuthBranchCard: FC = () => {
    const licenceName = useAppSelector(authSelectors.licenceName)
    const licenceId = useAppSelector(authSelectors.licenceId)

    return (
        <IconCard
            icon="store"
            title={licenceName ?? ''}
            subtitle={licenceId ?? ''}
            style={{ marginTop: verticalScale(15) }}
            iconCardID="deviceAuthorizationScreen-branch"
        />
    )
}

export default AuthBranchCard
