import { FC } from 'react'
import { StyleSheet } from 'react-native'
import { Text, TouchableRipple } from 'react-native-paper'

import { ColorEnum } from '../../../constants/color'
import { hexToRgba } from '../../../utils/hexToRgba'
import { moderateScale, verticalScale } from '../../../utils/metrics'

interface CircularButtonProps {
    number: string
    onPress: () => void
    numPadID?: string
}

const NumPadCircularButton: FC<CircularButtonProps> = ({ number, onPress, numPadID }) => {
    const size = verticalScale(96, 70)
    const borderRadius = size / 2

    return (
        <TouchableRipple
            borderless
            rippleColor={hexToRgba(ColorEnum.JET_800)}
            onPress={onPress}
            style={[styles.container, { borderRadius, height: size, width: size }]}
            accessibilityRole="button"
        >
            <Text
                style={[styles.text, { fontSize: moderateScale(21, 25) }]}
                testID={
                    numPadID ? `${numPadID ? `${numPadID}-` : ''}numPad-${number}Btn` : undefined
                }
            >
                {number}
            </Text>
        </TouchableRipple>
    )
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: ColorEnum.GRAY_100,
    },
    text: {
        color: ColorEnum.JET_800,
    },
})

export default NumPadCircularButton
