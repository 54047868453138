import currencyJs from 'currency.js'

import { IOrderItem, IPrice } from '../../types/order'

export const ItemsPriceCalculator = {
    calculateTotalPrice: (items: IOrderItem[], currency: string): IPrice => {
        let price = currencyJs(0)

        items.forEach((item) => {
            if (currency !== item.unitPrice.currency) {
                throw new Error('Currencies mismatch')
            }

            price = price.add(ItemsPriceCalculator.calculateTotalPriceForItem(item).amount)
        })

        return { amount: price.toString(), currency: currency }
    },

    calculateItemPriceWithoutExtras: (price: IPrice, quantity: number): IPrice => {
        const finalPrice = currencyJs(price.amount).multiply(quantity)

        return { amount: finalPrice.toString(), currency: price.currency }
    },

    calculateTotalPriceForItem: (item: IOrderItem): IPrice => {
        const currency = item.unitPrice.currency

        let price = currencyJs(
            ItemsPriceCalculator.calculateItemPriceWithoutExtras(item.unitPrice, item.quantity)
                .amount
        )

        item.toppings?.forEach((topping) => {
            if (topping.unitPrice.currency !== currency) {
                throw new Error('Currencies mismatch')
            }

            price = price.add(
                currencyJs(topping.unitPrice.amount)
                    .multiply(topping.quantity)
                    .multiply(item.quantity)
            )
        })

        item.addedItems?.forEach((addedItem) => {
            if (addedItem.unitPrice.currency !== currency) {
                throw new Error('Currencies mismatch')
            }

            price = price.add(
                currencyJs(addedItem.unitPrice.amount)
                    .multiply(addedItem.quantity)
                    .multiply(item.quantity)
            )
        })

        return { amount: price.toString(), currency: currency }
    },
}
