import dayjs from 'dayjs'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'
import { Text } from 'react-native-paper'

import { ColorEnum } from '../../../constants/color'
import { OrderStateEnum } from '../../../constants/order'
import { IDeliveryTime } from '../../../types/order'
import { formatHumanReadableDurationFromMinutes } from '../../../utils/format-duration'

interface OrderCompletionIndicatorProps {
    state: OrderStateEnum
    acceptedDeliveryTime?: IDeliveryTime
    deliveredAt?: string
}

const OrderCompletionIndicator: FC<OrderCompletionIndicatorProps> = ({
    state,
    acceptedDeliveryTime,
    deliveredAt,
}) => {
    const { t } = useTranslation()

    if (state === OrderStateEnum.Cancelled) {
        return (
            <Text style={[styles.text, { color: ColorEnum.BRICK_500 }]}>
                {t(`order.state.cancelled`)}
            </Text>
        )
    }

    if (!acceptedDeliveryTime) {
        throw new Error('Accepted delivery time must be set')
    }

    const minutesRemainingDuration = dayjs(acceptedDeliveryTime.end).diff(deliveredAt, 'minutes')
    const remainingDuration = `${minutesRemainingDuration > 0 ? '+' : ''}${formatHumanReadableDurationFromMinutes(minutesRemainingDuration)}`

    const color = minutesRemainingDuration > 0 ? ColorEnum.EMERALD_700 : ColorEnum.BRICK_500

    return <Text style={[styles.text, { color }]}>{remainingDuration}</Text>
}

const styles = StyleSheet.create({
    text: {
        fontFamily: 'Satoshi-Bold',
        fontSize: 14,
        fontWeight: '700',
    },
})

export default OrderCompletionIndicator
