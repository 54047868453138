import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { Text } from 'react-native-paper'

import { ColorEnum } from '../../../constants/color'
import { DeliveryTypeCategoryEnum } from '../../../constants/order'
import { useIntervaledState } from '../../../hooks/useIntervalState'
import { IDeliveryTime } from '../../../types/order'
import { getCountdown } from '../../../utils/getCountdown'

interface CountdownWithDeliveryTypeCategoryProps {
    deliveryTime: IDeliveryTime
    deliveryTypeCategory: DeliveryTypeCategoryEnum
}

const CountdownWithDeliveryTypeCategory: FC<CountdownWithDeliveryTypeCategoryProps> = ({
    deliveryTime,
    deliveryTypeCategory,
}) => {
    const { t } = useTranslation()

    if (!deliveryTime) {
        return null
    }

    const [countdown, isDelayed] = useIntervaledState(
        useCallback(() => getCountdown(deliveryTime), [deliveryTime]),
        60000
    )

    const color = !isDelayed ? ColorEnum.EMERALD_700 : ColorEnum.BRICK_500

    return (
        <View style={styles.container}>
            <Text style={[styles.countdownText, { color }]}>{countdown}</Text>
            <Text style={[styles.deliveryTypeCategoryText, { color }]}>
                {t(`order.countdown.deliveryTypeCategory.${deliveryTypeCategory}`)}
            </Text>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'flex-end',
    },
    countdownText: {
        fontFamily: 'Satoshi-Bold',
        fontSize: 16,
        fontWeight: '700',
    },
    deliveryTypeCategoryText: {
        fontSize: 12,
    },
})

export default CountdownWithDeliveryTypeCategory
