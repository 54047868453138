import { AxiosError } from 'axios'

export const formatAxiosErrorLog = (error: AxiosError) => {
    let log

    if (error.response) {
        log = `Axios Error Response:
Request: ${JSON.stringify(error.request, null, 2)};
Response status: ${error.response.status};
Response headers: ${JSON.stringify(error.response.headers, null, 2)};
Response data: ${JSON.stringify(error.response.data, null, 2)};`
    } else if (error.request) {
        log = `Axios Error:
Message: ${error.message};
Request: ${JSON.stringify(error.request, null, 2)};`
    } else {
        log = `Axios Error:
Message: ${error.message};
Error: ${JSON.stringify(error, null, 2)};`
    }

    return log
}
