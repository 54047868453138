import React, { FC } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

import { ColorEnum } from '../../../../constants/color'
import OrderAndCustomerInfo from './OrderAndCustomerInfo'
import ShortCodeAndDeliveryTypeCategoryAndChannelWithCountdownOrCompletionIndicator from './ShortCodeAndDeliveryTypeCategoryAndChannelWithCountdownOrCompletionIndicator'

interface OrderDetailHeaderProps {
    uuid: string
    style?: StyleProp<ViewStyle>
}

const OrderDetailHeader: FC<OrderDetailHeaderProps> = ({ uuid, style }) => {
    return (
        <View style={[styles.container, style]}>
            <ShortCodeAndDeliveryTypeCategoryAndChannelWithCountdownOrCompletionIndicator
                uuid={uuid}
                style={styles.deliveryTypeAndChannelAndShortCodeWithCountdown}
            />

            <OrderAndCustomerInfo uuid={uuid} />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: ColorEnum.GRAY_100,
        padding: 16,
    },
    deliveryTypeAndChannelAndShortCodeWithCountdown: {
        marginBottom: 24,
    },
})

export default OrderDetailHeader
