import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs'
import { useNavigation } from '@react-navigation/native'
import { FC } from 'react'
import { StyleSheet, View } from 'react-native'
import { IconButton } from 'react-native-paper'

import { ColorEnum } from '../../../constants/color'
import { OrdersModuleScreenEnum } from '../../../constants/screen'
import TabBar from '../../UI/TabBar/TabBar'

const TabBarWithMapIcon: FC<MaterialTopTabBarProps> = (props) => {
    const navigation = useNavigation()

    const handleMapIconButtonPress = () => {
        navigation.navigate(OrdersModuleScreenEnum.Map)
    }

    return (
        <View style={styles.container}>
            <TabBar {...props} />
            <IconButton
                icon="map-outline"
                size={24}
                iconColor={ColorEnum.BRICK_600}
                onPress={handleMapIconButtonPress}
                style={styles.mapIconButton}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: ColorEnum.WHITE,
    },
    mapIconButton: {
        margin: 0,
        marginTop: 4,
    },
})

export default TabBarWithMapIcon
