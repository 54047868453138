import { FC } from 'react'

import { authProfilePinCodeFieldLength } from '../../../constants/codeEntryField'
import { useAppSelector } from '../../../hooks/redux'
import { authSelectors } from '../../../redux/reducers/auth/selectors'
import { verticalScale } from '../../../utils/metrics'
import CodeEntryField from '../../UI/CodeEntryField/CodeEntryField'

const AuthProfilePinCodeField: FC = () => {
    const pin = useAppSelector(authSelectors.pin)

    return (
        <CodeEntryField
            length={authProfilePinCodeFieldLength}
            style={{ marginTop: verticalScale(22) }}
        >
            {pin}
        </CodeEntryField>
    )
}

export default AuthProfilePinCodeField
