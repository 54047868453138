import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { ColorEnum } from '../../../constants/color'
import { AuthScreenEnum } from '../../../constants/screen'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { fetchAuthDeviceId } from '../../../redux/reducers/auth/action-creators'
import { authSelectors } from '../../../redux/reducers/auth/selectors'
import { selectScreenInfo } from '../../../redux/reducers/screenInfo'
import AuthHeader from '../../Auth/AuthHeader'
import AuthLicenceIdField from '../../Auth/LicenceVerification/AuthLicenceIdField'
import AuthLicenceVerificationNumPad from '../../Auth/LicenceVerification/AuthLicenceVerificationNumPad'

const LicenceVerificationScreen: FC = () => {
    const hostUrl = useAppSelector(authSelectors.hostUrl)
    const deviceId = useAppSelector(authSelectors.deviceId)
    useAppSelector(selectScreenInfo)

    const dispatch = useAppDispatch()

    const { top } = useSafeAreaInsets()

    const { t } = useTranslation()

    const navigation = useNavigation()

    useFocusEffect(
        useCallback(() => {
            if (!hostUrl || !deviceId) return

            navigation.navigate(AuthScreenEnum.Device)
        }, [hostUrl, deviceId])
    )

    useFocusEffect(
        useCallback(() => {
            if (!hostUrl || deviceId) return

            dispatch(fetchAuthDeviceId())
        }, [hostUrl, deviceId])
    )

    return (
        <View style={[styles.container, { paddingTop: top }]} testID="licenceVerificationScreen">
            <AuthHeader
                title={t('authScreensDeviceAuthorization')}
                hint={t('licenceVerificationScreenEnterLicenceId')}
            />
            <AuthLicenceIdField />
            <AuthLicenceVerificationNumPad />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        backgroundColor: ColorEnum.JET_800,
    },
})

export default LicenceVerificationScreen
