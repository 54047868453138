import Config from 'react-native-config'

import { initializeApp } from '../../firebase/app'
import { connectAuthEmulator, getAuth } from '../../firebase/auth'
import { connectDatabaseEmulator, getDatabase } from '../../firebase/database'
import { isAndroid, isWeb } from '../constants/environment'
import { firebaseConfig, isFirebaseEmulators } from '../constants/firebase'

if (isWeb) {
    initializeApp(firebaseConfig)
}

if (isFirebaseEmulators) {
    const host = isAndroid ? Config.LOCAL_MACHINE_HOST_ANDROID! : Config.LOCAL_MACHINE_HOST!

    const auth = getAuth()
    connectAuthEmulator(auth, `http://${host}:${Config.FIREBASE_AUTH_EMULATOR_PORT}`, {
        disableWarnings: true,
    })

    const db = getDatabase()
    connectDatabaseEmulator(db, host, +Config.FIREBASE_DATABASE_EMULATOR_PORT!)
}
