import { FC } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { Text } from 'react-native-paper'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'

import { ColorEnum } from '../../../../constants/color'

interface RemovedIngredientProps {
    children: string
    style?: StyleProp<ViewStyle>
}

const RemovedIngredient: FC<RemovedIngredientProps> = ({ children, style }) => {
    return (
        <View style={[styles.container, style]}>
            <View style={styles.iconWrapper}>
                <Icon name="checkbox-blank" color={ColorEnum.BRICK_200} size={24} />
                <Icon
                    name="minus"
                    color={ColorEnum.BRICK_600}
                    style={styles.iconForeground}
                    size={16}
                />
            </View>
            <Text style={styles.name}>{children}</Text>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    iconWrapper: {
        marginEnd: 4,
    },
    name: {
        fontFamily: 'Satoshi-Regular',
        fontSize: 12,
        fontWeight: '400',
        color: ColorEnum.GRAY_900,
        marginStart: 4,
    },
    iconForeground: {
        position: 'absolute',
        left: 4,
        top: 4,
    },
})

export default RemovedIngredient
