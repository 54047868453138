import { FC, useEffect, useRef, useState } from 'react'
import { StyleSheet } from 'react-native'
import { ProgressBar as RNPProgressBar } from 'react-native-paper'

import { ColorEnum } from '../../../constants/color'
import { calculateProgressIntervalMs } from '../../../utils/calculateProgressIntervalMs'

const seconds = 10
const segment = 0.05

interface ProgressBarProps {
    onProgressEnd: () => void
}

const ProgressBar: FC<ProgressBarProps> = ({ onProgressEnd }) => {
    const [progress, setProgress] = useState(0)

    const intervalRef = useRef<NodeJS.Timeout | null>(null)
    const progressRef = useRef(0)

    useEffect(() => {
        intervalRef.current = setInterval(
            () => {
                if (progressRef.current >= 1) {
                    onProgressEnd()

                    if (!intervalRef.current) return

                    clearInterval(intervalRef.current)

                    return
                }

                progressRef.current = progressRef.current + segment
                setProgress(progressRef.current)
            },
            calculateProgressIntervalMs(seconds, segment)
        )

        return () => {
            if (!intervalRef.current) return

            clearInterval(intervalRef.current)
        }
    }, [])

    useEffect(() => {
        progressRef.current = 0
        setProgress(progressRef.current)
    }, [])

    return (
        <RNPProgressBar
            progress={progress}
            color={ColorEnum.YELLOW_500}
            style={styles.progressBar}
        />
    )
}

const styles = StyleSheet.create({
    progressBar: {
        position: 'absolute',
        bottom: -6,
        left: 9,
        right: 9,
        borderBottomLeftRadius: 30,
        borderBottomRightRadius: 30,
    },
})

export default ProgressBar
