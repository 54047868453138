import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'
import { Button, Dialog } from 'react-native-paper'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'

import { ColorEnum } from '../../constants/color'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { closeErrorDialog, dialogSelectors } from '../../redux/reducers/dialog'
import { moderateScale } from '../../utils/metrics'
import InfoMessage from '../UI/InfoMessage'

const ErrorDialog: FC = () => {
    const { visible, message } = useAppSelector(dialogSelectors.error)

    const dispatch = useAppDispatch()

    const { t } = useTranslation()

    if (!visible) {
        return null
    }

    if (!message) {
        throw new Error('Error message is not provided')
    }

    const handleDismiss = () => {
        dispatch(closeErrorDialog())
    }

    return (
        <Dialog
            visible={visible}
            onDismiss={handleDismiss}
            style={styles.dialog}
            testID="errorDialog"
        >
            <Dialog.Content>
                <Icon
                    name="alert"
                    color={ColorEnum.BRICK_700}
                    size={moderateScale(34, 48, 0.2)}
                    style={styles.icon}
                    testID="errorDialog-alertIcon"
                />
                <InfoMessage textStyle={{ fontSize: moderateScale(14, 16, 0.2) }}>
                    {message}
                </InfoMessage>
            </Dialog.Content>
            <Button textColor={ColorEnum.JET_500} onPress={handleDismiss} style={styles.button}>
                {t('close')}
            </Button>
        </Dialog>
    )
}

const styles = StyleSheet.create({
    dialog: {
        alignSelf: 'center',
        borderRadius: 10,
        maxWidth: 450,
        minWidth: 260,
    },
    icon: {
        alignSelf: 'center',
        marginBottom: 14,
    },
    button: {
        paddingVertical: 8,
    },
})

export default ErrorDialog
