import { useEffect } from 'react'
import Config from 'react-native-config'

import { getMessaging, getToken, onMessage } from '../../../firebase/messaging'
import { addRecentNotification } from '../../redux/reducers/notifications'
import { isNotification } from '../../utils/isNotification'
import { useAppDispatch } from '../redux'

export const useMessaging = () => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        // eslint-disable-next-line no-extra-semi
        ;(async () => {
            await Notification.requestPermission()

            const messaging = getMessaging()

            const token = await getToken(messaging, {
                vapidKey: Config.FIREBASE_MESSAGING_VAPID_KEY,
            })

            console.log(token)

            onMessage(messaging, (remoteMessage) => {
                if (!isNotification(remoteMessage.data)) return

                dispatch(addRecentNotification(remoteMessage.data))
            })
        })()
    }, [])
}
