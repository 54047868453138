import { ILicence } from '../../types/licence'
import { IProfile } from '../../types/profile'
import AxiosHttpFactory from '../axiosHttpFactory'
import { ILicenceAPI } from './types'

class LicenceAPI implements ILicenceAPI {
    private $licenceAPI = AxiosHttpFactory.createMultiClient()

    setHostUrl(url: string) {
        this.$licenceAPI.defaults.baseURL = `${url}/api/terminal-licence`
    }

    setAccessToken(token: string) {
        this.$licenceAPI.defaults.headers.common['Authorization'] = token
    }

    async fetchLicenceData() {
        const { data } = await this.$licenceAPI.get<ILicence>('/licence-data')

        return data
    }

    async fetchProfiles() {
        const { data } = await this.$licenceAPI.get<IProfile[]>('/profiles')

        return data
    }
}

export default new LicenceAPI()
