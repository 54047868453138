import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { useNavigation } from '@react-navigation/native'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { isDetox } from '../../../constants/environment'
import { OrdersTabEnum, mobileOrdersModuleStackId } from '../../../constants/screen'
import { OrdersTabsParamList } from '../../../types/navigation'
import OrdersArchive from '../../OrdersModule/OrdersTabs/OrdersArchive'
import OrdersDrive from '../../OrdersModule/OrdersTabs/OrdersDrive'
import OrdersList from '../../OrdersModule/OrdersTabs/OrdersList'
import TabBarWithMapIcon from '../../OrdersModule/OrdersTabs/TabBarWithMapIcon'
import TabBar from '../../UI/TabBar/TabBar'

const Tab = createMaterialTopTabNavigator<OrdersTabsParamList>()

const OrdersTabs: FC = () => {
    const { t } = useTranslation()

    const navigation = useNavigation()

    const tabBar = navigation.getId() === mobileOrdersModuleStackId ? TabBarWithMapIcon : TabBar

    return (
        <Tab.Navigator tabBar={tabBar} screenOptions={{ swipeEnabled: !isDetox }}>
            <Tab.Screen
                name={OrdersTabEnum.Orders}
                component={OrdersList}
                options={{ tabBarLabel: t(`ordersTabs.${OrdersTabEnum.Orders}`) }}
            />
            <Tab.Screen
                name={OrdersTabEnum.Drive}
                component={OrdersDrive}
                options={{ tabBarLabel: t(`ordersTabs.${OrdersTabEnum.Drive}`) }}
            />
            <Tab.Screen
                name={OrdersTabEnum.Archive}
                component={OrdersArchive}
                options={{ tabBarLabel: t(`ordersTabs.${OrdersTabEnum.Archive}`) }}
            />
        </Tab.Navigator>
    )
}

export default OrdersTabs
