import { useEffect, useState } from 'react'

// CAUTION!!!
// Need to check if the getStateCallback parameter keeps the same reference.
// If your getStateCallback function is defined inside the component, then its reference will be changed on each component render.
// In this case, use the useCallback hook on your getStateCallback function in the component to memoize it.
// https://beta.reactjs.org/reference/react/useCallback

export const useIntervaledState = <T>(
    getStateCallback: () => T,
    intervalMilliseconds: number,
    notRepeatable?: boolean
) => {
    const [state, setState] = useState<T>(getStateCallback)

    useEffect(() => {
        setState(getStateCallback())

        if (notRepeatable) return

        let interval: NodeJS.Timeout

        const timeoutSec = 60 - new Date().getSeconds()

        const timeout = setTimeout(() => {
            setState(getStateCallback())

            interval = setInterval(() => {
                setState(getStateCallback())
            }, intervalMilliseconds)
        }, timeoutSec * 1000)

        return () => {
            clearTimeout(timeout)
            clearInterval(interval)
        }
    }, [intervalMilliseconds, notRepeatable, getStateCallback])

    return state
}
