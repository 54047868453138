import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { IconButton, Text } from 'react-native-paper'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'

import { ColorEnum } from '../../../../constants/color'
import { SegmentEnum } from '../../../../constants/order'
import { ITag } from '../../../../types/order'
import Tag from '../../../UI/Tag'
import CustomerEvaluations from './CustomerEvaluations'

interface CustomerInfoProps {
    name?: string
    segment?: SegmentEnum
    daysFromLastOrder?: number
    note?: string
    tags?: ITag[]
    evaluationFood?: number
    evaluationDelivery?: number
    evaluationDate?: string
    style?: StyleProp<ViewStyle>
}

const CustomerInfo: FC<CustomerInfoProps> = ({
    name,
    segment,
    daysFromLastOrder,
    note,
    tags,
    evaluationFood,
    evaluationDelivery,
    evaluationDate,
    style,
}) => {
    const { t } = useTranslation()

    const handleEditButtonPress = () => {
        return
    }

    const getBackgroundForSegment = (segment: SegmentEnum) => {
        switch (segment) {
            default:
            case SegmentEnum.New:
                return ColorEnum.YELLOW_400
            case SegmentEnum.Loyal:
                return ColorEnum.EMERALD_500
            case SegmentEnum.Occasional:
                return ColorEnum.YELLOW_600
            case SegmentEnum.Lost:
                return ColorEnum.BRICK_600
        }
    }

    return (
        <View style={[styles.container, style]}>
            <View style={{ flexDirection: 'row' }}>
                {name && <Text style={styles.name}>{name}</Text>}
                {segment && (
                    <View>
                        <Text
                            style={[
                                styles.segment,
                                {
                                    backgroundColor: getBackgroundForSegment(segment),
                                },
                            ]}
                        >
                            {t(`customerSegment.${segment}`)}
                        </Text>
                    </View>
                )}
            </View>
            {(daysFromLastOrder || note) && (
                <View style={styles.lastOrderAndNoteContainer}>
                    {daysFromLastOrder && (
                        <View style={styles.lastOrderWrapper}>
                            <Icon
                                name="food-takeout-box-outline"
                                size={16}
                                color={ColorEnum.JET_600}
                            />
                            <Text style={styles.noteText}>
                                {t('customer.daysFromLastOrder', { days: daysFromLastOrder })}
                            </Text>
                        </View>
                    )}
                    {note && (
                        <>
                            <Icon name="note-text-outline" size={16} color={ColorEnum.JET_600} />
                            <Text style={styles.noteText}>{note}</Text>
                        </>
                    )}
                </View>
            )}
            {tags && tags.length > 0 && (
                <View style={styles.tagsContainer}>
                    {tags.map((tag, index) => (
                        <Tag
                            key={index}
                            text={tag.text}
                            isPositive={tag.isPositive}
                            style={{ marginEnd: 8 }}
                        />
                    ))}
                </View>
            )}

            <CustomerEvaluations
                evaluationFood={evaluationFood}
                evaluationDelivery={evaluationDelivery}
                evaluationDate={evaluationDate}
            />

            <View style={styles.editButtonContainer}>
                <IconButton
                    icon="note-text-outline"
                    size={28}
                    iconColor={ColorEnum.BRICK_600}
                    style={styles.editButton}
                    onPress={handleEditButtonPress}
                />
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        borderRadius: 8,
        backgroundColor: ColorEnum.VIOLET_100,
        borderColor: ColorEnum.VIOLET_200,
        borderWidth: 1,
        paddingVertical: 8,
        paddingHorizontal: 16,
    },
    name: {
        fontFamily: 'Satoshi-Bold',
        fontSize: 14,
        fontWeight: '700',
    },
    segment: {
        paddingHorizontal: 8,
        paddingVertical: 2,
        borderRadius: 4,
        marginStart: 8,
        color: ColorEnum.WHITE,
        fontSize: 12,
    },
    lastOrderAndNoteContainer: {
        flexDirection: 'row',
        marginTop: 8,
        alignItems: 'center',
    },
    lastOrderWrapper: {
        marginEnd: 12,
        flexDirection: 'row',
        alignItems: 'center',
    },
    noteText: {
        fontFamily: 'Satoshi-Bold',
        marginStart: 4,
    },
    tagsContainer: {
        flexDirection: 'row',
        marginVertical: 24,
    },
    note: {
        color: ColorEnum.GRAY_600,
        fontFamily: 'Satoshi-Regular',
        marginStart: 4,
    },
    editButtonContainer: {
        backgroundColor: ColorEnum.VIOLET_200,
        position: 'absolute',
        top: -1,
        right: -1,
        borderTopEndRadius: 8,
        borderBottomStartRadius: 8,
    },
    editButton: {
        margin: -4,
    },
})

export default CustomerInfo
