import React, { FC } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

import { IOrderItemRemovedIngredient, IOrderItemTopping, IPrice } from '../../../../types/order'
import ItemNameQuantityPrice from './ItemNameQuantityPrice'
import ItemNote from './ItemNote'
import RemovedIngredient from './RemovedIngredient'
import Topping from './Topping'

interface OrderDetailItemProps {
    name: string
    quantity: number
    price: IPrice
    toppings?: IOrderItemTopping[]
    removedIngredients?: IOrderItemRemovedIngredient[]
    note?: string
    style?: StyleProp<ViewStyle>
}

const OrderDetailItem: FC<OrderDetailItemProps> = ({
    name,
    quantity,
    price,
    toppings,
    removedIngredients,
    note,
    style,
}) => {
    return (
        <View style={[styles.container, style]}>
            <ItemNameQuantityPrice name={name} price={price} quantity={quantity} />

            {toppings?.map((topping) => (
                <Topping
                    key={topping.uuid}
                    name={topping.name}
                    quantity={topping.quantity}
                    price={topping.unitPrice}
                    style={styles.addedTopping}
                />
            ))}

            {removedIngredients?.map((ingredient) => (
                <RemovedIngredient key={ingredient.uuid} style={styles.addedTopping}>
                    {ingredient.name}
                </RemovedIngredient>
            ))}

            {note && <ItemNote style={styles.note}>{note}</ItemNote>}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        padding: 10,
    },
    note: {
        marginTop: 8,
    },
    addedTopping: {
        marginTop: 8,
    },
})

export default OrderDetailItem
