import { FC } from 'react'
import { StyleSheet, View } from 'react-native'
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import { useContextSelector } from 'use-context-selector'

import { ColorEnum } from '../../../constants/color'
import { NotificationTypeEnum } from '../../../constants/notificationType'
import NotificationContext from './NotificationContext'

const NotificationIcon: FC = () => {
    const type = useContextSelector(NotificationContext, ({ notification }) => notification.type)

    const getBackgroundColor = (type: NotificationTypeEnum) => {
        switch (type) {
            case NotificationTypeEnum.NewOrder:
            default:
                return ColorEnum.EMERALD_200
        }
    }

    const getIconName = (type: NotificationTypeEnum) => {
        switch (type) {
            case NotificationTypeEnum.NewOrder:
            default:
                return 'food-takeout-box'
        }
    }

    return (
        <View style={[styles.container, { backgroundColor: getBackgroundColor(type) }]}>
            <MCIcon name={getIconName(type)} size={28} color={ColorEnum.JET_800} />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 24,
        height: 48,
        width: 48,
        marginRight: 8,
    },
})

export default NotificationIcon
