import { FC, useState } from 'react'
import { StyleSheet } from 'react-native'
import { Button } from 'react-native-paper'

import { ColorEnum } from '../../../../constants/color'

interface OrderDetailFooterActionButtonProps {
    children: string
    onPress: () => Promise<void>
}

const OrderDetailFooterActionButton: FC<OrderDetailFooterActionButtonProps> = ({
    children,
    onPress,
}) => {
    const [loading, setLoading] = useState(false)

    const handlePress = async () => {
        try {
            setLoading(true)

            await onPress()
        } finally {
            setLoading(false)
        }
    }

    return (
        <Button
            disabled={loading}
            loading={loading}
            textColor={ColorEnum.WHITE}
            buttonColor={ColorEnum.EMERALD_500}
            onPress={handlePress}
            labelStyle={styles.label}
            contentStyle={styles.content}
            style={styles.button}
        >
            {children}
        </Button>
    )
}

const styles = StyleSheet.create({
    label: {
        textTransform: 'uppercase',
    },
    content: {
        paddingVertical: 3,
    },
    button: {
        justifyContent: 'center',
        borderRadius: 4,
        width: 240,
    },
})

export default OrderDetailFooterActionButton
