import { FC } from 'react'
import { StyleProp, StyleSheet, Text, ViewStyle } from 'react-native'
import { TouchableRipple } from 'react-native-paper'

import { ColorEnum } from '../../constants/color'
import { hexToRgba } from '../../utils/hexToRgba'
import { horizontalScale, moderateScale, verticalScale } from '../../utils/metrics'

interface TextLinkProps {
    children: string
    onPress: () => void
    style?: StyleProp<ViewStyle>
}

const TextLink: FC<TextLinkProps> = ({ children, onPress, style }) => {
    const containerStyle = {
        paddingVertical: verticalScale(5),
        paddingHorizontal: horizontalScale(5, 12),
    }
    const textStyle = {
        fontSize: moderateScale(12, 30, 0.2),
    }

    return (
        <TouchableRipple
            rippleColor={hexToRgba(ColorEnum.JET_800)}
            onPress={onPress}
            style={[containerStyle, style]}
        >
            <Text style={[styles.text, textStyle]}>{children}</Text>
        </TouchableRipple>
    )
}

const styles = StyleSheet.create({
    text: {
        color: ColorEnum.WHITE,
        textTransform: 'uppercase',
    },
})

export default TextLink
