import React, { FC } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { Text } from 'react-native-paper'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'

import { ColorEnum } from '../../../../constants/color'

interface ItemNoteProps {
    children: string
    style?: StyleProp<ViewStyle>
}

const ItemNote: FC<ItemNoteProps> = ({ children, style }) => {
    return (
        <View style={[styles.container, style]}>
            <Icon name="note-text-outline" color={ColorEnum.YELLOW_600} size={16} />
            <Text style={styles.text}>{children}</Text>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    text: {
        color: ColorEnum.GRAY_600,
        fontFamily: 'Satoshi-Regular',
        marginStart: 4,
    },
})

export default ItemNote
