import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(duration)
dayjs.extend(isToday)
dayjs.extend(isYesterday)
