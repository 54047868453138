import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

import { useAppSelector } from '../../../../hooks/redux'
import { ordersSelectors } from '../../../../redux/reducers/orders/selectors'
import { selectScreenInfo } from '../../../../redux/reducers/screenInfo'
import { getFullDeliveryAddress } from '../../../../utils/getFullDeliveryAddress'
import CustomerInfo from './CustomerInfo'
import OrderInfo from './OrderInfo'

interface OrderAndCustomerInfoProps {
    uuid: string
}

const OrderAndCustomerInfo: FC<OrderAndCustomerInfoProps> = ({ uuid }) => {
    const order = useAppSelector(ordersSelectors.orderByUuid(uuid))
    const screenInfo = useAppSelector(selectScreenInfo)

    const { t } = useTranslation()

    if (!order) {
        throw new Error('Order must be set')
    }

    const address = order.deliveryAddress
        ? getFullDeliveryAddress(order.deliveryAddress?.street, order.deliveryAddress?.city)
        : undefined

    return (
        <View style={{ flexDirection: screenInfo.isLargeScreen ? 'row' : 'column' }}>
            <OrderInfo
                deliveryTime={order.acceptedDeliveryTime ?? order.requestedDeliveryTime}
                phoneNumber={order.phoneNumber}
                address={address}
                paymentType={order.paymentType && t(`order.paymentType.${order.paymentType.type}`)}
                style={{ width: screenInfo.isLargeScreen ? '50%' : '100%' }}
            />

            <CustomerInfo
                name={order.customerName}
                style={{
                    width: screenInfo.isLargeScreen ? '50%' : '100%',
                    marginTop: screenInfo.isLargeScreen ? 0 : 12,
                }}
            />
        </View>
    )
}

export default OrderAndCustomerInfo
