import { useFonts } from 'expo-font'
import * as SplashScreen from 'expo-splash-screen'
import { StatusBar } from 'expo-status-bar'
import React, { FC, useEffect, useState } from 'react'
import { KeyboardAvoidingView, StyleSheet } from 'react-native'
import { GestureHandlerRootView } from 'react-native-gesture-handler'

import LicenceAPI from '../api/licence/licence'
import { setAllApisHostUrl } from '../api/setAllApisHostUrl'
import { ColorEnum } from '../constants/color'
import { isIOS } from '../constants/environment'
import { useMessaging } from '../hooks/messaging/useMessaging'
import { useAppDispatch } from '../hooks/redux'
import { useScreenInfo } from '../hooks/useScreenInfo'
import { setAuthDeviceAuth, setAuthHasTokens, setAuthProfiles } from '../redux/reducers/auth'
import { setAuthProfile } from '../redux/reducers/auth/action-creators'
import AuthStorageService from '../services/authStorage/authStorage'
import AppOverlay from './AppOverlay/AppOverlay'
import Dialogs from './Dialogs'
import Navigation from './Navigation'

SplashScreen.preventAutoHideAsync()

const AppContainer: FC = () => {
    const dispatch = useAppDispatch()

    const [authLoaded, setAuthLoaded] = useState(false)

    useScreenInfo()
    useMessaging()

    const [fontsLoaded] = useFonts({
        'Satoshi-Medium': require('../../assets/fonts/Satoshi-Medium.otf'),
        'Satoshi-Bold': require('../../assets/fonts/Satoshi-Bold.otf'),
        'Satoshi-Light': require('../../assets/fonts/Satoshi-Light.otf'),
        'Satoshi-Italic': require('../../assets/fonts/Satoshi-Italic.otf'),
        'Satoshi-BoldItalic': require('../../assets/fonts/Satoshi-BoldItalic.otf'),
        'Satoshi-Regular': require('../../assets/fonts/Satoshi-Regular.otf'),
    })

    useEffect(() => {
        // eslint-disable-next-line no-extra-semi
        ;(async () => {
            const deviceAuth = await AuthStorageService.getDeviceAuth()

            if (deviceAuth) {
                dispatch(setAuthDeviceAuth(deviceAuth))

                setAllApisHostUrl(deviceAuth.hostUrl)
                LicenceAPI.setAccessToken(deviceAuth.deviceToken)

                const profiles = await AuthStorageService.getProfiles()

                if (profiles) {
                    dispatch(setAuthProfiles(profiles))

                    const profile = await AuthStorageService.getProfile()

                    if (profile) {
                        dispatch(setAuthProfile(profile))

                        const tokens = await AuthStorageService.getTokens()

                        if (tokens) {
                            dispatch(setAuthHasTokens(true))
                        }
                    }
                }
            }

            setAuthLoaded(true)
        })()
    }, [])

    useEffect(() => {
        // eslint-disable-next-line no-extra-semi
        ;(async () => {
            if (!fontsLoaded || !authLoaded) return

            await SplashScreen.hideAsync()
        })()
    }, [fontsLoaded, authLoaded])

    return (
        <GestureHandlerRootView style={styles.container}>
            <KeyboardAvoidingView behavior={isIOS ? 'padding' : 'height'} style={{ flex: 1 }}>
                <StatusBar style="light" backgroundColor={ColorEnum.BRICK_700} />
                {authLoaded && <Navigation />}
                <Dialogs />
                <AppOverlay />
            </KeyboardAvoidingView>
        </GestureHandlerRootView>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
})

export default AppContainer
