import { FC } from 'react'
import { FlatList, StyleSheet } from 'react-native'

import { ColorEnum } from '../../../constants/color'
import { useAppSelector } from '../../../hooks/redux'
import { ordersSelectors } from '../../../redux/reducers/orders/selectors'
import ArchivedOrderListItem from '../OrderListItem/ArchivedOrderListItem'

const OrdersArchive: FC = () => {
    const uuids = useAppSelector(ordersSelectors.archivedOrderUuids)
    const selectedOrderUuid = useAppSelector(ordersSelectors.selectedOrderUuid)

    return (
        <FlatList
            data={uuids}
            renderItem={({ item: uuid }) => (
                <ArchivedOrderListItem uuid={uuid} selected={uuid === selectedOrderUuid} />
            )}
            keyExtractor={(uuid) => uuid}
            style={[styles.list]}
        />
    )
}

const styles = StyleSheet.create({
    list: {
        backgroundColor: ColorEnum.WHITE,
        flex: 1,
    },
})

export default OrdersArchive
