import React, { FC, ReactNode } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { TouchableRipple } from 'react-native-paper'

import { ColorEnum } from '../../../../constants/color'
import { IOrder } from '../../../../types/order'
import DeliveryTypeCategoryAndChannel from '../DeliveryTypeCategoryAndChannel'
import ShortCode from '../ShortCode'
import CustomerName from './CustomerName'
import OrderListItemContext from './OrderListItemContext'
import State from './State'

interface OrderListItemProps {
    order: IOrder
    State?: ReactNode
    RightEndComponent: ReactNode
    onPress: () => void
    style?: StyleProp<ViewStyle>
}

interface OrderListItemComponent extends FC<OrderListItemProps> {
    State: typeof State
}

const OrderListItem: OrderListItemComponent = ({
    order,
    RightEndComponent,
    State,
    onPress,
    style,
}) => {
    return (
        <OrderListItemContext.Provider value={{ order }}>
            <TouchableRipple onPress={onPress} style={[styles.outerContainer, style]}>
                <View style={styles.innerContainer}>
                    {order.shortCode && <ShortCode>{order.shortCode}</ShortCode>}
                    <View style={styles.contentContainer}>
                        <DeliveryTypeCategoryAndChannel
                            channel={order.channel}
                            deliveryTypeCategory={order.deliveryType.typeCategory}
                        />
                        <View style={styles.contentBottomContainer}>
                            <CustomerName />
                            {State}
                        </View>
                    </View>
                    {RightEndComponent}
                </View>
            </TouchableRipple>
        </OrderListItemContext.Provider>
    )
}

const styles = StyleSheet.create({
    outerContainer: {
        height: 64,
        paddingHorizontal: 16,
    },
    innerContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        borderBottomWidth: 1,
        borderBottomColor: ColorEnum.GRAY_200,
        paddingVertical: 10,
    },
    contentContainer: {
        flex: 1,
        height: '100%',
        justifyContent: 'space-evenly',
    },
    contentBottomContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 4,
    },
    name: {
        fontSize: 12,
        color: ColorEnum.GRAY_600,
    },
})

OrderListItem.State = State

export default OrderListItem
