import { LogBox } from 'react-native'

import { isDetox } from '../constants/environment'
import { ignoredLogs } from '../constants/ignoredLogs'

const ignoreLogs = () => {
    if (isDetox) {
        LogBox.ignoreAllLogs()

        return
    }

    LogBox.ignoreLogs(ignoredLogs)

    if (!__DEV__) return

    const withoutIgnored =
        (logger: any) =>
        (...args: any) => {
            const output = args.join(' ')

            if (ignoredLogs.some((log) => output.includes(log))) return

            logger(...args)
        }

    console.log = withoutIgnored(console.log)
    console.info = withoutIgnored(console.info)
    console.warn = withoutIgnored(console.warn)
    console.error = withoutIgnored(console.error)
}

ignoreLogs()
