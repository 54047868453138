import {
    DeliveryTypeCategoryEnum,
    OrderChannelEnum,
    OrderStateEnum,
    PaymentTypeEnum,
    SegmentEnum,
} from '../../../constants/order'
import { OrdersTabEnum } from '../../../constants/screen'

export default {
    accessibilityLabels: {
        buttons: {
            reload: 'Znovu načíst',
        },
    },

    close: 'Zavřít',
    cancel: 'Zrušit',
    back: 'Zpět',
    apply: 'Potvrdit',

    serverError: 'Nepodařilo se navázat spojení se serverem, zkuste to prosím znovu',
    unknownError: 'Vyskytla se neočekávaná chyba',

    authScreensDeviceAuthorization: 'Autorizace zařízení',

    licenceVerificationScreenEnterLicenceId: 'Zadejte ID licence',
    licenceVerificationScreenLicenceId: 'ID licence',
    licenceVerificationScreenEntityNotFound: 'Licenci se nepodařilo najít, zkontrolujte zadané ID',

    deviceAuthorizationScreenEnterCode: 'Zadejte autorizační kód',
    deviceAuthorizationScreenChangeLicenceId: 'Změnit ID licence',
    deviceAuthorizationScreenErrorNotGranted:
        'Autorizační kód není platný. Zkuste to, prosím, znovu.',

    profileScreensLogin: 'Přihlášení',

    profileSelectionScreenSelectProfile: 'Vyberte účet pro přihlášení',
    profileSelectionScreenChangeLicence: 'Změnit licenci',
    profileSelectionScreenEmptyListMessage: 'Nejsou žádné profily',

    profilePinScreenEnterPin: 'Zadejte PIN k vybranému účtu',
    profilePinScreenSelectAnotherAccount: 'Zvolit jiný účet',
    profilePinScreenInvalidClientError:
        'Autorizace zařízení není platná nebo selhala. Prosím, proveďte autorizaci zařízení znovu.',
    profilePinScreenInvalidGrantError: 'Přihlášení se nezdařilo. Zkuste to prosím znovu.',

    menuOrders: 'Objednávky',
    menuCashDesk: 'Pokladna',
    menuSettings: 'Nastavení',

    notificationsTitle: 'Události',
    notificationsEmptyListMessage: 'Nejsou žádné události',

    ordersListSectionNew: 'Nové',
    ordersListSectionProcess: 'V přípravě',
    ordersListSectionOncoming: 'Budoucí',

    order: {
        channel: {
            [OrderChannelEnum.Web]: 'E-shop',
            [OrderChannelEnum.Foodora]: 'Foodora',
            [OrderChannelEnum.Wolt]: 'Wolt',
            [OrderChannelEnum.Terminal]: 'Terminál',
            [OrderChannelEnum.Kiosk]: 'Kiosek',
            [OrderChannelEnum.QR]: 'QR',
            [OrderChannelEnum.Menuboard]: 'Menuboard',
        },
        deliveryTypeCategory: {
            [DeliveryTypeCategoryEnum.Delivery]: 'Rozvoz jídla',
            [DeliveryTypeCategoryEnum.Takeaway]: 'Jídlo s sebou',
            [DeliveryTypeCategoryEnum.EatIn]: 'Na místě',
        },
        paymentType: {
            [PaymentTypeEnum.CardOnsite]: 'Kartou při převzetí',
            [PaymentTypeEnum.Cash]: 'Hotově',
            [PaymentTypeEnum.Invoice]: 'Fakturou',
            [PaymentTypeEnum.MealVoucher]: 'Stravenkami',
            [PaymentTypeEnum.MealVoucherCard]: 'Stravenkovou kartou',
            [PaymentTypeEnum.Online]: 'Kartou online',
        },
        detail: {
            items: 'Položky objednávky',
            itemHeader: 'Položka',
            quantityHeader: 'Ks',
            priceHeader: 'Cena',
            priceItemsLabel: 'Cena za produkty',
            priceDeliveryLabel: 'Dopravné',
            priceTotalLabel: 'Celkem',
            showHistory: 'Zobrazit historii objednávky',
            acceptationTitle: 'Přijetí objednávky',
            acceptationCustomTimeTitle: 'Vlastní čas',
            requiredTime: 'Požadovaný čas',
            customTime: 'Vlastní',
            postpone: 'Odložít',
            acceptPostpone: 'Potvrdit odložení',
            toProduction: 'Do přípravy',
            postponeTitle:
                'Objednávka se přesune zpět do sekce Nové v určený čas před zbývajícím časem doručení.',
            customPostponeTitle: 'Vrátit do sekce Nové před časem doručení',
            toReady: 'K výdeji',
            toDelivered: 'Doručeno',
            toDelivering: 'Doručováno',
            cancel: 'Storno',
            accept: 'Příjmout',
            cancelDialog: {
                optionsFormTitle: 'Storno objednávky',
                noteFormTitle: 'Vložit poznámku',
                reasonLabel: 'Kdo stornuje',
                noteLabel: 'Poznámka',
                customerReason: 'Zákazník',
                restaurantReason: 'Restaurace',
                insertNote: 'Vložit poznámku',
                noteFormPlaceholder: 'Toto je text poznámky',
                confirmAction: 'Potvrdit',
                cancelAction: 'Zrušit storno',
            },
            accepted: 'Přijato',
        },
        deliveryTime: {
            asap: 'Do {{ time }}',
            unknown: 'Neznámý typ času doručeni',
        },
        state: {
            [OrderStateEnum.AwaitingAcceptance]: 'K přijetí',
            [OrderStateEnum.Accepted]: 'Přijato',
            [OrderStateEnum.Production]: 'V přípravě',
            [OrderStateEnum.Ready]: 'Připraveno',
            [`${OrderStateEnum.Ready}_for_delivery`]: 'K doručení',
            [OrderStateEnum.Delivering]: 'Doručuje se',
            [OrderStateEnum.Cancelled]: 'Storno',
        },
        countdown: {
            deliveryTypeCategory: {
                [DeliveryTypeCategoryEnum.Delivery]: 'K doručení',
                [DeliveryTypeCategoryEnum.Takeaway]: 'K předání',
                [DeliveryTypeCategoryEnum.EatIn]: 'K předání',
            },
        },
    },

    timeUnit: {
        hour: 'hod',
        min: 'min',
        shortHour: 'h',
        shortMin: 'm',
        day: '(1)[den];(2-4)[dny];(5-inf)[dnů];',
    },

    customer: {
        daysFromLastOrder: '{{ days }} d',
    },

    customerSegment: {
        [SegmentEnum.New]: 'Nový',
        [SegmentEnum.Loyal]: 'Loajalní',
        [SegmentEnum.Occasional]: 'Občasný',
        [SegmentEnum.Lost]: 'Ztracen',
    },

    date: {
        today: 'Dnes',
        yesterday: 'Včera',
        daysAgo: 'Před {{ days }} dny',
        monthAgo: 'Před měsícem',
        monthsAgo: 'Před {{ months }} měsíci',
        moreThanAYearAgo: 'Před více než rokem',
    },

    cancelDialog: {
        title: 'Důvod storna',
        noOption: 'Nejsou k dispozici žádné důvody storna',
    },

    ordersTabs: {
        [OrdersTabEnum.Orders]: 'Objednávky',
        [OrdersTabEnum.Drive]: 'Rozvoz',
        [OrdersTabEnum.Archive]: 'Archiv',
    },
}
