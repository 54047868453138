export enum AuthScreenEnum {
    Licence = 'LicenceVerificationScreen',
    Device = 'DeviceAuthorizationScreen',
    ProfileSelection = 'ProfileSelectionScreen',
    ProfilePin = 'ProfilePinScreen',
}

export enum MainScreenEnum {
    OrdersModule = 'MainScreen.OrdersModule',
}

export enum OrdersModuleScreenEnum {
    Tabs = 'OrdersModuleScreen.Tabs',
    Detail = 'OrdersModuleScreen.OrderDetail',
    Map = 'OrdersModuleScreen.Map',
}

export enum OrdersTabEnum {
    Orders = 'OrdersTabs.Orders',
    Drive = 'OrdersTabs.Drive',
    Archive = 'OrdersTabs.Archive',
}

export const mobileOrdersModuleStackId = 'MobileOrdersModuleStack'
