import { FC } from 'react'

import { useFirebaseOrdersConnect } from '../../hooks/firebase/useFirebaseOrdersConnect'
import { useAppSelector } from '../../hooks/redux'
import { selectScreenInfo } from '../../redux/reducers/screenInfo'
import MobileOrdersModuleStack from '../navigations/orders-module/MobileOrdersModuleStack'
import TabletOrderModuleContainer from './TabletOrderModuleContainer'

const OrdersModule: FC = () => {
    const screenInfo = useAppSelector(selectScreenInfo)

    useFirebaseOrdersConnect()

    if (!screenInfo.isLargeScreen) {
        return <MobileOrdersModuleStack />
    }

    return <TabletOrderModuleContainer />
}

export default OrdersModule
