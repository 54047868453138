import { useNavigation } from '@react-navigation/native'
import { FC, memo } from 'react'

import { ColorEnum } from '../../../constants/color'
import { OrdersModuleScreenEnum, mobileOrdersModuleStackId } from '../../../constants/screen'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { setSelectedOrderUuid } from '../../../redux/reducers/orders'
import { ordersSelectors } from '../../../redux/reducers/orders/selectors'
import { IDeliveryTime } from '../../../types/order'
import CountdownWithDeliveryTypeCategory from '../../UI/Order/CountdownWithDeliveryTypeCategory'
import OrderListItem from '../../UI/Order/OrderListItem/OrderListItem'

interface NewOrderListItemProps {
    uuid: string
    selected?: boolean
}

const NewOrderListItem: FC<NewOrderListItemProps> = ({ uuid, selected }) => {
    const order = useAppSelector(ordersSelectors.orderByUuid(uuid))

    const dispatch = useAppDispatch()

    const navigation = useNavigation()

    if (!order || (!order.acceptedDeliveryTime && !order.requestedDeliveryTime)) {
        throw new Error('Order with delivery time must be provided')
    }

    const handlePress = () => {
        if (navigation.getParent()?.getId() === mobileOrdersModuleStackId) {
            navigation.navigate(OrdersModuleScreenEnum.Detail, { uuid })

            return
        }

        dispatch(setSelectedOrderUuid(uuid))
    }

    const deliveryTime =
        order.acceptedDeliveryTime ?? (order.requestedDeliveryTime as IDeliveryTime)

    return (
        <OrderListItem
            order={order}
            State={<OrderListItem.State />}
            RightEndComponent={
                <CountdownWithDeliveryTypeCategory
                    deliveryTime={deliveryTime}
                    deliveryTypeCategory={order.deliveryType.typeCategory}
                />
            }
            onPress={handlePress}
            style={selected && { backgroundColor: ColorEnum.YELLOW_200 }}
        />
    )
}

export default memo(NewOrderListItem)
