import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'
import { Dialog, TextInput } from 'react-native-paper'

import { ColorEnum } from '../../../../constants/color'
import CancelWithConfirmButtons from '../../../UI/buttons/CancelWithConfirmButtons'

interface CancelOrderDialogNoteFormProps {
    note: string
    onNoteChange: (note: string) => void
    onCancelButton: () => void
    onApplyButton: () => void
}

const CancelOrderDialogNoteForm: FC<CancelOrderDialogNoteFormProps> = ({
    note,
    onNoteChange,
    onCancelButton,
    onApplyButton,
}) => {
    const { t } = useTranslation()

    return (
        <>
            <Dialog.Title style={styles.title}>
                {t('order.detail.cancelDialog.noteFormTitle')}
            </Dialog.Title>
            <Dialog.Content>
                <TextInput
                    multiline
                    mode="outlined"
                    outlineColor={ColorEnum.GRAY_300}
                    activeOutlineColor={ColorEnum.JET_600}
                    placeholder={t('order.detail.cancelDialog.noteFormPlaceholder')}
                    value={note}
                    onChangeText={onNoteChange}
                    style={styles.textInput}
                />
            </Dialog.Content>
            <Dialog.Actions style={{ justifyContent: 'center' }}>
                <CancelWithConfirmButtons
                    onCancel={onCancelButton}
                    onConfirm={onApplyButton}
                    style={{ flex: 1 }}
                />
            </Dialog.Actions>
        </>
    )
}

const styles = StyleSheet.create({
    title: {
        fontSize: 18,
        textAlign: 'center',
    },
    textInput: {
        backgroundColor: ColorEnum.WHITE,
        height: 284,
        marginBottom: 32,
    },
})

export default CancelOrderDialogNoteForm
