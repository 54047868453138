import { FC } from 'react'
import { StyleProp, StyleSheet, TextStyle, View, ViewStyle } from 'react-native'
import { Text } from 'react-native-paper'

import { ColorEnum } from '../../../constants/color'

interface ShortCodeProps {
    children: string
    textStyle?: StyleProp<TextStyle>
    style?: StyleProp<ViewStyle>
}

const ShortCode: FC<ShortCodeProps> = ({ children, textStyle, style }) => {
    return (
        <View style={[styles.container, style]}>
            <Text numberOfLines={2} style={[styles.text, textStyle]}>
                {children}
            </Text>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: ColorEnum.YELLOW_400,
        borderRadius: 8,
        height: 42,
        width: 42,
        marginRight: 10,
    },
    text: {
        fontFamily: 'Satoshi-Bold',
        fontSize: 14,
        fontWeight: '700',
    },
})

export default ShortCode
