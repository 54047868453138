import { NavigationContainer } from '@react-navigation/native'
import React, { FC } from 'react'

import { useAppSelector } from '../hooks/redux'
import { appSelectors } from '../redux/reducers/app'
import { authSelectors } from '../redux/reducers/auth/selectors'
import Layout from './Layout'
import AuthStack from './navigations/AuthStack'
import MainStack from './navigations/MainStack'
import GridMenuScreen from './screens/GridMenuScreen'

const Navigation: FC = () => {
    const isAuthCompleted = useAppSelector(authSelectors.isCompleted)
    const isGridMenuOpen = useAppSelector(appSelectors.gridMenuOpen)

    const renderStack = () => {
        if (!isAuthCompleted) {
            return <AuthStack />
        }

        if (isGridMenuOpen) {
            return <GridMenuScreen />
        }

        return (
            <Layout>
                <MainStack />
            </Layout>
        )
    }

    return <NavigationContainer>{renderStack()}</NavigationContainer>
}

export default Navigation
