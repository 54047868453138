import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'
import { Button, Text } from 'react-native-paper'

import { ColorEnum } from '../../../../constants/color'

interface CancelOrderDialogInsertNoteButtonProps {
    onPress: () => void
}

const CancelOrderDialogInsertNoteButton: FC<CancelOrderDialogInsertNoteButtonProps> = ({
    onPress,
}) => {
    const { t } = useTranslation()

    return (
        <Button
            icon="plus"
            textColor={ColorEnum.BRICK_600}
            onPress={onPress}
            labelStyle={styles.label}
            contentStyle={styles.content}
            style={styles.button}
        >
            <Text style={styles.text}>{t('order.detail.cancelDialog.insertNote')}</Text>
        </Button>
    )
}

const styles = StyleSheet.create({
    label: {
        fontSize: 24,
    },
    content: {
        alignSelf: 'flex-start',
        paddingVertical: 3,
    },
    text: {
        color: ColorEnum.BRICK_600,
        fontSize: 14,
        textTransform: 'uppercase',
    },
    button: {
        marginTop: 24,
    },
})

export default CancelOrderDialogInsertNoteButton
