import dayjs, { Dayjs } from 'dayjs'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'

import { ColorEnum } from '../../../../../../constants/color'
import { postponeOrderAvailableShiftValues } from '../../../../../../constants/order-flow-action-time-values'
import { useAppSelector } from '../../../../../../hooks/redux'
import { ordersSelectors } from '../../../../../../redux/reducers/orders/selectors'
import { selectScreenInfo } from '../../../../../../redux/reducers/screenInfo'
import { ICustomPostponeOrderTime } from '../../../../../../types/customPostponeOrderTime'
import { PostponeOrderTimeValues } from '../../../../../../types/order-flow-action-time-values'
import {
    calculatePostponedUntil,
    getPostponementTimeAsDuration,
} from '../../../../../../utils/postponeOrderTime'
import Chip from '../../../../../UI/Chip'
import DefaultPostponeOrderButtons from './DefaultPostponeOrderButtons'

interface DefaultPostponeOrderTimeSelectorProps {
    orderUuid: string
    postponementTime: PostponeOrderTimeValues | undefined
    customTime: ICustomPostponeOrderTime
    currentTime: Dayjs
    onTimePress: (value: PostponeOrderTimeValues) => void
    onBackButton: () => void
    onPostponeButton: () => Promise<void>
}

const DefaultPostponeOrderTimeSelector: FC<DefaultPostponeOrderTimeSelectorProps> = ({
    orderUuid,
    postponementTime,
    customTime,
    currentTime,
    onTimePress,
    onBackButton,
    onPostponeButton,
}) => {
    const order = useAppSelector(ordersSelectors.orderByUuid(orderUuid))
    const screenInfo = useAppSelector(selectScreenInfo)

    const { t } = useTranslation()

    if (!order) {
        return null
    }

    const checkDefaultTimeDisabled = (
        postponementTime: Exclude<PostponeOrderTimeValues, 'custom'>
    ) => {
        const durationTime = dayjs.duration({
            hours: postponementTime,
        })

        return calculatePostponedUntil(order, durationTime).isBefore(currentTime)
    }

    const checkIsDisablePostponeButton = () => {
        if (!postponementTime) {
            return true
        }

        const durationTime = getPostponementTimeAsDuration(postponementTime, customTime)

        return calculatePostponedUntil(order, durationTime).isBefore(currentTime)
    }

    const postponeButtonDisabled = checkIsDisablePostponeButton()

    return (
        <>
            <Text style={[styles.title, !screenInfo.isLargeScreen && { fontSize: 13.5 }]}>
                {t('order.detail.postponeTitle')}
            </Text>
            <View style={styles.container}>
                {postponeOrderAvailableShiftValues.map((value, index) => {
                    if (value === 'custom') {
                        return (
                            <Chip
                                key={index}
                                text={t('order.detail.customTime')}
                                subText={
                                    `${customTime.hours > 0 ? `${customTime.hours} HOD ` : ``}` +
                                    `${customTime.minutes > 0 ? `${customTime.minutes} MIN` : ``}`
                                }
                                chosen={postponementTime === 'custom'}
                                onPress={() => onTimePress(value)}
                                style={!screenInfo.isLargeScreen && { width: '62%' }}
                            />
                        )
                    }
                    const disabled = checkDefaultTimeDisabled(value)
                    return (
                        <Chip
                            key={index}
                            text={`${value} hod`}
                            disabled={disabled}
                            chosen={postponementTime === value}
                            onPress={() => onTimePress(value)}
                            textStyle={{ color: disabled ? ColorEnum.GRAY_500 : undefined }}
                            style={!screenInfo.isLargeScreen && { width: '30%' }}
                        />
                    )
                })}
            </View>
            <DefaultPostponeOrderButtons
                onBack={onBackButton}
                postponeButtonDisabled={postponeButtonDisabled}
                onPostpone={onPostponeButton}
            />
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flexWrap: 'wrap',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: 12,
    },
    title: {
        fontSize: 16,
        marginBottom: 12,
        textAlign: 'center',
    },
})

export default DefaultPostponeOrderTimeSelector
