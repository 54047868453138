import { configureStore } from '@reduxjs/toolkit'

import reducer from './reducers'

const store = configureStore({ reducer })

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type ReduxStore = typeof store

export default store
