import { FC } from 'react'
import { Pressable, StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { Avatar, Text } from 'react-native-paper'

import { ColorEnum } from '../../../constants/color'
import { hexToRgba } from '../../../utils/hexToRgba'
import { horizontalScale, moderateScale } from '../../../utils/metrics'

interface IconCardProps {
    icon: string
    title: string
    subtitle: string
    onPress?: () => void
    style?: StyleProp<ViewStyle>
    iconCardID?: string
}

const IconCard: FC<IconCardProps> = ({ icon, title, subtitle, onPress, style, iconCardID }) => {
    const containerStyle = {
        width: horizontalScale(270, 376),
        padding: moderateScale(4, 8),
    }

    return (
        <Pressable
            onPress={onPress}
            style={({ pressed }) => [
                styles.container,
                containerStyle,
                pressed && styles.pressedContainer,
                style,
            ]}
            testID={iconCardID ? `${iconCardID ? `${iconCardID}-` : ''}iconCard` : undefined}
        >
            <Avatar.Icon
                icon={icon}
                size={moderateScale(40, 56, 0.2)}
                style={styles.icon}
                testID={`iconCard-${icon}Icon`}
            />
            <View>
                <Text
                    style={[styles.title, { fontSize: moderateScale(15, 18, 0.1) }]}
                    testID={
                        iconCardID
                            ? `${iconCardID ? `${iconCardID}-` : ''}iconCard-title`
                            : undefined
                    }
                >
                    {title}
                </Text>
                <Text
                    style={[styles.subtitle, { fontSize: moderateScale(13, 14, 0.02) }]}
                    testID={
                        iconCardID
                            ? `${iconCardID ? `${iconCardID}-` : ''}iconCard-subtitle`
                            : undefined
                    }
                >
                    {subtitle}
                </Text>
            </View>
        </Pressable>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: hexToRgba(ColorEnum.WHITE, 0.26),
        borderRadius: 36,
        padding: 8,
    },
    pressedContainer: {
        backgroundColor: 'rgba(255, 255, 255, 0.36)',
    },
    icon: {
        backgroundColor: ColorEnum.BRICK_700,
        marginRight: 8,
    },
    title: {
        color: ColorEnum.WHITE,
    },
    subtitle: {
        color: ColorEnum.WHITE,
        marginTop: 3,
    },
})

export default IconCard
