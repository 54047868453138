import dayjs, { ManipulateType } from 'dayjs'

import { IDeliveryTime } from '../types/order'

export const shiftDeliveryTime = (
    deliveryTime: IDeliveryTime,
    shiftValue: number,
    timeUnit: ManipulateType
): IDeliveryTime => {
    const start = dayjs(deliveryTime.start).add(shiftValue, timeUnit).format()
    const end = dayjs(deliveryTime.end).add(shiftValue, timeUnit).format()

    return {
        start,
        end,
        type: deliveryTime.type,
    }
}
