import React, { FC } from 'react'
import { StyleSheet, View } from 'react-native'
import { Text } from 'react-native-paper'

import { ColorEnum } from '../../../../constants/color'
import { isoDateToAgoHumanFormat } from '../../../../utils/formatDate'
import CustomerEvaluation from './CustomerEvaluation'

interface CustomerEvaluationsProps {
    evaluationFood?: number
    evaluationDelivery?: number
    evaluationDate?: string
}

const CustomerEvaluations: FC<CustomerEvaluationsProps> = ({
    evaluationFood,
    evaluationDelivery,
    evaluationDate,
}) => {
    return (
        <View style={styles.container}>
            {evaluationFood && (
                <CustomerEvaluation icon="silverware">{evaluationFood}</CustomerEvaluation>
            )}
            {evaluationDelivery && (
                <CustomerEvaluation icon="car">{evaluationDelivery}</CustomerEvaluation>
            )}
            {evaluationDate && (
                <Text style={styles.date}>{isoDateToAgoHumanFormat(evaluationDate)}</Text>
            )}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    date: {
        fontFamily: 'Satoshi-Regular',
        color: ColorEnum.GRAY_500,
        marginStart: 16,
    },
})

export default CustomerEvaluations
