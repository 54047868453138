import { createContext } from 'use-context-selector'

import { INotification } from '../../../types/notification'

interface INotificationContext {
    notification: INotification
}

const NotificationContext = createContext<INotificationContext>({
    notification: {} as INotification,
})

export default NotificationContext
