import { FC } from 'react'
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native'

import { ColorEnum } from '../../../constants/color'
import { horizontalScale, moderateScale } from '../../../utils/metrics'

interface CodeEntryFieldProps {
    children: string
    length: number
    style?: StyleProp<ViewStyle>
    codeEntryFieldID?: string
}

const CodeEntryField: FC<CodeEntryFieldProps> = ({ children, length, style, codeEntryFieldID }) => {
    return (
        <View style={[styles.container, style]}>
            {Array.from({ length }, (_, i) => i + 1).map((i) => (
                <View
                    key={i}
                    style={[
                        styles.rectangle,
                        { marginHorizontal: moderateScale(4, 8, 0.2) },
                        i === 1 && styles.noMarginLeft,
                        i === 6 && styles.noMarginRight,
                        { height: horizontalScale(60), width: horizontalScale(48) },
                    ]}
                >
                    <Text
                        style={[styles.rectangleText, { fontSize: moderateScale(18, 32, 0.2) }]}
                        testID={
                            codeEntryFieldID
                                ? `${
                                      codeEntryFieldID ? `${codeEntryFieldID}-` : ''
                                  }codeEntryField-${i}Sq`
                                : undefined
                        }
                    >
                        {children.slice(i - 1, i)}
                    </Text>
                </View>
            ))}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
    },
    rectangle: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: ColorEnum.WHITE,
        borderRadius: 5,
        maxHeight: 70,
        maxWidth: 56,
    },
    rectangleText: {
        color: ColorEnum.JET_800,
        fontWeight: 'bold',
    },
    noMarginLeft: {
        marginLeft: 0,
    },
    noMarginRight: {
        marginRight: 0,
    },
})

export default CodeEntryField
