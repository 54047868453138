import { FC, useEffect } from 'react'
import { StyleSheet } from 'react-native'
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated'

import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { clearSelectedOrderUuid } from '../../../redux/reducers/orders'
import { ordersSelectors } from '../../../redux/reducers/orders/selectors'
import OrderDetail from './OrderDetail'

const TabletOrderDetailContainer: FC = () => {
    const selectedOrderUuid = useAppSelector(ordersSelectors.selectedOrderUuid)
    const order = useAppSelector(ordersSelectors.orderByUuid(selectedOrderUuid ?? ''))
    const isOrdersLoaded = useAppSelector(ordersSelectors.isOrdersLoaded)

    const dispatch = useAppDispatch()

    const nonExistingOrder = !order && isOrdersLoaded

    useEffect(() => {
        if (!nonExistingOrder) return

        dispatch(clearSelectedOrderUuid())
    }, [nonExistingOrder])

    if (!selectedOrderUuid) {
        return null
    }

    return (
        <Animated.View
            key={selectedOrderUuid}
            entering={FadeIn.duration(125)}
            exiting={FadeOut.duration(125)}
            style={styles.container}
        >
            <OrderDetail uuid={selectedOrderUuid} elevation={5} />
        </Animated.View>
    )
}

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        width: '50%',
        zIndex: 1,
    },
})

export default TabletOrderDetailContainer
