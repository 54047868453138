import { AxiosError } from 'axios'

import i18n from '../translations'
import { OAuthErrorTypeEnum } from './oauth/constants'

type I18NTranslation = Parameters<typeof i18n.t>[1]

interface ApiErrorCodeTranslations {
    [code: number]: I18NTranslation
}

type OAuthErrorTypeTranslations = {
    [type in OAuthErrorTypeEnum]: I18NTranslation
}

interface StatusCodeTranslations {
    [status: number]: I18NTranslation
}

export default class ApiErrorTranslatorService {
    static translateMasterAPIError(
        error: unknown,
        apiErrorCodeTranslations?: ApiErrorCodeTranslations,
        statusCodeTranslations?: StatusCodeTranslations
    ) {
        if (
            !(error instanceof AxiosError) ||
            !error.response ||
            !(apiErrorCodeTranslations || statusCodeTranslations)
        ) {
            return i18n.t('unknownError')
        }

        return ApiErrorTranslatorService.translateError(
            error.response.data?.data?.error.code,
            error.response.status,
            apiErrorCodeTranslations,
            statusCodeTranslations
        )
    }

    static translateTerminalAPIError(
        error: unknown,
        apiErrorCodeTranslations?: ApiErrorCodeTranslations,
        statusCodeTranslations?: StatusCodeTranslations
    ) {
        if (
            !(error instanceof AxiosError) ||
            !error.response ||
            !(apiErrorCodeTranslations || statusCodeTranslations)
        ) {
            return i18n.t('unknownError')
        }

        return ApiErrorTranslatorService.translateError(
            error.response.data?.error.code,
            error.response.status,
            apiErrorCodeTranslations,
            statusCodeTranslations
        )
    }

    static translateOAuthAPIError(
        error: unknown,
        oauthErrorTypeTranslations?: OAuthErrorTypeTranslations,
        statusCodeTranslations?: StatusCodeTranslations
    ) {
        if (
            !(error instanceof AxiosError) ||
            !error.response ||
            !(oauthErrorTypeTranslations || statusCodeTranslations)
        ) {
            return i18n.t('unknownError')
        }

        return ApiErrorTranslatorService.translateError(
            error.response.data?.error,
            error.response.status,
            oauthErrorTypeTranslations,
            statusCodeTranslations
        )
    }

    private static translateError(
        marker: number,
        statusCode: number,
        errorMarkers?: Record<number, I18NTranslation>,
        statusCodeTranslations?: StatusCodeTranslations
    ): string

    private static translateError(
        marker: string,
        statusCode: number,
        errorMarkers?: Record<string, I18NTranslation>,
        statusCodeTranslations?: StatusCodeTranslations
    ): string

    private static translateError(
        marker: number | string,
        statusCode: number,
        errorMarkers?: Record<number | string, I18NTranslation>,
        statusCodeTranslations?: StatusCodeTranslations
    ) {
        if (errorMarkers && errorMarkers[marker]) {
            // @ts-ignore
            return i18n.t(errorMarkers[marker])
        }

        if (statusCode && statusCodeTranslations && statusCodeTranslations[statusCode]) {
            // @ts-ignore
            return i18n.t(statusCodeTranslations[statusCode])
        }

        return i18n.t('unknownError')
    }
}
