import { AxiosError } from 'axios'

import { OAuthErrorTypeEnum } from '../api/oauth/constants'

export const getOAuthErrorType = (error: unknown) => {
    if (
        error instanceof AxiosError &&
        error.response &&
        error.response.data !== null &&
        typeof error.response.data === 'object' &&
        'error' in error.response.data
    ) {
        return error.response.data.error as OAuthErrorTypeEnum
    }
}
