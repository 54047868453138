import { FC, ReactNode } from 'react'
import { StyleSheet, View } from 'react-native'

import { useAppSelector } from '../hooks/redux'
import { selectScreenInfo } from '../redux/reducers/screenInfo'
import SideBar from './SideBar/SideBar'
import TopBar from './TopBar/TopBar'

interface LayoutProps {
    children: ReactNode
}

const Layout: FC<LayoutProps> = ({ children }) => {
    const screenInfo = useAppSelector(selectScreenInfo)

    return (
        <View style={styles.container} testID="layout">
            <TopBar />
            <View style={styles.main}>
                {screenInfo.isLargeScreen && <SideBar />}
                <View style={styles.content}>{children}</View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    main: {
        flexDirection: 'row',
        flex: 1,
    },
    content: {
        flex: 1,
    },
})

export default Layout
