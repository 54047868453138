import { FC } from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { removeRecentNotification } from '../../redux/reducers/notifications'
import { selectScreenInfo } from '../../redux/reducers/screenInfo'
import { INotification } from '../../types/notification'
import Notification from '../UI/Notification/Notification'

interface TopBarNotificationProps {
    notification: INotification
}

const TopBarNotification: FC<TopBarNotificationProps> = ({ notification }) => {
    const screenInfo = useAppSelector(selectScreenInfo)

    const dispatch = useAppDispatch()

    const handleClose = () => {
        dispatch(removeRecentNotification())
    }

    const style: StyleProp<ViewStyle> = screenInfo.isLargeScreen
        ? { maxWidth: 360 }
        : {
              marginHorizontal: 16,
              position: 'absolute',
              left: 0,
              right: 0,
              zIndex: 1,
          }

    return (
        <Notification
            notification={notification}
            mode="rounded"
            ProgressBar={<Notification.ProgressBar onProgressEnd={handleClose} />}
            CloseButton={<Notification.CloseButton onClose={handleClose} />}
            style={style}
        />
    )
}

export default TopBarNotification
