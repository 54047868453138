import { FC } from 'react'
import { StyleProp, StyleSheet, TextStyle, View, ViewStyle } from 'react-native'
import { Text } from 'react-native-paper'

import { ColorEnum } from '../../constants/color'

interface InfoMessageProps {
    children: string
    textStyle?: StyleProp<TextStyle>
    style?: StyleProp<ViewStyle>
}

const InfoMessage: FC<InfoMessageProps> = ({ children, textStyle, style }) => {
    return (
        <View style={[styles.container, style]}>
            <Text style={[styles.text, textStyle]}>{children}</Text>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    text: {
        color: ColorEnum.JET_800,
    },
})

export default InfoMessage
