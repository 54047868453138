import { createAsyncThunk } from '@reduxjs/toolkit'
import { HttpStatusCode } from 'axios'

import { getAuth, signInWithCustomToken } from '../../../../firebase/auth'
import ApiErrorTranslatorService from '../../../api/apiErrorTranslator'
import AuthAPI from '../../../api/auth/auth'
import { AuthAPIErrorCodeEnum } from '../../../api/auth/constants'
import LicenceAPI from '../../../api/licence/licence'
import { MasterAPIErrorCodeEnum } from '../../../api/master/constants'
import MasterAPI from '../../../api/master/master'
import { ILicenceLookupData } from '../../../api/master/types'
import { OAuthErrorTypeEnum } from '../../../api/oauth/constants'
import OAuthAPI from '../../../api/oauth/oauth'
import { setAllApisHostUrl } from '../../../api/setAllApisHostUrl'
import TerminalAPI from '../../../api/terminal/terminal'
import { authDeviceAuthorizationCodeFieldLength } from '../../../constants/codeEntryField'
import AuthStorageService from '../../../services/authStorage/authStorage'
import { IProfile } from '../../../types/profile'
import { getOAuthErrorType } from '../../../utils/getOAuthErrorType'
import { logError } from '../../../utils/logError'
import { setAppOverlay } from '../app'
import { setErrorDialog } from '../dialog'
import { AuthState } from './index'

interface ThunkApiConfig<T extends string | void = void> {
    state: { auth: AuthState }
    rejectValue: T | void
}

export const lookupAuthLicence = createAsyncThunk<ILicenceLookupData, void, ThunkApiConfig>(
    'auth/lookupAuthLicence',
    async (_, { getState, dispatch, rejectWithValue }) => {
        try {
            dispatch(setAppOverlay(true))

            const licenceId = getState().auth.licenceId

            let data

            try {
                data = await MasterAPI.licenceLookup(licenceId)
            } catch (e) {
                const message = ApiErrorTranslatorService.translateMasterAPIError(
                    e,
                    {
                        [MasterAPIErrorCodeEnum.EntityNotFound]:
                            'licenceVerificationScreenEntityNotFound',
                    },
                    {
                        [HttpStatusCode.InternalServerError]: 'serverError',
                    }
                )

                dispatch(setErrorDialog(message))

                return rejectWithValue()
            }

            await AuthStorageService.saveHostUrl(data.url)

            setAllApisHostUrl(data.url)

            return data
        } catch (e) {
            logError(e)

            return rejectWithValue()
        } finally {
            dispatch(setAppOverlay(false))
        }
    }
)

export const fetchAuthDeviceId = createAsyncThunk<string, void, ThunkApiConfig>(
    'auth/fetchAuthDeviceId',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setAppOverlay(true))

            let deviceId

            try {
                deviceId = await AuthAPI.requestDeviceAuthorization()
            } catch (e) {
                const message = ApiErrorTranslatorService.translateTerminalAPIError(e, {
                    [HttpStatusCode.InternalServerError]: 'serverError',
                })

                dispatch(setErrorDialog(message))

                await AuthStorageService.removeHostUrl()
                await AuthStorageService.removeDeviceId()

                return rejectWithValue()
            }

            await AuthStorageService.saveDeviceId(deviceId)

            return deviceId
        } catch (e) {
            logError(e)

            return rejectWithValue()
        } finally {
            dispatch(setAppOverlay(false))
        }
    }
)

export const fetchAuthDeviceToken = createAsyncThunk<string, void, ThunkApiConfig>(
    'auth/fetchAuthDeviceToken',
    async (_, { getState, dispatch, rejectWithValue }) => {
        try {
            dispatch(setAppOverlay(true))

            const { deviceId, deviceCode } = getState().auth

            if (!deviceId) {
                throw new Error('deviceId not provided')
            }

            if (deviceCode.length !== authDeviceAuthorizationCodeFieldLength) {
                throw new Error('Six number device authorization code is not provided')
            }

            let deviceToken

            try {
                deviceToken = await AuthAPI.authorizeDevice(deviceId, deviceCode)
            } catch (e) {
                const message = ApiErrorTranslatorService.translateTerminalAPIError(
                    e,
                    {
                        [AuthAPIErrorCodeEnum.NotGranted]:
                            'deviceAuthorizationScreenErrorNotGranted',
                    },
                    {
                        [HttpStatusCode.InternalServerError]: 'serverError',
                    }
                )

                dispatch(setErrorDialog(message))

                await AuthStorageService.removeDeviceAuth()

                return rejectWithValue()
            }

            await AuthStorageService.saveDeviceToken(deviceToken)

            LicenceAPI.setAccessToken(deviceToken)

            return deviceToken
        } catch (e) {
            logError(e)

            return rejectWithValue()
        } finally {
            dispatch(setAppOverlay(false))
        }
    }
)

export type IRetainedKeys = ['licenceId'] | undefined

export const clearAuth = createAsyncThunk<IRetainedKeys, IRetainedKeys, ThunkApiConfig>(
    'auth/clearAuth',
    async (retainedKeys, { rejectWithValue }) => {
        try {
            await AuthStorageService.removeAuth()

            return retainedKeys
        } catch (e) {
            logError(e)

            return rejectWithValue()
        }
    }
)

export const fetchAuthProfiles = createAsyncThunk<IProfile[], void, ThunkApiConfig>(
    'auth/fetchAuthProfiles',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setAppOverlay(true))

            let profiles

            try {
                profiles = await LicenceAPI.fetchProfiles()
            } catch (e) {
                const message = ApiErrorTranslatorService.translateTerminalAPIError(e, {
                    [HttpStatusCode.InternalServerError]: 'serverError',
                })

                dispatch(setErrorDialog(message))

                return rejectWithValue()
            }

            await AuthStorageService.saveProfiles(profiles)

            return profiles
        } catch (e) {
            logError(e)

            return rejectWithValue()
        } finally {
            dispatch(setAppOverlay(false))
        }
    }
)

export const setAuthProfile = createAsyncThunk<IProfile, IProfile, ThunkApiConfig>(
    'auth/setAuthProfile',
    async (profile, { rejectWithValue }) => {
        try {
            await AuthStorageService.saveProfile(profile)

            return profile
        } catch (e) {
            logError(e)

            return rejectWithValue()
        }
    }
)

export const fetchAuthTokens = createAsyncThunk<void, void, ThunkApiConfig<OAuthErrorTypeEnum>>(
    'auth/fetchAuthTokens',
    async (_, { getState, dispatch, rejectWithValue }) => {
        try {
            dispatch(setAppOverlay(true))

            const { deviceId, deviceToken, profile, pin } = getState().auth

            if (!deviceId || !deviceToken) {
                throw new Error('Device id or token is not provided')
            }

            if (!profile || !pin) {
                throw new Error('Profile or pin is not provided')
            }

            let tokens

            try {
                tokens = await OAuthAPI.fetchTokens(deviceId, deviceToken, profile.uuid, pin)
            } catch (e) {
                const message = ApiErrorTranslatorService.translateOAuthAPIError(e, {
                    [OAuthErrorTypeEnum.InvalidClient]: 'profilePinScreenInvalidClientError',
                    [OAuthErrorTypeEnum.InvalidGrant]: 'profilePinScreenInvalidGrantError',
                })

                dispatch(setErrorDialog(message))

                const errorType = getOAuthErrorType(e)

                if (errorType) {
                    if (errorType === OAuthErrorTypeEnum.InvalidClient) {
                        await AuthStorageService.removeAuth()
                    } else if (errorType === OAuthErrorTypeEnum.InvalidGrant) {
                        const { pin, prevSubmittedPin } = getState().auth

                        if (pin === prevSubmittedPin) {
                            await AuthStorageService.removeProfile()
                            await AuthStorageService.removeProfiles()
                        }
                    }
                }

                return rejectWithValue()
            }

            await AuthStorageService.saveTokens(tokens)

            TerminalAPI.setAuthorizationToken(tokens.accessToken)

            const fbCustomToken = await TerminalAPI.fetchFirebaseCustomToken()

            const auth = getAuth()

            await signInWithCustomToken(auth, fbCustomToken)
        } catch (e) {
            logError(e)

            return rejectWithValue()
        } finally {
            dispatch(setAppOverlay(false))
        }
    }
)

export const logoutAuthUser = createAsyncThunk<void, void, ThunkApiConfig>(
    'auth/logoutAuthUser',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            dispatch(setAppOverlay(true))

            await AuthStorageService.removeTokens()
            await AuthStorageService.removeProfile()

            dispatch(setAppOverlay(false))
        } catch (e) {
            logError(e)

            return rejectWithValue()
        }
    }
)
