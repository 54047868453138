export enum ColorEnum {
    BLUE = '#1E429E',
    BLUE_500 = '#06AED5',
    BRICK_200 = '#FBB1BE',
    BRICK_500 = '#E90C31',
    BRICK_600 = '#C00A27',
    BRICK_700 = '#9B0821',
    EMERALD_100 = '#DFF7EB',
    EMERALD_200 = '#BEEED6',
    EMERALD_500 = '#33C47C',
    EMERALD_700 = '#26925C',
    GRAY_100 = '#F9F9F9',
    GRAY_200 = '#EBEBEB',
    GRAY_300 = '#CCCCCC',
    GRAY_500 = '#7A7A7A',
    GRAY_600 = '#555555',
    GRAY_900 = '#231F20',
    JET_500 = '#778592',
    JET_600 = '#5B6671',
    JET_800 = '#212529',
    RED_500 = '#FF0000',
    VIOLET_100 = '#F5F3F5',
    VIOLET_200 = '#DAD3DA',
    WHITE = '#FFFFFF',
    YELLOW_200 = '#FFEEC2',
    YELLOW_400 = '#FFCB47',
    YELLOW_500 = '#FFBA08',
    YELLOW_600 = '#E0A100',
}
