import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootState } from '../../store'

interface AppState {
    overlay: boolean
    gridMenuOpen: boolean
}

const initialState: AppState = {
    overlay: false,
    gridMenuOpen: false,
}

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setAppOverlay: (state, { payload: overlay }: PayloadAction<boolean>) => {
            state.overlay = overlay
        },
        openGridMenu: (state) => void (state.gridMenuOpen = true),
        closeGridMenu: (state) => void (state.gridMenuOpen = false),
    },
})

export const { setAppOverlay, openGridMenu, closeGridMenu } = appSlice.actions

export const appSelectors = {
    overlay: ({ app }: RootState) => app.overlay,
    gridMenuOpen: ({ app }: RootState) => app.gridMenuOpen,
}

export default appSlice.reducer
