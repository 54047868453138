import { FC } from 'react'
import { StyleSheet } from 'react-native'
import { IconButton } from 'react-native-paper'

import { ColorEnum } from '../../../constants/color'

interface CloseButtonProps {
    onClose: () => void
}

const CloseButton: FC<CloseButtonProps> = ({ onClose }) => {
    return (
        <IconButton
            icon="close"
            iconColor={ColorEnum.BRICK_600}
            onPress={onClose}
            style={styles.button}
        />
    )
}

const styles = StyleSheet.create({
    button: {
        margin: 0,
        marginRight: 8,
    },
})

export default CloseButton
