import { FC } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { Text } from 'react-native-paper'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'

import { ColorEnum } from '../../../../constants/color'
import { IPrice } from '../../../../types/order'
import { formatPrice } from '../../../../utils/priceHelper'

interface ToppingProps {
    name: string
    quantity: number
    price: IPrice
    style?: StyleProp<ViewStyle>
}

const Topping: FC<ToppingProps> = ({ name, quantity, price, style }) => {
    return (
        <View style={[styles.container, style]}>
            <View style={styles.iconWrapper}>
                <Icon name="checkbox-blank" color={ColorEnum.EMERALD_200} size={24} />
                <Icon
                    name="plus"
                    color={ColorEnum.EMERALD_700}
                    style={styles.iconForeground}
                    size={16}
                />
            </View>
            <Text style={styles.name}>
                {name} ({quantity}x)
            </Text>

            <Text style={styles.price}>
                {Number(price.amount) > 0 ? '+' : ''}
                {formatPrice(price.amount, price.currency)}
            </Text>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    iconWrapper: {
        marginEnd: 4,
    },
    name: {
        fontFamily: 'Satoshi-Regular',
        fontSize: 12,
        fontWeight: '400',
        color: ColorEnum.GRAY_900,
    },
    price: {
        fontFamily: 'Satoshi-Regular',
        fontSize: 12,
        fontWeight: '500',
        color: ColorEnum.GRAY_900,
        marginStart: 'auto',
    },
    iconForeground: {
        position: 'absolute',
        left: 4,
        top: 4,
    },
})

export default Topping
