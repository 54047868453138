import { Dimensions } from 'react-native'

const maxScreenHeight = 1152
const maxScreenWidth = 2048

const guidelineBaseHeight = 812
const guidelineBaseWidth = 375

const verticalScale = (size: number, maxSize?: number) => {
    const scaledSize =
        (Math.min(Dimensions.get('window').height, maxScreenHeight) / guidelineBaseHeight) * size

    return maxSize ? Math.min(scaledSize, maxSize) : scaledSize
}

const horizontalScale = (size: number, maxSize?: number) => {
    const scaledSize =
        (Math.min(Dimensions.get('window').width, maxScreenWidth) / guidelineBaseWidth) * size

    return maxSize ? Math.min(scaledSize, maxSize) : scaledSize
}

const moderateScale = (size: number, maxSize?: number, factor = 0.5) => {
    const scaledSize = size + (horizontalScale(size) - size) * factor

    return maxSize ? Math.min(scaledSize, maxSize) : scaledSize
}

export { verticalScale, horizontalScale, moderateScale }
