import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Dimensions, ScaledSize } from 'react-native'

import { largeScreenMinWidth } from '../../../constants/resolution'
import { RootState } from '../../store'

interface IAdditionalScreenInfo {
    isPortrait: boolean
    isLargeScreen: boolean
}

type ScreenInfoState = ScaledSize & IAdditionalScreenInfo

const scaledSize = Dimensions.get('window')

const getAdditionalScreenInfo = (scaledSize: ScaledSize) => ({
    isPortrait: scaledSize.width < scaledSize.height,
    isLargeScreen: scaledSize.width >= largeScreenMinWidth,
})

const initialState: ScreenInfoState = {
    ...scaledSize,
    ...getAdditionalScreenInfo(scaledSize),
}

const screenInfoSlice = createSlice({
    name: 'screenInfo',
    initialState,
    reducers: {
        setScreenInfo: (state, { payload: scaledSize }: PayloadAction<ScaledSize>) =>
            (state = {
                ...scaledSize,
                ...getAdditionalScreenInfo(scaledSize),
            }),
    },
})

export const { setScreenInfo } = screenInfoSlice.actions

export const selectScreenInfo = ({ screenInfo }: RootState) => screenInfo

export default screenInfoSlice.reducer
