import { useEffect } from 'react'

import { NotificationTypeEnum } from '../constants/notificationType'
import { setNotifications } from '../redux/reducers/notifications'
import { useAppDispatch } from './redux'

export const useNotificationsConnect = () => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (process.env.NODE_ENV !== 'development') {
            return
        }

        dispatch(
            setNotifications([
                {
                    id: '1',
                    type: NotificationTypeEnum.NewOrder,
                    name: 'Nová objednávka',
                    text: 'Rozvoz - Mánesova 83',
                    created: '1689152020',
                },
                {
                    id: '2',
                    type: NotificationTypeEnum.NewReservation,
                    name: 'Nová rezervace',
                    text: 'Stůl pro 4 osoby od 16:00',
                    created: '1584151055',
                },
                {
                    id: '3',
                    type: NotificationTypeEnum.NewOrder,
                    name: 'Nová objednávka',
                    text: 'Osobní odběr Novák 13:00',
                    created: '1584151059',
                },
            ])
        )
    }, [])
}
