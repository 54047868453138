import { createSelector } from 'reselect'

import {
    authDeviceAuthorizationCodeFieldLength,
    authProfilePinCodeFieldLength,
} from '../../../constants/codeEntryField'
import { RootState } from '../../store'

const sliceSelectors = {
    licenceId: ({ auth }: RootState) => auth.licenceId,
    hostUrl: ({ auth }: RootState) => auth.hostUrl,
    licenceName: ({ auth }: RootState) => auth.licenceName,
    deviceId: ({ auth }: RootState) => auth.deviceId,
    deviceCode: ({ auth }: RootState) => auth.deviceCode,
    deviceToken: ({ auth }: RootState) => auth.deviceToken,
    profiles: ({ auth }: RootState) => auth.profiles,
    profile: ({ auth }: RootState) => auth.profile,
    pin: ({ auth }: RootState) => auth.pin,
    hasTokens: ({ auth }: RootState) => auth.hasTokens,
}

const isLicenceIdEmpty = createSelector([sliceSelectors.licenceId], (licenceId) => {
    return !licenceId
})

const deviceCodeState = createSelector([sliceSelectors.deviceCode], (deviceCode) => {
    return {
        empty: !deviceCode,
        fulfilled: deviceCode.length === authDeviceAuthorizationCodeFieldLength,
    }
})

const deviceAuth = createSelector(
    [sliceSelectors.hostUrl, sliceSelectors.deviceId, sliceSelectors.deviceToken],
    (hostUrl, deviceId, deviceToken) => {
        if (hostUrl && deviceId && deviceToken) {
            return {
                hostUrl,
                deviceId,
                deviceToken,
            }
        }

        return null
    }
)

const pinState = createSelector([sliceSelectors.pin], (pin) => {
    return { empty: !pin, fulfilled: pin.length === authProfilePinCodeFieldLength }
})

export const isCompleted = createSelector(
    [
        sliceSelectors.hostUrl,
        sliceSelectors.deviceId,
        sliceSelectors.deviceToken,
        sliceSelectors.profile,
        sliceSelectors.hasTokens,
    ],
    (hostUrl, deviceId, deviceToken, profile, hasTokens) => {
        return hostUrl && deviceId && deviceToken && profile && hasTokens
    }
)

export const authSelectors = {
    ...sliceSelectors,
    isLicenceIdEmpty,
    deviceCodeState,
    deviceAuth,
    pinState,
    isCompleted,
}
