import { FC } from 'react'
import { StyleSheet, View } from 'react-native'

import SVG from '../../../assets/images/adaptee-gastro-logo.svg'
import { isWeb } from '../../constants/environment'
import { useAppSelector } from '../../hooks/redux'
import { selectScreenInfo } from '../../redux/reducers/screenInfo'

const TopBarLogo: FC = () => {
    const screenInfo = useAppSelector(selectScreenInfo)

    const logoProps = isWeb
        ? { transform: 'scale(0.60)' }
        : { height: screenInfo.isLargeScreen ? '60%' : '100%' }

    return (
        <View style={styles.container}>
            <SVG {...logoProps} />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: -1,
    },
})

export default TopBarLogo
