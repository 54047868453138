import { FC } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { Text, TouchableRipple } from 'react-native-paper'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'

import { ColorEnum } from '../../constants/color'
import { hexToRgba } from '../../utils/hexToRgba'
import { moderateScale } from '../../utils/metrics'

interface GridMenuItemProps {
    name: string
    icon: string
    backgroundColor: ColorEnum
    badge?: number
    onPress?: () => void
    style?: StyleProp<ViewStyle>
}

const GridMenuItem: FC<GridMenuItemProps> = ({
    name,
    icon,
    backgroundColor,
    badge,
    onPress,
    style,
}) => {
    return (
        <TouchableRipple
            borderless
            rippleColor={hexToRgba(ColorEnum.JET_800)}
            onPress={onPress}
            style={[style, styles.container, { backgroundColor: backgroundColor }]}
        >
            <>
                {badge !== undefined && badge > 0 && (
                    <View style={styles.badge}>
                        <Text
                            style={[
                                styles.badgeText,
                                { color: backgroundColor, fontSize: moderateScale(14, 18, 0.2) },
                            ]}
                        >
                            {badge}
                        </Text>
                    </View>
                )}
                <Icon name={icon} color={ColorEnum.WHITE} size={moderateScale(40, 60, 0.15)} />
                <Text style={[styles.text, { fontSize: moderateScale(14, 16, 0.2) }]}>{name}</Text>
            </>
        </TouchableRipple>
    )
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: ColorEnum.JET_800,
        padding: 12,
        width: 128,
        height: 128,
        borderRadius: 8,
        alignContent: 'space-between',
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        color: ColorEnum.WHITE,
        textTransform: 'uppercase',
        marginTop: 12,
        letterSpacing: 0.05,
    },
    badge: {
        position: 'absolute',
        top: 4,
        end: 4,
        backgroundColor: ColorEnum.WHITE,
        minWidth: 24,
        height: 24,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 12,
        paddingHorizontal: 4,
    },
    badgeText: {
        fontFamily: 'Satoshi-Bold',
    },
})

export default GridMenuItem
