import { FC } from 'react'
import { StyleSheet, View } from 'react-native'

import { ColorEnum } from '../../constants/color'
import { useAppSelector } from '../../hooks/redux'
import { notificationSelectors } from '../../redux/reducers/notifications'
import TopBarLogoOrNotification from './TopBarLogoOrNotification'
import TopBarMenuIcon from './TopBarMenuIcon'
import TopBarToolbar from './TopBarToolbar'

const TopBarContentMobile: FC = () => {
    const recentNotification = useAppSelector(notificationSelectors.recentNotification)

    return (
        <View style={styles.container}>
            {recentNotification && <View style={styles.brickView} />}
            <TopBarMenuIcon />
            <TopBarLogoOrNotification />
            <TopBarToolbar />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    brickView: {
        backgroundColor: ColorEnum.BRICK_700,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1,
    },
})

export default TopBarContentMobile
