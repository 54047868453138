import { combineReducers } from '@reduxjs/toolkit'

import app from './app'
import auth from './auth'
import dialog from './dialog'
import licence from './licence'
import notifications from './notifications'
import orders from './orders'
import screenInfo from './screenInfo'

export default combineReducers({ app, auth, dialog, licence, notifications, orders, screenInfo })
