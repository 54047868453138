import { useEffect, useRef } from 'react'

import {
    get,
    getDatabase,
    ref as getRef,
    onChildAdded,
    onChildChanged,
    onChildRemoved,
} from '../../../firebase/database'
import { licenceSelectors } from '../../redux/reducers/licence'
import {
    addOrder,
    clearOrders,
    removeOrder,
    setOrders,
    updateOrder,
} from '../../redux/reducers/orders'
import { useAppDispatch, useAppSelector } from '../redux'

export const useFirebaseOrdersConnect = () => {
    const licence = useAppSelector(licenceSelectors.licence)

    const dispatch = useAppDispatch()

    const unsubscribeOrderRemovedRef = useRef<(() => void) | null>(null)
    const unsubscribeOrderChangedRef = useRef<(() => void) | null>(null)
    const unsubscribeOrderAddedRef = useRef<(() => void) | null>(null)

    const unsubscribeOrders = () => {
        if (unsubscribeOrderRemovedRef.current) {
            unsubscribeOrderRemovedRef.current()
        }

        if (unsubscribeOrderChangedRef.current) {
            unsubscribeOrderChangedRef.current()
        }

        if (unsubscribeOrderAddedRef.current) {
            unsubscribeOrderAddedRef.current()
        }

        dispatch(clearOrders())
    }

    useEffect(() => {
        // eslint-disable-next-line no-extra-semi
        ;(async () => {
            if (!licence) return

            const db = getDatabase()

            const ref = getRef(db, `licences/${licence.token}/serverData/orders`)

            unsubscribeOrderRemovedRef.current = onChildRemoved(ref, (snapshot) => {
                if (!snapshot.key) {
                    throw new Error("Snapshot key doesn't exist")
                }

                dispatch(removeOrder(snapshot.key))
            })

            unsubscribeOrderChangedRef.current = onChildChanged(ref, (snapshot) => {
                if (!snapshot.key) {
                    throw new Error("Snapshot key doesn't exist")
                }

                dispatch(updateOrder({ uuid: snapshot.key, order: snapshot.val() }))
            })

            const snapshot = await get(ref)

            dispatch(setOrders(snapshot.val()))

            unsubscribeOrderAddedRef.current = onChildAdded(ref, (snapshot) => {
                if (!snapshot.key) {
                    throw new Error("Snapshot key doesn't exist")
                }

                dispatch(addOrder({ uuid: snapshot.key, order: snapshot.val() }))
            })
        })()

        return unsubscribeOrders
    }, [licence])
}
