import { FC } from 'react'
import { StyleSheet, View } from 'react-native'
import { IconButton } from 'react-native-paper'

import { ColorEnum } from '../../constants/color'
import { topBarMarginHorizontalSide } from '../../constants/layout'
import { horizontalScale, moderateScale } from '../../utils/metrics'
import AppNotificationCenter from '../NotificationCenter/AppNotificationCenter'

const TopBarToolbar: FC = () => {
    const marginHorizontalSide = horizontalScale(topBarMarginHorizontalSide)
    const iconSize = moderateScale(21, 26, 0.2)

    return (
        <View style={[styles.container, { marginRight: marginHorizontalSide }]}>
            <IconButton
                icon="plus"
                iconColor={ColorEnum.WHITE}
                size={iconSize}
                style={styles.icon}
            />
            <AppNotificationCenter />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
    },
    icon: {
        margin: 0,
    },
})

export default TopBarToolbar
