import React, { FC } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

import { ColorEnum } from '../../../../constants/color'

interface ProductsSeparatorProps {
    style?: StyleProp<ViewStyle>
}

const ItemsSeparator: FC<ProductsSeparatorProps> = ({ style }) => {
    return <View style={[styles.container, style]} />
}

const styles = StyleSheet.create({
    container: {
        height: 1,
        width: '100%',
        backgroundColor: ColorEnum.JET_500,
    },
})

export default ItemsSeparator
