import { StackActions, useNavigation } from '@react-navigation/native'
import { FC, useEffect } from 'react'

import { OrdersModuleScreenEnum } from '../../../constants/screen'
import { useAppSelector } from '../../../hooks/redux'
import { ordersSelectors } from '../../../redux/reducers/orders/selectors'
import { OrderDetailProps } from '../../../types/navigation'
import OrderDetail from '../../OrdersModule/OrderDetail/OrderDetail'

const MobileOrderDetailScreen: FC<OrderDetailProps> = ({ route }) => {
    const order = useAppSelector(ordersSelectors.orderByUuid(route.params.uuid))
    const isOrdersLoaded = useAppSelector(ordersSelectors.isOrdersLoaded)

    const navigation = useNavigation()

    const nonExistingOrder = !order && isOrdersLoaded

    useEffect(() => {
        if (!nonExistingOrder) return

        navigation.dispatch(StackActions.replace(OrdersModuleScreenEnum.Tabs))
    }, [nonExistingOrder])

    return <OrderDetail uuid={route.params.uuid} />
}

export default MobileOrderDetailScreen
