import { FC } from 'react'
import { StyleSheet, View } from 'react-native'

import OrdersTabs from '../navigations/orders-module/OrdersTabs'
import MapScreen from '../screens/orders-module/MapScreen'
import TabletOrderDetailContainer from './OrderDetail/TabletOrderDetailContainer'

const TabletOrderModuleContainer: FC = () => {
    return (
        <View style={styles.container}>
            <OrdersTabs />
            <TabletOrderDetailContainer />
            <MapScreen />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
    },
})

export default TabletOrderModuleContainer
