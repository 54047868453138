import AsyncStorage from '@react-native-async-storage/async-storage'
import * as SecureStore from 'expo-secure-store'

import { ITokenResponse } from '../../api/oauth/types'
import { isWeb } from '../../constants/environment'
import { AsyncStorageKeyEnum, SecureStorageKeyEnum } from '../../constants/storage'
import { IProfile } from '../../types/profile'
import { IAuthDataStorageService } from './types'

class AsyncAuthStorageService implements IAuthDataStorageService {
    async saveHostUrl(hostUrl: string) {
        await AsyncStorage.setItem(AsyncStorageKeyEnum.ApiHostUrl, hostUrl)
    }

    async getHostUrl() {
        return await AsyncStorage.getItem(AsyncStorageKeyEnum.ApiHostUrl)
    }

    async removeHostUrl() {
        await AsyncStorage.removeItem(AsyncStorageKeyEnum.ApiHostUrl)
    }

    async saveDeviceId(deviceId: string) {
        await AsyncStorage.setItem(AsyncStorageKeyEnum.DeviceId, deviceId)
    }

    async getDeviceId() {
        return await AsyncStorage.getItem(AsyncStorageKeyEnum.DeviceId)
    }

    async removeDeviceId() {
        await AsyncStorage.removeItem(AsyncStorageKeyEnum.DeviceId)
    }

    async saveDeviceToken(deviceToken: string) {
        if (isWeb) {
            await AsyncStorage.setItem(AsyncStorageKeyEnum.DeviceToken, deviceToken)

            return
        }

        await SecureStore.setItemAsync(SecureStorageKeyEnum.DeviceToken, deviceToken)
    }

    async getDeviceToken() {
        if (isWeb) {
            return await AsyncStorage.getItem(AsyncStorageKeyEnum.DeviceToken)
        }

        return await SecureStore.getItemAsync(SecureStorageKeyEnum.DeviceToken)
    }

    async removeDeviceToken() {
        if (isWeb) {
            await AsyncStorage.removeItem(AsyncStorageKeyEnum.DeviceToken)

            return
        }

        await SecureStore.deleteItemAsync(SecureStorageKeyEnum.DeviceToken)
    }

    async getDeviceAuth() {
        const hostUrl = await this.getHostUrl()
        const deviceId = await this.getDeviceId()
        const deviceToken = await this.getDeviceToken()

        if (!hostUrl || !deviceId || !deviceToken) return null

        return {
            hostUrl,
            deviceId,
            deviceToken,
        }
    }

    async removeDeviceAuth() {
        await Promise.allSettled([
            this.removeHostUrl(),
            this.removeDeviceId(),
            this.removeDeviceToken(),
        ])
    }

    async saveProfiles(profiles: IProfile[]) {
        await AsyncStorage.setItem(AsyncStorageKeyEnum.Profiles, JSON.stringify(profiles))
    }

    async getProfiles() {
        const json = await AsyncStorage.getItem(AsyncStorageKeyEnum.Profiles)

        if (!json) {
            return null
        }

        return JSON.parse(json) as IProfile[]
    }

    async removeProfiles() {
        await AsyncStorage.removeItem(AsyncStorageKeyEnum.Profiles)
    }

    async saveProfile(profile: IProfile) {
        await AsyncStorage.setItem(AsyncStorageKeyEnum.Profile, JSON.stringify(profile))
    }

    async getProfile() {
        const json = await AsyncStorage.getItem(AsyncStorageKeyEnum.Profile)

        if (!json) {
            return null
        }

        return JSON.parse(json) as IProfile
    }

    async removeProfile() {
        await AsyncStorage.removeItem(AsyncStorageKeyEnum.Profile)
    }

    async saveTokens(tokens: ITokenResponse) {
        const tokensStr = JSON.stringify(tokens)

        if (isWeb) {
            await AsyncStorage.setItem(AsyncStorageKeyEnum.OAuthTokens, tokensStr)

            return
        }

        await SecureStore.setItemAsync(SecureStorageKeyEnum.OAuthTokens, tokensStr)
    }

    async removeTokens() {
        if (isWeb) {
            await AsyncStorage.removeItem(AsyncStorageKeyEnum.OAuthTokens)

            return
        }

        await SecureStore.deleteItemAsync(SecureStorageKeyEnum.OAuthTokens)
    }

    async getTokens() {
        try {
            let json

            if (isWeb) {
                json = await AsyncStorage.getItem(AsyncStorageKeyEnum.OAuthTokens)
            } else {
                json = await SecureStore.getItemAsync(SecureStorageKeyEnum.OAuthTokens)
            }

            if (!json) return null

            const tokens = JSON.parse(json)

            if (
                !(
                    'accessToken' in tokens &&
                    'refreshToken' in tokens &&
                    'expiresIn' in tokens &&
                    'issuedAt' in tokens
                )
            )
                return null

            return tokens
        } catch (e) {
            await this.removeTokens()
        }
    }

    async removeAuth() {
        await Promise.allSettled([
            this.removeDeviceAuth(),
            this.removeProfiles(),
            this.removeProfile(),
            this.removeTokens(),
        ])
    }
}

export default new AsyncAuthStorageService()
