import dayjs from 'dayjs'

import { DeliveryTimeTypeEnum } from '../constants/order'
import i18n from '../translations'
import { IDeliveryTime } from '../types/order'

export const formatDeliveryTime = (time: IDeliveryTime): string => {
    const format = 'HH:mm'

    const formattedEnd = dayjs(time.end).format(format)
    const formattedStart = dayjs(time.start).format(format)

    switch (time.type) {
        case DeliveryTimeTypeEnum.Asap:
            return i18n.t('order.deliveryTime.asap', { time: formattedEnd })
        case DeliveryTimeTypeEnum.Exact:
            return formattedEnd
        case DeliveryTimeTypeEnum.Window:
            return formattedStart + ' - ' + formattedEnd
        default:
            return i18n.t('order.deliveryTime.unknown')
    }
}
