import * as Sentry from '@sentry/react-native'

export const logError = (e: unknown) => {
    if (__DEV__) {
        console.error(e)

        return
    }

    Sentry.captureException(e)
}
