import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { Text } from 'react-native-paper'
import { useContextSelector } from 'use-context-selector'

import { ColorEnum } from '../../../../constants/color'
import { OrderStateEnum } from '../../../../constants/order'
import { isDeliveryOrder } from '../../../../utils/isDeliveryOrder'
import OrderListItemContext from './OrderListItemContext'

const State: FC = () => {
    const order = useContextSelector(OrderListItemContext, ({ order }) => order)

    const { t } = useTranslation()

    if (
        order.state === OrderStateEnum.AwaitingPayment ||
        order.state === OrderStateEnum.Delivered
    ) {
        throw new Error("Translation for given state doesn't exist")
    }

    const translationState: OrderStateEnum | `${OrderStateEnum.Ready}_for_delivery` =
        order.state === OrderStateEnum.Ready && isDeliveryOrder(order)
            ? `${order.state}_for_delivery`
            : order.state

    return (
        <View style={styles.container}>
            <Text numberOfLines={1} style={styles.text}>
                {t(`order.state.${translationState}`)}
            </Text>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: ColorEnum.EMERALD_700,
        borderRadius: 4,
        paddingHorizontal: 4,
        paddingVertical: 3,
        flexShrink: 1,
    },
    text: {
        color: ColorEnum.WHITE,
        fontSize: 12,
    },
})

export default State
