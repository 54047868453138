import { FC } from 'react'
import { StyleSheet, View } from 'react-native'
import { Text } from 'react-native-paper'

import Logo from '../../../assets/images/adaptee-gastro-logo.svg'
import { ColorEnum } from '../../constants/color'
import { isWeb } from '../../constants/environment'
import { moderateScale, verticalScale } from '../../utils/metrics'

interface AuthHeaderProps {
    title: string
    hint: string
    showLogo?: boolean
}

const AuthHeader: FC<AuthHeaderProps> = ({ title, hint, showLogo = true }) => {
    const titleStyle = {
        fontSize: moderateScale(21, 36),
        marginTop: showLogo ? verticalScale(36) : verticalScale(16),
    }

    const hintStyle = {
        fontSize: moderateScale(14, 21),
        marginTop: verticalScale(8),
    }

    // Warning: React does not recognize the `testID` prop on a DOM element.
    const logoProps = isWeb ? {} : { testID: 'authHeader-logo' }

    return (
        <View style={styles.container} testID="authHeader">
            {showLogo && <Logo style={{ marginTop: verticalScale(36) }} {...logoProps} />}
            <Text style={[styles.title, titleStyle]}>{title}</Text>
            <Text style={[styles.hint, hintStyle]}>{hint}</Text>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
    },
    title: {
        color: ColorEnum.WHITE,
        textTransform: 'uppercase',
    },
    hint: {
        color: ColorEnum.WHITE,
    },
})

export default AuthHeader
