import { ITokenResponse } from '../../api/oauth/types'
import { IProfile } from '../../types/profile'
import { IAuthDataStorageService } from './types'

class InMemoryAuthDataStorageService implements IAuthDataStorageService {
    private storedHostUrl: string | null = null
    private deviceId: string | null = null
    private storedDeviceToken: string | null = null
    private profiles: IProfile[] | null = null
    private profile: IProfile | null = null
    private tokens: ITokenResponse | null = null

    async saveHostUrl(hostUrl: string) {
        this.storedHostUrl = hostUrl
    }

    async getHostUrl() {
        return this.storedHostUrl
    }

    async removeHostUrl() {
        this.storedHostUrl = null
    }

    async saveDeviceId(deviceId: string) {
        this.deviceId = deviceId
    }

    async getDeviceId() {
        return this.deviceId
    }

    async removeDeviceId() {
        this.deviceId = null
    }

    async saveDeviceToken(deviceToken: string) {
        this.storedDeviceToken = deviceToken
    }

    async getDeviceToken() {
        return this.storedDeviceToken
    }

    async removeDeviceToken() {
        this.storedDeviceToken = null
    }

    async getDeviceAuth() {
        const hostUrl = await this.getHostUrl()
        const deviceId = await this.getDeviceId()
        const deviceToken = await this.getDeviceToken()

        if (!hostUrl || !deviceId || !deviceToken) return null

        return {
            hostUrl,
            deviceId,
            deviceToken,
        }
    }

    async removeDeviceAuth() {
        await Promise.allSettled([
            this.removeHostUrl(),
            this.removeDeviceId(),
            this.removeDeviceToken(),
        ])
    }

    async saveProfiles(profiles: IProfile[]) {
        this.profiles = profiles
    }

    async getProfiles() {
        return this.profiles
    }

    async removeProfiles() {
        this.profiles = null
    }

    async saveProfile(profile: IProfile) {
        this.profile = profile
    }

    async getProfile() {
        return this.profile
    }

    async removeProfile() {
        this.profile = null
    }

    async saveTokens(tokens: ITokenResponse) {
        this.tokens = tokens
    }

    async removeTokens() {
        this.tokens = null
    }

    async getTokens() {
        return this.tokens
    }

    async removeAuth() {
        await Promise.allSettled([
            this.removeDeviceAuth(),
            this.removeProfiles(),
            this.removeProfile(),
            this.removeTokens(),
        ])
    }
}

export default new InMemoryAuthDataStorageService()
