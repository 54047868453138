import { FC } from 'react'

import ErrorDialog from './dialogs/ErrorDialog'

const Dialogs: FC = () => {
    return (
        <>
            <ErrorDialog />
        </>
    )
}

export default Dialogs
