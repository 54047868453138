import { FC } from 'react'
import { StyleSheet } from 'react-native'
import { Text, TouchableRipple } from 'react-native-paper'

import { ColorEnum } from '../../../constants/color'

interface TabBarItemProps {
    children: string
    focused: boolean
    onPress: () => void
}

const TabBarItem: FC<TabBarItemProps> = ({ children, focused, onPress }) => {
    return (
        <TouchableRipple
            borderless
            onPress={onPress}
            style={[styles.container, focused && styles.focusedContainer]}
        >
            <Text style={[styles.label, focused && styles.focusedLabel]}>{children}</Text>
        </TouchableRipple>
    )
}

const styles = StyleSheet.create({
    container: {
        borderRadius: 16,
        paddingVertical: 6,
        paddingHorizontal: 16,
    },
    focusedContainer: {
        backgroundColor: ColorEnum.BRICK_600,
    },
    label: {
        color: ColorEnum.BRICK_600,
        textTransform: 'uppercase',
    },
    focusedLabel: {
        color: ColorEnum.WHITE,
    },
})

export default TabBarItem
