import { NotificationTypeEnum } from '../constants/notificationType'
import { INotification } from '../types/notification'

export const isNotification = (data: any): data is INotification => {
    return (
        typeof data.id === 'string' &&
        Object.values(NotificationTypeEnum).includes(data.type) &&
        typeof data.name === 'string' &&
        typeof data.text === 'string' &&
        typeof data.created === 'string'
    )
}
