import { FC } from 'react'
import { StyleSheet } from 'react-native'
import { Text } from 'react-native-paper'

import { ColorEnum } from '../../constants/color'
import { topBarMarginHorizontalSide } from '../../constants/layout'
import { horizontalScale, moderateScale } from '../../utils/metrics'

const TopBarClock: FC = () => {
    const marginHorizontalSide = horizontalScale(topBarMarginHorizontalSide)
    const clockStyle = {
        fontSize: moderateScale(20, undefined, 0.2),
        marginLeft: marginHorizontalSide,
    }

    return <Text style={[styles.container, clockStyle]}>12:20</Text>
}

const styles = StyleSheet.create({
    container: {
        color: ColorEnum.WHITE,
    },
})

export default TopBarClock
