import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { OrderPhaseEnum } from '../../../constants/order'
import { getOrderSortProperty, sortOrderUuids } from './sortOrderUuids'
import { IAddOrUpdateOrderPayload, OrdersState, UuidOrderMap } from './types'

const initialState: OrdersState = {
    uuidOrderMap: {},
    uuidsByPhase: {
        [OrderPhaseEnum.New]: [],
        [OrderPhaseEnum.InProgress]: [],
        [OrderPhaseEnum.Future]: [],
        [OrderPhaseEnum.Archived]: [],
    },
    isOrdersLoaded: false,
    selectedOrderUuid: null,
}

const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        setOrders: (state, { payload: uuidOrderMap }: PayloadAction<UuidOrderMap | null>) => {
            for (const uuid in uuidOrderMap) {
                const order = uuidOrderMap[uuid]

                if (!order) continue

                state.uuidOrderMap[uuid] = order
                state.uuidsByPhase[order.phase].push(uuid)
            }

            Object.values(OrderPhaseEnum).forEach((phase) => {
                if (state.uuidsByPhase[phase].length === 0) return

                sortOrderUuids(state.uuidsByPhase[phase], state.uuidOrderMap, phase)
            })

            state.isOrdersLoaded = true
        },
        addOrder: (
            state,
            { payload: { uuid, order } }: PayloadAction<IAddOrUpdateOrderPayload>
        ) => {
            if (state.uuidOrderMap[uuid]) return

            state.uuidOrderMap[uuid] = order
            state.uuidsByPhase[order.phase].push(uuid)

            sortOrderUuids(state.uuidsByPhase[order.phase], state.uuidOrderMap, order.phase)
        },
        removeOrder: (state, { payload: uuid }: PayloadAction<string>) => {
            const storeOrder = state.uuidOrderMap[uuid]

            if (!storeOrder) return

            delete state.uuidOrderMap[uuid]

            const index = state.uuidsByPhase[storeOrder.phase].indexOf(uuid)

            if (index < 0) return

            state.uuidsByPhase[storeOrder.phase].splice(index, 1)
        },
        updateOrder: (
            state,
            { payload: { uuid, order } }: PayloadAction<IAddOrUpdateOrderPayload>
        ) => {
            const storeOrder = state.uuidOrderMap[uuid]

            let shouldSort = false

            if (!storeOrder) {
                state.uuidsByPhase[order.phase].push(uuid)

                shouldSort = true
            } else {
                if (storeOrder.phase !== order.phase) {
                    const prevPhaseUuids = state.uuidsByPhase[storeOrder.phase]

                    const index = prevPhaseUuids.indexOf(uuid)

                    if (index < 0) return

                    prevPhaseUuids.splice(index, 1)

                    const nextPhaseUuids = state.uuidsByPhase[order.phase]

                    nextPhaseUuids.push(uuid)

                    shouldSort = true
                } else {
                    shouldSort = getOrderSortProperty(storeOrder) !== getOrderSortProperty(order)
                }
            }

            state.uuidOrderMap[uuid] = order

            if (!shouldSort) return

            sortOrderUuids(state.uuidsByPhase[order.phase], state.uuidOrderMap, order.phase)
        },
        clearOrders: () => initialState,
        setSelectedOrderUuid: (state, { payload: uuid }: PayloadAction<string>) =>
            void (state.selectedOrderUuid = uuid),
        clearSelectedOrderUuid: (state) =>
            void (state.selectedOrderUuid = initialState.selectedOrderUuid),
    },
})

export const {
    setOrders,
    addOrder,
    removeOrder,
    updateOrder,
    clearOrders,
    setSelectedOrderUuid,
    clearSelectedOrderUuid,
} = ordersSlice.actions

export default ordersSlice.reducer
