import { FlashList, ListRenderItem } from '@shopify/flash-list'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Dimensions, StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { Portal, Surface, Text } from 'react-native-paper'

import { ColorEnum } from '../../../constants/color'
import { INotification } from '../../../types/notification'
import InfoMessage from '../InfoMessage'

interface NotificationCenterPaperProps {
    notifications: INotification[] | null
    renderNotification: ListRenderItem<INotification>
    triangleStyle?: StyleProp<ViewStyle>
    style?: StyleProp<ViewStyle>
}

const NotificationCenterPaper: FC<NotificationCenterPaperProps> = ({
    notifications,
    renderNotification,
    triangleStyle,
    style,
}) => {
    const { t } = useTranslation()

    return (
        <Portal>
            <View style={[styles.outerContainer, { width: Dimensions.get('window').width }, style]}>
                <View style={[styles.triangle, triangleStyle]} />
                <Surface elevation={3} style={styles.innerContainer}>
                    <FlashList
                        showsVerticalScrollIndicator={false}
                        data={notifications}
                        renderItem={renderNotification}
                        keyExtractor={(notification) => notification.id}
                        estimatedItemSize={64}
                        ListHeaderComponent={
                            <Text variant="displayMedium" style={styles.title}>
                                {t('notificationsTitle')}
                            </Text>
                        }
                        ListEmptyComponent={
                            <InfoMessage>{t('notificationsEmptyListMessage')}</InfoMessage>
                        }
                        contentContainerStyle={styles.contentContainerStyle}
                    />
                </Surface>
            </View>
        </Portal>
    )
}

const borderRadius = 20
export const paperMaxWidth = 414
export const paperTriangleSize = 12
export const paperTriangleOffset = 24

const styles = StyleSheet.create({
    outerContainer: {
        borderRadius,
        maxWidth: paperMaxWidth,
        position: 'absolute',
    },
    innerContainer: {
        flex: 1,
        backgroundColor: ColorEnum.WHITE,
        borderRadius,
        overflow: 'hidden',
        height: '100%',
    },
    triangle: {
        alignSelf: 'flex-end',
        borderBottomColor: ColorEnum.WHITE,
        borderBottomWidth: paperTriangleSize,
        borderLeftColor: 'transparent',
        borderLeftWidth: paperTriangleSize,
        borderRightColor: 'transparent',
        borderRightWidth: paperTriangleSize,
        borderStyle: 'solid',
        height: 0,
        width: 0,
    },
    title: {
        fontSize: 20,
        marginTop: 10,
        marginBottom: 18,
    },
    contentContainerStyle: {
        paddingHorizontal: 14,
    },
})

export default NotificationCenterPaper
