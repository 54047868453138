import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { Text } from 'react-native-paper'

import { ColorEnum } from '../../../../constants/color'
import { IPrice } from '../../../../types/order'
import { formatPrice } from '../../../../utils/priceHelper'

interface ItemsPriceProps {
    price: IPrice
}

const ItemsPrice: FC<ItemsPriceProps> = ({ price }) => {
    const { t } = useTranslation()

    return (
        <View style={styles.container}>
            <Text style={styles.label}>{t('order.detail.priceItemsLabel')}</Text>
            <Text style={styles.price}>{formatPrice(price.amount, price.currency)}</Text>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        paddingVertical: 6,
        paddingHorizontal: 10,
        backgroundColor: ColorEnum.GRAY_100,
        borderBottomWidth: 1,
        borderBottomColor: ColorEnum.GRAY_200,
    },
    label: {
        flex: 1,
        fontFamily: 'Satoshi-Regular',
    },
    price: {
        width: '20%',
        textAlign: 'right',
        fontFamily: 'Satoshi-Regular',
    },
})

export default ItemsPrice
