import React, { FC, useRef } from 'react'
import { Dimensions, StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { IconButton } from 'react-native-paper'

import numPad from '../../../../e2e/componentObjects/numPad'
import { ColorEnum } from '../../../constants/color'
import { horizontalScale, moderateScale, verticalScale } from '../../../utils/metrics'
import NumPadCircularButton from './NumPadCircularButton'

interface NumPadProps {
    deleteDisabled?: boolean
    submitDisabled?: boolean
    onNumPress: (num: string) => void
    onDelete: () => void
    onSubmit: () => void
    style?: StyleProp<ViewStyle>
    numPadID?: string
}

const maxWidth = 385

const NumPad: FC<NumPadProps> = ({
    deleteDisabled,
    submitDisabled,
    onNumPress,
    onDelete,
    onSubmit,
    style,
    numPadID,
}) => {
    const timeout = useRef<NodeJS.Timeout>()
    const interval = useRef<NodeJS.Timeout>()

    const deleteDisabledRef = useRef(deleteDisabled)
    deleteDisabledRef.current = deleteDisabled

    const clearTimers = () => {
        clearTimeout(timeout.current)
        clearInterval(interval.current)
    }

    const handleNumDeleteIn = () => {
        clearTimers()

        timeout.current = setTimeout(() => {
            interval.current = setInterval(() => {
                if (deleteDisabledRef.current) return

                onDelete()
            }, 100)
        }, 750)
    }

    const paddingHorizontal = horizontalScale(7, 10)
    const buttonContainerStyle = {
        flexBasis: Math.min(Dimensions.get('window').width, maxWidth) / 3 - 2 * paddingHorizontal,
        marginBottom: verticalScale(15),
    }
    const iconSize = moderateScale(40, 46)

    const deleteBtnProps = deleteDisabled
        ? {}
        : {
              onPress: onDelete,
              onPressIn: handleNumDeleteIn,
          }
    const submitBtnProps = submitDisabled ? {} : { onPress: onSubmit }

    return (
        <View
            style={[styles.container, style]}
            testID={numPadID ? `${numPadID ? `${numPad}-` : ''}numPad` : undefined}
        >
            {Array.from({ length: 9 }, (_, i) => i + 1).map((number) => (
                <View key={number} style={[styles.buttonContainer, buttonContainerStyle]}>
                    <NumPadCircularButton
                        number={number.toString()}
                        onPress={() => onNumPress(number.toString())}
                        numPadID={numPadID}
                    />
                </View>
            ))}

            <View style={[styles.buttonContainer, buttonContainerStyle]}>
                <IconButton
                    icon="backspace"
                    iconColor={!deleteDisabled ? ColorEnum.GRAY_100 : undefined}
                    size={iconSize}
                    onPressOut={clearTimers}
                    style={styles.icon}
                    accessibilityLabel="delete"
                    testID={
                        numPadID ? `${numPadID ? `${numPadID}-` : ''}numPad-deleteBtn` : undefined
                    }
                    {...deleteBtnProps}
                />
            </View>

            <View style={[styles.buttonContainer, buttonContainerStyle]}>
                <NumPadCircularButton
                    number="0"
                    onPress={() => onNumPress('0')}
                    numPadID={numPadID}
                />
            </View>

            <View style={[styles.buttonContainer, buttonContainerStyle]}>
                <IconButton
                    icon="check-circle"
                    iconColor={!submitDisabled ? ColorEnum.GRAY_100 : undefined}
                    size={iconSize}
                    style={styles.icon}
                    accessibilityLabel="submit"
                    testID={
                        numPadID ? `${numPadID ? `${numPadID}-` : ''}numPad-submitBtn` : undefined
                    }
                    {...submitBtnProps}
                />
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        maxWidth,
    },
    buttonContainer: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    icon: {
        margin: 0,
    },
})

export default NumPad
