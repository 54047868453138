import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet, View } from 'react-native'
import { IconButton } from 'react-native-paper'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import Logo from '../../../assets/images/adaptee-gastro-logo.svg'
import { ColorEnum } from '../../constants/color'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { closeGridMenu } from '../../redux/reducers/app'
import { authSelectors } from '../../redux/reducers/auth/selectors'
import { selectScreenInfo } from '../../redux/reducers/screenInfo'
import UserAccountCard from '../GridMenu/UserAccountCard'
import GridMenuItem from '../UI/GridMenuItem'

const GridMenuScreen: FC = () => {
    const profile = useAppSelector(authSelectors.profile)
    useAppSelector(selectScreenInfo)

    const dispatch = useAppDispatch()

    const { t } = useTranslation()

    const { top } = useSafeAreaInsets()

    const data = [
        {
            name: t('menuOrders'),
            icon: 'food-takeout-box-outline',
            backgroundColor: ColorEnum.BRICK_700,
            badge: 8,
            onPress: () => dispatch(closeGridMenu()),
        },
        {
            name: t('menuCashDesk'),
            icon: 'cash-register',
            backgroundColor: ColorEnum.BLUE,
            onPress: () => dispatch(closeGridMenu()),
        },
    ]

    return (
        <View style={[styles.container, { paddingTop: top }]}>
            <View style={styles.toolbar}>
                <View style={styles.logoContainer}>
                    <Logo height="32" viewBox="0 0 149.999 52.33" />
                </View>
                <IconButton
                    icon="cog-outline"
                    iconColor={ColorEnum.WHITE}
                    onPress={() => {
                        return
                    }}
                />
            </View>
            {profile && (
                <UserAccountCard
                    name={profile.name}
                    role={profile.description ?? ''}
                    style={{
                        marginHorizontal: 12,
                        marginVertical: 32,
                    }}
                />
            )}

            <View style={styles.menuContainer}>
                <ScrollView contentContainerStyle={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                    {data.map((item, index) => (
                        <GridMenuItem
                            key={index}
                            icon={item.icon}
                            name={item.name}
                            backgroundColor={item.backgroundColor}
                            badge={item.badge}
                            style={{ marginEnd: index % 2 !== 0 ? 0 : 32, marginBottom: 32 }}
                            onPress={item.onPress}
                        />
                    ))}
                </ScrollView>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        height: '100%',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: ColorEnum.JET_800,
    },
    toolbar: {
        width: '100%',
        height: 56,
        paddingHorizontal: 16,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    logoContainer: {
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        marginStart: 'auto',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
    },
    menuContainer: {
        width: '100%',
        maxWidth: 288,
        justifyContent: 'center',
        flexDirection: 'row',
        flex: 1,
    },
})

export default GridMenuScreen
