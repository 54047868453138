import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'
import { Text } from 'react-native-paper'
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'

import { ColorEnum } from '../../../constants/color'
import { hexToRgba } from '../../../utils/hexToRgba'

const OrderDetailAcceptedOverlay: FC = () => {
    const { t } = useTranslation()

    return (
        <Animated.View entering={FadeIn} exiting={FadeOut} style={styles.container}>
            <Icon name="check-circle-outline" color={ColorEnum.WHITE} size={120} />
            <Text style={styles.text}>{t('order.detail.accepted')}</Text>
        </Animated.View>
    )
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: hexToRgba(ColorEnum.EMERALD_700, 0.5),
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
    text: {
        color: ColorEnum.WHITE,
        fontSize: 24,
        marginTop: 12,
    },
})

export default OrderDetailAcceptedOverlay
