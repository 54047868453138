import React, { FC } from 'react'

import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { useNotificationsConnect } from '../../hooks/useNotificationsConnect'
import { notificationSelectors, toggleNotificationCenter } from '../../redux/reducers/notifications'
import NotificationCenter from '../UI/NotificationCenter/NotificationCenter'
import NotificationCenterItem from './NotificationCenterItem'

const AppNotificationCenter: FC = () => {
    const isCenterOpen = useAppSelector(notificationSelectors.isCenterOpen)
    const notifications = useAppSelector(notificationSelectors.notifications)

    const dispatch = useAppDispatch()

    useNotificationsConnect()

    const handlePress = () => {
        dispatch(toggleNotificationCenter())
    }

    return (
        <NotificationCenter
            visible={isCenterOpen}
            notifications={notifications}
            renderNotification={({ item: notification }) => (
                <NotificationCenterItem key={notification.id} notification={notification} />
            )}
            onPress={handlePress}
        />
    )
}

export default AppNotificationCenter
